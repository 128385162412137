<template>
	<div>
		<div class="columns is-mobile">
			<div class="column title is-size-4-mobile is-size-3-tablet brand-color is-narrow">{{ ML.trans('log') }}</div>
			<div class="column has-text-right sectionTitle" >{{ ML.trans('page') }} {{ currentPage() + 1 }} / {{ totalPages() }}, Log items: {{ totalLogItems }}</div>
		</div>

		<div class="container block">
			<div class="box">
				<div class="columns is-variable">
					<div class="column has-text-centered is-prime-color ls-happy-title">


						<div class="columns is-vcentered has-text-weight-normal">

							<div class="column has-text-weight-bold has-text-left">
								<a href="#" v-on:click.prevent="getFirstPage" v-text="'|<'" class="button is-primary" style="color: black;" :title="ML.trans('first_page')"></a>
								<a href="#" v-on:click.prevent="getPreviousPage" v-text="'<'" class="button is-primary spaceleft" style="color: black;" :title="ML.trans('prev_page')"></a>
								<a href="#" v-on:click.prevent="getNextPage" v-text="'>'" class="button is-primary spaceleft" style="color: black;" :title="ML.trans('next_page')"></a>
								<a href="#" v-on:click.prevent="getLastPage" v-text="'>|'" class="button is-primary spaceleft" style="color: black;" :title="ML.trans('last_page')"></a>
							</div>

							<div class="column is-narrow-tablet">
								<div class="columns is-vcentered is-centered is-mobile">

									<div class="column ls-no-wrap is-narrow">
										<select v-model="pageSize" style="margin-left: 5px;">
											<option v-for="pSize in pageSizeOptions" :value="pSize" v-bind:key="pSize">{{ pSize }}</option>
										</select>
										<span class="has-text-weight-semibold spaceleft-small">{{ ML.trans('rows_L') }} / {{ ML.trans('page_L') }}</span>
									</div>
									<div class="column is-narrow">
										<a class="button is-primary" @click="refreshPage()" :title="ML.trans('refresh')">
											<img
												class="ls-is-vam"
												alt="Refresh"
												src="../assets/images/refresh.svg"
												style="width: 20px"
											/>
										</a>
									</div>
									<div v-if="hasMinRights(3)" class="column is-narrow">
										<a class="button is-primary" @click="clearLog()" :title="ML.trans('delete')">
											<img
												class="ls-is-vam"
												alt="Refresh"
												src="../assets/images/delete.svg"
												style="width: 20px"
											/>
										</a>
									</div>


								</div>
							</div>
							
						</div>
					</div>
				</div>

				<div class="table-container mt-5">
					<table class="container table is-bordered table is-narrow is-hoverable">
						<thead>
							<tr>
								<!-- <th>LogId</th> -->
								<th>{{ ML.trans('date') }}</th>
								<!-- <th>Area</th> -->
								<!-- <th>Rank</th> -->
								<th>{{ ML.trans('description') }}</th>
								<th>{{ ML.trans('notes') }}</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="logItem in logItems" v-bind:key="logItem.logId" :style="{'color': logItem.errCallStack != '' ? 'var(--my-error-color)' : '' }">
								<!-- <td class="bodyCell">{{logItem.logId}}</td> -->
								<td class="bodyCell">{{ UnixUtcToLocalDateString(logItem.timeStamp, goptions.localSettings.dateFormat) }}</td>
								<!-- <td class="bodyCell">{{logItem.area}}</td> -->
								<!-- <td class="bodyCell">{{logItem.rank}}</td> -->
								<td class="bodyCell" v-text="logItem.description"></td>
								<td class="bodyCell">{{logItem.errCallStack}}</td>
							</tr>
						</tbody>
					</table>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'Log',
	mixins: [],
	data: function() {
		return {
			logItems: [],
			conversations: [],
            totalLogItems: 0,
            page: { index: 0 },
            pageSizeOptions: [5, 10, 15, 20, 35, 50, 100],
            testStr: "xxx",
            etc: {
                wait: false
            },
            nextExecutionTime: {
                "hourLocal": "(undefined)",
				"hourUtc": "(undefined)",
				"DateTimeUtcUnix": 0
            }
		};
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		isWaiting: {
			get: function () {
				return this.etc.wait;
			},
			set: function (v) {
				this.etc.wait = v;
			}
		},
		barColor: function () {
			return (this.isWaiting ? "yellow" : "#333333");
		},
		pageSize: {
			get: function() {
				return this.goptions.localSettings.logPageSize
			},
			set: function(newValue) {
				this.goptions.localSettings.logPageSize = newValue;
				this.saveLocalSettings();
			}
		}
	},
	created: function () {
		this.refreshPage();
	},
	watch: {
		pageSize: function () {
			this.setPageSize(this.pageSize);
		}
	},
	methods: {
		getNextPage: function () {
			if (this.page.index + this.pageSize < this.totalLogItems) {
				this.getLogItems(this.page.index + this.pageSize, this.pageSize);
			}
		},
		getPreviousPage: function () {
			if (this.page.index > 0) {
				this.getLogItems(this.page.index - this.pageSize, this.pageSize);
			}
		},
		getFirstPage: function () {
			this.getLogItems(0, this.pageSize);
		},
		getLastPage: function () {
			var pageCount = this.totalPages();
			this.getLogItems((pageCount - 1) * this.pageSize, this.pageSize);
		},
		currentPage: function () {
			return Math.trunc(this.page.index / this.pageSize);
		},
		totalPages: function () {
			var pages = Math.trunc(this.totalLogItems / this.pageSize) + 1;
			if (pages * this.pageSize < this.totalPages) {
				pages++;
			}
			return pages;
		},
		getLogItems: function (firstRowIndex, limit) {
			this.isWaiting = true;
			this.$ky.get(this.apiUrl + "/GetLogItems", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					firstRowIndex: firstRowIndex,
					limit: limit
				}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				this.logItems = responseJson;
				this.page.index = firstRowIndex;
				this.pageSize = limit;
				this.isWaiting = false;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		updateLogItemsCount: function () {
			this.$ky.get(this.apiUrl + "/GetLogItemsCount", {
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				this.totalLogItems = responseJson;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		setPageSize: function (newPageSize) {
			this.updateLogItemsCount();
			this.getLogItems(0, newPageSize);
		},
		refreshPage: function () {
			this.updateLogItemsCount();
			this.getLogItems(0, this.pageSize);
		},
		clearLog: function() {
			if (!confirm(this.ML.trans("sure_purge_log"))) {
				return;
			}
			this.showWait(true);
			this.$ky.get(this.apiUrl + "/PurgeUserLog", {
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				if (!responseJson) {
					alert("Purge error");
				}
				this.refreshPage();
				this.showWait(false);
			})
			.catch((err) => {
				this.showWait(false);
				alert("Error: " + err.message);
			});
		}	
	}
}
</script>

<style>
</style>