<template>
	<div>
		<div class="columns is-centered is-4 is-variable">
			<div class="column is-half has-text-right-tablet ls-is-label">
				{{ ML.trans('device_name') }}
			</div>
			<div class="column is-half has-text-left">
				<input type="text" size=30 v-model="device.deviceName" :placeholder="ML.trans('enter_dev_name')">
			</div>
		</div>

		<hr>

		<div class="buttons is-grouped is-centered">
			<a class="button is-primary" @click="doSave()">{{ ML.trans('ok') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
			<a v-if="!device.isPermanent" class="button is-danger" @click="doDelete()">{{ ML.trans('delete') }}</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "DeviceEdit",
	props: ["targetDevice"],
	data: function() {
		return {
			device: null
		};
	},
	created: function() {
		this.device = {...this.targetDevice};

		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	methods: {
		onKeydown: function(event) {
			// Manage the Enter key down
			if (event.keyCode == 13) {
				// Trigger the default action
				this.doSave();
			}
		},
		doSave: function() {
			if (this.device.deviceName.includes("|")) {
				alert(this.ML.trans('dev_name_forbidden_char') + " \"|\"");
				return;
			}
			
			this.$emit("askSave", this.device);
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		},
		doDelete: async function() {
			// Check if the device is referred by any job.
			var flatInfo = await this.getFlatInfo();
			var jobs = this.getChildren(flatInfo, this.device.dbId, "device");
			if (jobs.length > 0) {
				var msg = "This device is referred by the following programs:";
				jobs.forEach(job => {
					msg += "\n* " + job.name;
				});
				msg += "\n\nThe device cannot be deleted while referred."
				alert(msg);
				return;
			}
			// Confirm deletion
			if (confirm("Are you sure you want to remove the device \"" + this.device.deviceName + "?")) {
				this.$emit("askDelete", this.device);
			}
		}
	}
}
</script>

<style>
</style>