<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ML.trans('sections')}}</div>
		<div style="text-align: center;">
			<div class="big-button" @click="navigate('Dashboard')">
				<div class="big-button-img">
					<img
						alt="Dashboard"
						src="../assets/images/menu/dashboard.svg"
						style="width: 100px"
					/>
				</div>
				<div>{{ML.trans('dashboard')}}</div>
			</div>

			<div class="big-button" @click="navigate('Controllers')">
				<div class="big-button-img">
					<img
						alt="Dashboard"
						src="../assets/images/menu/sprinkler.svg"
						style="width: 100px"
					/>
				</div>
				<div>{{ML.trans('controllers')}}</div>
			</div>

			<div class="big-button" @click="navigate('Programs')">
				<div class="big-button-img">
					<img
						alt="Dashboard"
						src="../assets/images/menu/timer.svg"
						style="width: 100px"
					/>
				</div>
				<div>{{ML.trans('programs')}}</div>
			</div>

			<div class="big-button" @click="navigate('Weather')">
				<div class="big-button-img">
					<img
						alt="Weather"
						src="../assets/images/menu/weather.svg"
						style="width: 100px"
					/>
				</div>
				<div>{{ML.trans('weather')}}</div>
			</div>

			<div class="big-button" @click="navigate('Log')">
				<div class="big-button-img">
					<img
						alt="Log"
						src="../assets/images/menu/log.svg"
						style="width: 100px"
					/>
				</div>
				<div>{{ML.trans('log')}}</div>
			</div>
		
			<div class="big-button" @click="navigate('Settings')">
				<div class="big-button-img">
					<img
						alt="Settings"
						src="../assets/images/menu/settings.svg"
						style="width: 100px;"
					/>
				</div>
				<div>{{ML.trans('settings')}}</div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { themes } from "@/components/mixins/themes";

export default {
	name: 'Home',
	mixins: [themes],
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	methods: {
		navigate: function(target) {
			this.$router.push({ name: target, query: { 'nav': true } });
		}
	}
}
</script>

<style>
.big-button {
	display: inline-block;
	width: 160px;
	text-align: center;
	font-size: 120%;
	padding: 5px;
	margin: 3px;
	color: #000000;
	background-color: #bbbbbb;
	border: 3px solid #888888;
	border-radius: 10px;
	cursor: pointer;
}

.big-button:hover {
	border: 3px solid var(--my-link-hover-color);
	background-color: #cccccc;
}

.big-button-img {
	display: flex;
	height: 110px;
	justify-content: center;
	align-items: center;
}

</style>