<template>
	<div>
		<div v-for="action in actions">
			<div class="mt-5">
				<span>{{ actionText(action) }}</span>
				<span v-if="(! isOutputAction(action)) && (action.state == executionState.succeeded)" style="float: right;">==> {{ ML.trans('result') }}: {{ action.execValue ? '&#9989;' : '&#9940;' }}</span>
				<span v-if="action.state == executionState.failed" class="has-text-danger" style="float: right;">{{ action.errorDescription }}</span>
			</div>
			<progress class="progress is-small is-primary mt-4" max="100" :value="progressValue(action)" :class="progressClasses(action)"></progress>
		</div>

		<hr>

		<div v-if="! allActionsExecuting" class="buttons is-grouped is-centered">
			<a class="button is-primary" @click="executeAll()">{{ ML.trans('re_execute') }}</a>
			<a class="button is-primary" @click="doClose()">{{ ML.trans('close') }}</a>
		</div>
	</div>
</template>

<script>
import { reactive, ref } from 'vue'
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "ActionListExecutor",
	props: ["targetActions"],
	data: function() {
		return {
			actions: [],
			executionState: {
				idle: 0,
				started: 1,
				succeeded: 2,
				failed: 3
			},
			allActionsExecuting: false
		};
	},
	created: function() {
		this.actions = this.deepClone(this.targetActions);
		this.actions.forEach(action => {
			action = reactive(action);
			action.state = ref(this.executionState.idle);
			action.execValue = ref(false);
			action.errorDescription = ref("");
		});
	},
	mounted: function() {
		this.executeAll();
	},
	unmounted: function() {
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	methods: {
		progressValue: function(action) {
			switch(action.state) {
				case this.executionState.started:
					return 50;
				case this.executionState.failed:
				case this.executionState.succeeded:
					return 100;
				case this.executionState.idle:
				default:
					return 0;
			}
		},
		progressClasses: function(action) {
			switch(action.state) {
				case this.executionState.started:
					return {"is-primary": true, "is-danger": false};
				case this.executionState.failed:
					return {"is-primary": false, "is-danger": true};
				case this.executionState.succeeded:
					return {"is-primary": true, "is-danger": false};
				case this.executionState.idle:
				default:
					return {"is-primary": true, "is-danger": false};
			}
		},
		executeAll: async function() {
			console.print("Executimg all...");
			this.allActionsExecuting = true;
			this.actions.forEach(action => {
				action.state = this.executionState.idle;
			});
			var action, responseJson;
			for (var i = 0; i < this.actions.length; i++) {
				action = this.actions[i];
				try {
					action.state = this.executionState.started;
					responseJson = await this.executeAction(action);
					if (responseJson.success) {
						console.log("responseJson", responseJson);
						action.state = this.executionState.succeeded;
						if (this.isOutputAction(action)) {
							console.print(action + " - Successfullty executed");
						}
						else {
							action.execValue = responseJson.value;
							console.print(this.actionText(action) + " - Result: " + responseJson.value);
						}
					}
					else {
						action.state = this.executionState.failed;
						action.errorDescription = responseJson.errorDescription;
						console.print("%c" + action + " - Execution error: " + responseJson.errorDescription, "color: #aa0000");
					}
				}
				catch(err) {
					action.state = this.executionState.failed;
					console.print("%c Error: " + err.message);
				}
			}

			this.allActionsExecuting = false;
			console.print("...Execute all finished");
		},
		executeAction: async function(action) {
			return this.$ky.get(this.apiUrl + "/ActionExecute", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					actionStr: this.actionSerialize(action)
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.bigger, retry: 1})
			.json();
		},
		doClose: function()   {
			this.$emit("askClose", {});
		}
	}
}
</script>

<style>
</style>