<template>
	<div style="margin-bottom: 80px;">
		<!-- <div class="title is-size-6-mobile is-size-3-tablet">Factory setup</div> -->


		<div class="columns">
			<div class="column title is-size-6-mobile is-size-3-tablet brand-color">Factory setup</div>
			<div class="column has-text-right-tablet">
				<a class="button is-primary" @click="refresh()">Refresh steps</a>
			</div>
		</div>



		<div class="container block">
			<div class="box" >
				<div class="select is-primary content">
					<select v-model="crtConfig" class="is-prime-color">
						<option v-for="config in configs" v-bind:key="config.controllerModelId" v-bind:value="config">{{config.controllerModelId}} -- {{ config.comment }}</option>
					</select>
				</div>

				<div class="columns is-centered is-variable">
					<div class="column has-text-centered">
						<span class="ls-is-label">Model id: </span>
						<span>{{ crtConfig.controllerModelId }}</span>
					</div>
				</div>
				<div class="columns is-centered is-variable">
					<div class="column has-text-centered">
						<span class="ls-is-label">Include command id: </span>
						<span><input type="checkbox" class="spaceleft" v-model="includeCommandId" /></span>
					</div>
				</div>
			</div>

			<div class="content has-text-info">
				<div class="is-size-3">General note</div>
				<div>
					Plug the controller to the <b>computer's Serial port</b>.
					<br>
					Open the <b>Serial Monitor</b> in Visual Micro. All the following commands are to be executed in the Serial Monitor.
				</div>
			</div>
			
			<div class="content">
				<div class="is-size-3">Set the controller stamp</div>
				<div>
					The controller stamp needss to be set in the corresponding SPIFF file.
					<br/>
					Execute the following command:
					<br/>
					<span style="color: green; margin-left:50px;">000,spifss,,,{"id":"{{newGuid}}", "model":"{{crtConfig.controllerModelId}}"}</span>
					<br/>
					<span style="color: indianred"><b>NOTE:</b> The controller id in the above command is (re)generated each time this screen is accesssed or refreshed.</span>
				</div>
			</div>

			<div class="content">
				<div class="is-size-3">Set the permanent devices</div>
				<div>
					The permanent devices need to be written in the corresponding SPIFF file.
					<br/>
					Execute the following commands:
					<br/>
					<div v-for="(command, index) in mountCommands" style="color: green; margin-left:50px;">
						<span v-if="includeCommandId">{{command.id}}</span>
						<span>{{command.value}}</span>
						<span class="ls-feels-link spaceleft" @click="copyToClipboard(index)">Copy</span>
						<span>
							<input type="checkbox" class="spaceleft" v-model="command.isCopied" />
						</span>
					</div>
				</div>
			</div>

			<div class="content">
				<div class="is-size-3">Set the controller configuration (contains only MQTT stuff for now)</div>
				<div>
					The MQTT parameters need to be set in the corresponding SPIFF file.
					<br/>
					Assuming, for example, that <span class="has-text-info">MQTT broker IP</span>=serbanic.site, and <span class="has-text-info">MQTT broker port</span>=1883,
					<br/>
					Execute the following command:
					<br/>
					<span style="color: green; margin-left:50px;"><span v-if="includeCommandId">000,</span>cfgset,,,mqttbrokerip=serbanic.site|mqttbrokerport=1883</span>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "FactorySetup",
	data: function() {
		return {
			configs: [],
			crtConfig: {},
			mountCommands: [],
			includeCommandId: true,
			newGuid: ""
		}
	},
	created: function() {
		this.getAllConfigurations();
		this.getNewGuid();
	},
	watch: {
		crtConfig: function() {
			var deviceDefinitionStrArr = this.crtConfig.permanentDevices.split("\r\n");
			var devDefinitionArr = deviceDefinitionStrArr.map(function(devStr) {return JSON.parse(devStr);} );
			this.mountCommands = devDefinitionArr.map(function(deviceDefinition) {return {id: "000,", value: "smdp," + deviceDefinition.mountCommand, isCopied: false};});
			this.getNewGuid();
		}
	},
	methods: {
		refresh: function() {
			alert("Not implemented");
		},
		getNewGuid: function() {
			this.$ky(this.apiUrl + "/GetNewGuid", {
				method: "GET",
				searchParams: {
					format: "N"
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				this.newGuid = responseJson;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		getAllConfigurations: function() {
			this.$ky(this.apiUrl + "/GetAllFactoryConfigs", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				this.configs = responseJson;
				if (this.configs.length > 0) {
					this.crtConfig = this.configs[0];
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		copyToClipboard: function(commandIndex) {
			navigator.clipboard.writeText(this.mountCommands[commandIndex].value);
			this.mountCommands[commandIndex].isCopied = true;
			//console.log("Copy index " + commandIndex);
		}
	}
}
</script>

<style>
</style>
