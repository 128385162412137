<template>
	<div>
		<div class="columns is-centered is-4 is-variable">
			<div class="column is-half has-text-right-tablet ls-is-label">
				{{ ML.trans('controller_name') }}
			</div>
			<div class="column is-half has-text-left">
				<input type="text" size=30 v-model="controller.name" :placeholder="ML.trans('enter_ctrl_name')">
			</div>
		</div>
		<hr>

		<div class="buttons is-grouped is-centered">
			<a class="button is-primary" @click="doSave()">{{ ML.trans('ok') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
			<a class="button is-danger" @click="doDelete()">{{ ML.trans('delete') }}</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "ControllerEdit",
	props: ["targetController"],
	data: function() {
		return {
			controller: null
		};
	},
	created: function() {
		this.controller = {...this.targetController};

		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	methods: {
		onKeydown: function(event) {
			// Manage the Enter key down
			if (event.keyCode == 13) {
				// Trigger the default action
				this.doSave();
			}
		},
		doSave: function() {
			this.$emit("askSave", this.controller);
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		},
		doDelete: async function() {
			// Check if there are jobs referring (indirectly, by devices) this controller
			var flatInfo = await this.getFlatInfo();
			var refDeviceIds = this.getChildren(flatInfo, this.controller.controllerId, "controller").map(device => device.dbId);
			var refJobs = [];
			if (refDeviceIds.length > 0) {
				flatInfo.jobs.forEach(job => {
					if (job.allDevIds.filter(devId => refDeviceIds.includes(devId)).length > 0) {
						refJobs.push(job);
					}
				});
				if (refJobs.length > 0) {
					var msg = this.ML.trans('jobs_referring_controller');
					refJobs.forEach(job => {
						msg += "\n * " + job.name
					});
					alert(msg);
					return;
				}
			}

			if (confirm("Are you sure you want to remove the controller \"" + this.controller.name + "\"\r\nfrom yout account?")) {
				this.$emit("askDelete", this.controller);
			}
		}
	}
}
</script>

<style>
</style>