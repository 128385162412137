<template>
	<div>
		<div v-show="isVisible('controllers')" class="columns is-centered is-4 is-variable is-vcentered">
			<div class="column has-text-right-tablet has-text-centered-mobile ls-is-label">
				{{ ML.trans('controller') }}
			</div>
			<div class="column has-text-left-tablet has-text-centered-mobile">
				<div class="select is-primary content">
					<select v-model="crtControllerId" class="is-prime-color">
						<option v-for="controller in controllers" v-bind:key="controller.controllerId" v-bind:value="controller.controllerId">{{ controller.name }}</option>
					</select>
				</div>
			</div>
		</div>

		<div class="columns is-mobile is-vcentered">
			<div class="column has-text-right ls-is-label">
				{{ ML.trans('device') }}
			</div>
			<div class="column has-text-left">
				<div class="select is-primary content">
					<select v-model="action.device" class="is-prime-color">
						<option v-for="device in displayDevices" :key="device.dbId" :value="device">{{ device.deviceName }}</option>
					</select>
				</div>
			</div>
		</div>

		<div v-show="!isSetAction">	<!-- Operators and velues for GET action -->
			<div class="columns is-mobile is-vcentered">
				<div class="column has-text-right ls-is-label">
					{{ ML.trans('value_is') }}
				</div>
				<div v-show="!getSetValueSimplified" class="column has-text-left is-narrow">
					<div class="select is-primary content">
						<select v-model="action.operator" class="is-prime-color">
							<option v-for="op in enumPresenter.getOperators" v-bind:key="op.value" v-bind:value="op.value">{{ op.label }}</option>
						</select>
					</div>
				</div>
				<div v-if="isVisibleRefCategory('value')" class="column has-text-left">
					<!-- <input type="number" v-model="action.value" min="0" max="255" class="number is-prime-color is-size-6 ls-is-vam"> -->
					<EditableDiv v-model="action.value" :isNumber="true" class="input" style="width: 100px;"></EditableDiv>
				</div>
				<div v-if="isVisibleRefCategory('interval')" class="column has-text-left">
					<span>Interval values - probably to be removed</span>
				</div>
				<div v-if="isVisibleRefCategory('onoff')" class="column has-text-left">
					<div class="select is-primary content">
						<select v-model="action.value" class="is-prime-color">
							<option :value="1">&#9889; ON</option>
							<option :value="0">&#9940; OFF</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<div v-show="isSetAction">	<!-- Operators and velues for SET action -->
			<div class="columns is-mobile is-vcentered">
				<div class="column has-text-right ls-is-label">
					{{ ML.trans('set_to') }}
				</div>
				<div v-show="!getSetValueSimplified" class="column has-text-left is-narrow">
					<div class="select is-primary content">
						<select v-model="action.operator" class="is-prime-color">
							<option v-for="op in enumPresenter.setOperators" v-bind:key="op.value" v-bind:value="op.value">{{ op.label }}</option>
						</select>
					</div>
				</div>
				<div v-if="isVisibleRefCategory('value')" class="column has-text-left">
					<input type="number" min="0" max="255" class="number is-prime-color is-size-6 ls-is-vam" v-model="action.value">
				</div>
				<div v-if="isVisibleRefCategory('onoff')" class="column has-text-left">
					<div class="select is-primary content">
						<select v-model="action.value" class="is-prime-color">
							<option :value="1">&#9889; ON</option>
							<option :value="0">&#9940; OFF</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<hr>

		<div class="buttons is-grouped is-centered">
			<a class="button is-primary" @click="doSave()">{{ ML.trans('ok') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import EditableDiv from "@/components/controls/EditableDiv.vue"

export default {
	name: "ChainLinkActionEditor",
	components: {EditableDiv},
	props: {
		seedAction: {	// The default action already generated from outside
			type: Object,
			default: {}
		},
		controllers: {
			type: Array,
			default: []
		},
		devices: {
			type: Array,
			default: []
		}
	},
	data: function() {
		return {
			mode: "",	// "N" (new) or "E" (edit) - will be set according to the device value (null / not null)
			action: {},
			isSetAction: false,
			operator: "",
			crtControllerId: 0,
			displayDevices: [],
			getSetValueSimplified: false
		};
	},
	created: function() {
		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	mounted: function() {
		this.action = this.deepClone(this.seedAction);
		this.mode = (this.action.device == null ? "N" : "E");
		this.isSetAction = this.isOutputAction(this.action);
		if (this.action.device == null) {
			this.crtControllerId = (this.controllers.length > 0 ? this.controllers[0].controllerId : 0);	// Initially selected controller
		}
		else {
			this.crtControllerId = this.action.device.controllerId;
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		currentDevice: function() {
			return this.action.device;
		}
	},
	watch: {
		crtControllerId: function() {
			this.filterDevices();
		},
		currentDevice: function() {
			this.trySimplifyGetSetValue();
		}
	},
	methods: {
		trySimplifyGetSetValue: function() {
			if (this.action.device == null) {
				this.getSetValueSimplified = false;
				return;
			}
			if (this.action.device.minValue != 0 || this.action.device.maxValue != 1) {		// NOT a binary value device
				this.getSetValueSimplified = false;
				return;
			}
			// This is a binary value device.
			this.getSetValueSimplified = true;
			this.action.operator = (this.isSetAction ? "setOnOff" : "getOnOff");
		},
		isVisibleRefCategory: function(category) {
			if (this.isSetAction) {
				if (category == "value") {
					return (this.action.operator == "=");
				}
				else if (category == "onoff") {
					return (this.action.operator == "setOnOff");
				}
				else {
					return false;
				}
			}
			else {
				if (category == "value") {
					return (["LT", "LT=", "==", "GT", "GT="].includes(this.action.operator))
				}
				else if (category == "interval") {
					return (["InInterval", "OutsideInterval"].includes(this.action.operator))
				}
				else if (category == "onoff") {
					return (this.action.operator == "getOnOff");
				}
				else {
					return false;
				}
			}
		},
		filterDevices: function() {
			var devs = this.devices.filter(device => device.controllerId == this.crtControllerId);
			if (this.isSetAction) {
				devs = devs.filter(device => device.isOutputDevice);
			}
			else {
				devs = devs.filter(device => device.isInputDevice);
			}
			this.displayDevices = devs;
		},
		onKeydown: function(event) {
			// Manage the Enter key down
			if (event.keyCode == 13) {
				// Trigger the default action
				this.doSave();
			}
		},
		doSave: function() {
			if (this.action.device == null || this.displayDevices.find(dev => dev.dbId == this.action.device.dbId) == null) {
				// No current selected device in devices combo
				// May happen if (1) "Add" mode + no device selected, or (2) the controller changed, so the current device (on of the previous controller devices) is not between the currently displayed devices (for the current controller).
				alert(this.ML.trans('must_select_device'));
				return;
			}
			if (this.mode == "N") {
				this.$emit("askSave", this.action);
			}
			else {
				this.$emit("askSaveEdit", this.action);
			}
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		},
		isVisible: function(category) {
			switch(category) {
				case "controllers":
					return (this.controllers.length > 1);
			}
		}
	}
}
</script>

<style>
</style>