import { createApp, defineComponent } from 'vue'	// reactive function will be used in subsequent calls as this.$reactive
import App from './App.vue'
import router from './router'
import store from './store'

import registerGlobalComponents from "@/components/globalComponents.js"	// To later register the global components


import { globalSettings } from "@/components/mixins/globalSettings";
import { auth } from "@/components/mixins/auth";

import { initialSetup } from "./initialSetup.js";

// Date-time picker
// Ref: https://github.com/chronotruck/vue-ctk-date-time-picker
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import ky from "ky";
//Object.defineProperty(Vue.prototype, '$ky', { value: ky, writable: false, });
import {gconst} from "@/components/mixins/gconst";
//Object.defineProperty(Vue.prototype, '$gconst', { value: gconst, writable: false, });

require('@/assets/css/main.scss');		// custom Bulma definitions

//Vue.config.productionTip = false

console.print = function (...args) {
	queueMicrotask (console.log.bind (console, ...args));
}


// App initialization
async function initilizeApp() {
	const app = createApp(App);
	app.use(store).use(router);

	app.mixin(globalSettings);	// Set globalSettings as a global mixin 
	app.mixin(auth);	// Set auth as a global mixin

	app.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

	registerGlobalComponents(app);	// Register the global components

	app.config.globalProperties.$ky = ky;	// Add ky to the app's global properties
	app.config.globalProperties.$gconst = gconst;	// Add gconst to the app's global properties

	await initialSetup.initializeAppState(store);

	app.mount('#app');
}

initilizeApp();
