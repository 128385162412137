import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Settings from '../views/Settings.vue'
import Auth from '../views/Auth.vue'
import Controllers from '../views/Controllers.vue'
import Dashboard from '../views/Dashboard.vue'
import Log from '../views/Log.vue'
import Map from '../views/Map.vue'
import Programs from '../views/Programs.vue'
import Weather from '../views/Weather.vue'
import ProgramPageEdit from '../views/ProgramPageEdit.vue'
import UserRegistration from '../views/UserRegistration.vue'

// Admin
import Users from '../views/admin/Users.vue'
import CommandConsole from '../views/admin/CommandConsole.vue'
import Server from '../views/admin/Server.vue'
import FactorySetup from '../views/admin/FactorySetup.vue'
import Test from '../views/admin/Test.vue'
import PushSubscriptions from '../views/admin/PushSubscriptions.vue'

const routes = [
	{
		path: '/',
		name: 'Default',
		component: Dashboard
	},
	{
		path: '/home',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings
	},
	{
		path: '/auth',
		name: 'Auth',
		component: Auth
	},
	{
		path: '/userRegistration',
		name: 'UserRegistration',
		component: UserRegistration
	},
	{
		path: '/controllers',
		name: 'Controllers',
		component: Controllers
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard
	},
	{
		path: '/log',
		name: 'Log',
		component: Log
	},
	{
		path: '/programs',
		name: 'Programs',
		component: Programs
	},
	{
		path: '/weather',
		name: 'Weather',
		component: Weather
	},
	{
		path: '/map',
		name: 'Map',
		component: Map
	},
	{
		path: "/ProgramPageEdit",
		name: "ProgramPageEdit",
		component: ProgramPageEdit
	},
	// ========= Admin ==========
	{
		path: '/users',
		name: 'Users',
		component: Users,
		meta: { isAdminMenu: true }
	},
	{
		path: '/command_console',
		name: 'CommandConsole',
		component: CommandConsole,
		meta: { isAdminMenu: true }
	},
	{
		path: '/server',
		name: 'Server',
		component: Server,
		meta: { isAdminMenu: true }
	},
	{
		path: '/factory-setup',
		name: 'FactorySetup',
		component: FactorySetup,
		meta: { isAdminMenu: true }
	},
	{
		path: '/test',
		name: 'Test',
		component: Test,
		meta: { isAdminMenu: true }
	},
	{
		path: '/PushSubscriptions',
		name: 'PushSubscriptions',
		component: PushSubscriptions,
		meta: { isAdminMenu: true }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
