<template>
	<div>
		<div class="columns">
			<div class="column title is-size-6-mobile is-size-3-tablet brand-color">Command console</div>
			<div class="column has-text-right-tablet">
				<span class="ls-is-comment has-text-weight-bold" style="cursor: pointer" @click="openCommandDocumentation(false)">Commands reference:</span>
				<br/>
				<span class="ls-is-comment is-size-7-mobile is-size-7-tablet">https://docs.google.com/document/d/1HdwxCSeyyqjT3Y7v6iiUxNt8KZIMGe11BL4CPVSBZys/edit</span>
			</div>
		</div>
		
		<div class="columns is-vcentered mt-4">
			<div class="column">
				<div class="select is-primary content">
					<select v-model="crtControllerId" class="is-prime-color">
						<option v-for="controller in controllers" v-bind:key="controller.controllerId" v-bind:value="controller.controllerId">[id: {{controller.controllerId}}] {{ controller.name }} ({{ controller.stampId }})</option>
					</select>
				</div>
			</div>
			
			<div class="columns is-vcentered">
				<div class="column has-text-right-tablet ls-no-wrap">
					<span class="ls-is-vam spaceright">Timeout (millis):</span>
					<input type="number" min="1000" max="20000" step="1000" class="number is-prime-color is-size-6 ls-is-vam" v-model="timeout">
				</div>
				<div class="column has-text-right-tablet">
					<span class="ls-is-vam spaceright">Breaks for newlines</span>
					<input type="checkbox" class="ls-is-vam spaceright" v-model="replaceNewline">
				</div>
			</div>

		</div>

		<div class="content">
			<input type="text" class="input is-primary" v-model="commandBody" ref="textboxCommand" autocapitalize="none" autocomplete="off" @focus="$event.target.select()" @keyup.enter="commandExecute()" id="textboxCommand" name="textboxCommand" value="" />
		</div>

		<div class="content" style="text-align: center;">
			<a class="button is-primary" @click="commandExecute()" >Execute</a>
			<a class="button is-primary ml-4" @click="clearLog()" >Clear log</a>
		</div>

		<div ref="logDiv" class="content log-panel">
			<div v-for="(logItem, index) in goptions.commandLog" :key="index" v-html="logItemText(logItem)" v-bind:style="{color : logItemColor(logItem), cursor: logItem.sense == 1 ? 'pointer' : null}" @click="logClicked(index)"></div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "CommandConsole",
	data: function() {
		return {
			controllers: [],
			crtControllerId: 0,
			commandBody: "",
			timeout: this.$gconst.timeouts.medium,
			replaceNewline: true
		}
	},
	created: function() {
		this.getAllControllers();
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		logClicked: function(logIndex) {
			// If the clicked log item is a command, copy the command to the command body text box.
			if (this.goptions.commandLog[logIndex].sense == 1) {
				this.commandBody = this.goptions.commandLog[logIndex].contents;
				this.$refs.textboxCommand.focus();
			}
		},
		getAllControllers: function() {
			this.$ky(this.apiUrl + "/GetAllControllers", {
				method: "GET",
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				this.controllers = responseJson;
				if (this.controllers.length > 0) {
					this.crtControllerId = this.controllers[0].controllerId;
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		commandExecute: function() {
			this.showWait(true);
			this.serialCommandExecute(this.crtControllerId, this.commandBody, this.timeout, false)
			.then((responseJson) => {
				if (!responseJson.success) {
					this.goptions.commandLog.unshift({sense: 2, contents: responseJson.errorDescription, success: false});
				}
				else {
					this.goptions.commandLog.unshift({sense: 2, contents: responseJson.value.messageDetails, success: responseJson.value.messageSuccessCode == 0});
				}
				this.goptions.commandLog.unshift({sense: 1, contents: this.commandBody});
			})
			.finally(() => {
				this.showWait(false);
				// Scroll the log div to top with animation
				this.$refs.logDiv.scroll({
					top: 0,
					behavior: "smooth"  // 👈
				});
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		logItemColor: function (message) {
			var color = "#000000";
			if (message.sense == 1) {		// OUT message
				color = "var(--my-prime-color)";
			}
			else {
				color = (message.success ? "#0077FF" : "#FF3300");
			}
			return color;
		},
		logItemText: function(logItem) {
			return logItem.contents != '' ? (this.replaceNewline ? logItem.contents.replaceAll("\n", "<br/>") : logItem.contents) : '&nbsp;';
		},
		clearLog: function() {
			this.goptions.commandLog.length = 0;
			this.goptions.commandLog = new Array();
		},
		openCommandDocumentation: function(openInNewWindow) {
			const windowFeatures = openInNewWindow ? "width=600,height=400" : null;
			window.open("https://docs.google.com/document/d/1HdwxCSeyyqjT3Y7v6iiUxNt8KZIMGe11BL4CPVSBZys/edit", "_blank", windowFeatures);
		}
	}
}
</script>

<style scoped>
.log-panel {
	overflow-y: scroll;
	overflow-x: auto;
	height: 500px;
	border: solid 1px var(--my-brand-color);
	padding: 5px;
	border-radius: 10px;
}
</style>