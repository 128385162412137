<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('sign_in') }}</div>
		<div class="container">
			<div class="box">
				<div class="columns is-centered is-5 is-variable">
					<div class="column is-half has-text-right-tablet">
						{{ ML.trans('uname_or_email') }}
					</div>
					<div class="column is-half has-text-left">
						<input type="text" ref="userName" v-model="userNameOrEmail" autocapitalize="none" autocomplete="off">
					</div>
				</div>

				<div class="columns is-centered is-5 is-variable">
					<div class="column is-half has-text-right-tablet">
						{{ ML.trans('password') }}
					</div>
					<div class="column is-half has-text-left">
						<input :type="passwordDisplayMode" v-model="password" @keydown.enter="login()">
						<span class="spaceleft ls-feels-link" @click="switchPassDisplay()">{{showPassword ? ML.trans('hide') : ML.trans('show')}}</span>
					</div>
				</div>

				<hr>

				<div class="columns is-centered mt-4">
					<div class="column has-text-centered">
						<a class="button is-primary" @click="login()" >{{ ML.trans('sign_in') }}</a>
					</div>
				</div>

				<div class="columns is-centered mt-3">
					<div class="column has-text-centered">
						{{ ML.trans('if_no_account') }}, <a @click="register()" >{{ ML.trans('click_here') }}</a>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "Auth",
	data: function() {
		return {
			showPassword: false,
			userNameOrEmail: "",
			password: ""
		};
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		passwordDisplayMode: function() {
			return (this.showPassword ? "text" : "password");
		}
	},
	created: function() {
		this.authLogout();
	},
	mounted: function() {
		this.$refs.userName.focus();
	},
	methods: {
		login: function() {
			this.authenticateUser(this.userNameOrEmail, this.password)
			.then((responseJson) => {
				if (responseJson.success) {
					this.goptions.currentuser = responseJson.value.user;
					this.authSetToken(responseJson.value.jwt);
					this.$router.push({ name: "Dashboard", query: { 'nav': true } });
				}
				else {
					if (responseJson.errCode == 401) {
						alert(this.ML.trans('server_inaccessible') + "\n" + this.ML.trans('check_internet_connection'));
					}
					else {
						alert(this.ML.trans('invalid_credentials'));
					}
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		register: function() {
			this.$router.push({ name: "UserRegistration", query: { 'nav': true } });
		},
		switchPassDisplay: function() {
			this.showPassword = !this.showPassword;
		},
		getMqttUsers: function() {
			this.$ky(this.apiUrl + "/GetClients", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				//console.log(responseJson);
				this.clients = responseJson.clients;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
	}
}
</script>

<style>
.spaceleft {
	margin-left: 15px;
}
</style>