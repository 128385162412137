<template>
	<div>
		<div class="columns is-mobile">
			<div class="column has-text-weight-bold is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('controllers') }}</div>
			<div class="column has-text-right">
				<div class="button is-primary" @click="refresh()" :title="ML.trans('refresh')">
					<img
						class="ls-is-vam"
						alt="Refresh"
						src="../assets/images/refresh.svg"
						style="width: 20px"
					/>
					<!-- <span class="spaceleft">{{ ML.trans('refresh') }}</span> -->
				</div>
				<div v-if="isPrincipal()" class="button is-primary spaceleft" @click="addController()" :title="ML.trans('add_controller')">
					<img
						class="ls-is-vam"
						alt="Add"
						src="../assets/images/add.svg"
						style="width: 20px"
					/>
					<!-- <span class="spaceleft">{{ ML.trans('add') }}</span> -->
				</div>
			</div>
		</div>

		<div v-for="controller in controllers" class="container block">
			<div class="box" >

				<div class="columns is-centered is-variable">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ controller.name }}</span>
					</div>
				</div>



				<div class="columns">
					<div class="column has-text-centered">
						<span class="ls-is-label">{{ ML.trans('model') }}: </span>
						<span>{{ controller.stampModel }}</span>
					</div>
					<div class="column has-text-centered">
						<span class="ls-is-label">{{ ML.trans('firmware_version') }}: </span>
						<span>{{ controller.appVersion }}</span>
					</div>
				</div>

				<div class="columns is-mobile">
					<div class="column has-text-right">
						<span class="ls-is-vam ls-is-label">{{ ML.trans('online') }}: </span>
						<!-- <span class="ls-is-vam">???</span> -->
						<div class="ls-circle ls-is-vam" :style="{'border-color': getControllerStateColor(controller)}"></div>
					</div>
					<div class="column has-text-left">
						<span class="ls-is-vam ls-is-label">WiFi: </span>
						<!-- <span class="ls-is-vam">???</span> -->
						<SignalGauge :dataAvailable="controller.info != null" :strength="controller.info != null ? controller.info.signalStrength : 0" class="ls-is-vam" style="height: 15px; width: 30px;" />
					</div>
				</div>


				<hr/>

				<div class="buttons is-centered">
					<a class="button is-primary" @click="toggleDevicesVisibility(controller)">
						<span>
							<img
								class="ls-is-vam"
								alt="Devices"
								src="../assets/images/device.svg"
								style="width: 20px"
							/>
						</span>
						<span class="spaceleft">{{ ML.trans('devices') }}</span>
					</a>
					<a  v-if="hasMinRights(3)" class="button is-primary" @click="editController(controller)">
						<span>
							<img
								class="ls-is-vam"
								alt="Edit"
								src="../assets/images/edit.svg"
								style="width: 20px"
							/>
						</span>
						<span class="spaceleft">{{ ML.trans('edit') }}</span>
					</a>
				</div>

				<div v-if="controller.devicesVisible" style="">

					<hr>

					<div class="columns is-centered">
						<div class="column is-8-tablet">
							<div class="columns is-mobile">
								<div class="column ls-is-label has-text-left">{{ ML.trans('devices') }}</div>
								<div class="column has-text-right">
									<!--
									<a v-if="isPrincipal()" class="button is-primary is-small" @click="editDeviceAdvanced(controller, 0)">
										<span>
											<img
												class="ls-is-vam"
												alt="Edit"
												src="../assets/images/add.svg"
												style="width: 20px"
											/>
										</span>
										<span class="spaceleft">{{ ML.trans('add_device') }}</span>
									</a>
									-->
								</div>
							</div>


							<!-- Device --- Begin -->
							<!-- ---------------- -->
							<div v-for="device in controller.devices" class="ls-devlist-item ls-is-rounded">
								<div class="columns is-mobile">
									<div class="column" style="border-bottom: 1px dotted black;">
										<!-- groove, dotted, ridge -->
										<span>
											<img
												class="ls-is-vam"
												alt="Edit"
												:src="deviceIcon(device)"
												style="width: 40px"
											/>
										</span>
										<span class="has-text-weight-bold">{{device.deviceName}}</span>
										<span v-if="device.boxConnectorNumber > 0" style="float: right;">❇ {{device.boxConnectorNumber}}</span>
									</div>
								</div>
								
								<div class="columns is-mobile">
									<div class="column">
										<div v-if="device.isOutputDevice">
											<FunToggleSwitch v-if="controller.mqttClient != null" :type="2" v-model="device.stateValue" :refObj="device" :on_state_val="device.maxValue" :off_state_val="device.minValue" :enabled="hasMinRights(2)" @hasChenged="deviceValueChanged" />
											<div v-else>
												{{ ML.trans('last_value') }}: {{ device.stateValue }}
											</div>
										</div>
										<div v-if="device.isInputDevice">
											<span v-if="controller.mqttClient != null" class="ls-feels-link" @click="queryDevice(device)">{{ ML.trans('query') }}</span>
											<span v-else>
												{{ ML.trans('no_querry_possible_offline') }}
											</span>
											<span v-if="device.queryValue != null" class="spaceleft">{{ device.queryValue }}</span>
										</div>
									</div>
									<div class="column has-text-right">
										<a v-if="hasMinRights(3)" class="button is-primary is-small" @click="editDevice(device)">
											<span>
													<img
													class="ls-is-vam"
													alt="Edit"
													src="../assets/images/edit.svg"
													style="width: 20px"
												/>
											</span>
											<span class="spaceleft">{{ ML.trans('edit') }}</span>
										</a>
									</div>
								</div>
							</div>
							<!-- Device --- End -->
							<!-- ---------------- -->

						</div>
					</div>
				</div>

			</div>
		</div>

		<ModalPopupFade :visible="controllerAddEditorVisible" columns="6" transDuration="0.5s" :popTitle="editor.controllerId == 0 ? ML.trans('add_controller') : ML.trans('edit_controller')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="addControllerCancel()">
			<ControllerAdd ref="addControllerComponent" v-if="controllerAddEditorVisible" @askClose="addControllerCancel()" @askSave="addControllerSave"></ControllerAdd>
		</ModalPopupFade>

		<ModalPopupFade :visible="controllerEditEditorVisible" columns="6" transDuration="0.5s" :popTitle="ML.trans('edit_controller')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="editControllerCancel()">
			<ControllerEdit v-if="controllerEditEditorVisible" :targetController="editedController" @askClose="editControllerCancel()" @askSave="editControllerSave" @askDelete="editControllerDelete"></ControllerEdit>
		</ModalPopupFade>

		<ModalPopupFade :visible="deviceBasicEditorVisible" columns="6" transDuration="0.5s" :popTitle="ML.trans('edit_device')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="editDeviceCancel()">
			<DeviceEdit v-if="deviceBasicEditorVisible" :targetDevice="editedDevice" @askClose="editDeviceCancel()" @askSave="editDeviceSave" @askDelete="editDeviceDelete"></DeviceEdit>
		</ModalPopupFade>

		<ModalPopupFade :visible="deviceAdvancedEditorVisible" columns="6" transDuration="0.5s" :popTitle="editor.deviceAdv.dbId != 0 ? ML.trans('edit_device') : ML.trans('add_device')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="editDeviceAdvCancel()">
			<DeviceAdvancedEdit ref="devAdvEdit" v-if="deviceAdvancedEditorVisible" :targetDevice="editor.deviceAdv" @askClose="editDeviceAdvCancel()" @askSave="editDeviceAdvSave" @askDelete="editDeviceAdvDelete"></DeviceAdvancedEdit>
		</ModalPopupFade>

	</div>
</template>

<script>
import { reactive, ref } from "vue";
import { mapState, mapMutations, Store } from 'vuex'
import ControllerAdd from "@/components/ControllerAdd.vue"
import ControllerEdit from "@/components/ControllerEdit.vue"
import DeviceEdit from "@/components/DeviceEdit.vue"
import DeviceAdvancedEdit from "@/components/DeviceAsvancedEdit.vue"
import FunToggleSwitch from "@/components/controls/FunToggleSwitch.vue"
import SignalGauge from "@/components/controls/SignalGauge.vue"
import {errorCodes} from "@/components/mixins/errorCodes";


export default {
	name: 'Controllers',
	components: { ControllerAdd, ControllerEdit, FunToggleSwitch, SignalGauge, DeviceEdit, DeviceAdvancedEdit },
	mixins: [errorCodes],
	data: function() {
		return {
			controllers: [],
			controllerAddEditorVisible: false,
			controllerEditEditorVisible: false,
			deviceBasicEditorVisible: false,
			deviceAdvancedEditorVisible: false,
			editor: {
				controllerId: 0,
				deviceAdv: {}
			},
			editedController: null,
			editedDevice: null
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	watch: {
	},
	created: function() {
		this.getControllers();
	},
	methods: {
		queryDevice: function(device) {
			device.queryValue = ref("...");
			this.$ky.get(this.apiUrl + "/QueryDevice", {
				searchParams: {deviceId: device.dbId, timeout: this.$gconst.timeouts.medium}, cache: "no-cache", timeout: this.$gconst.timeouts.medium + 1000, retry: 1})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					device.queryValue = ref(responseJson.value);
				}
				else {
					device.queryValue = ref("?");
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		deviceIcon: function(device) {
			var iconName = "";
			switch(device.deviceType) {
				case "CONST":
					iconName = "constant.svg";
					break;
				case "LED":
					iconName = "relay.svg";
					break;
				case "DHT":
					iconName = "sensor.svg";
					break;
				default:
					iconName = "device.svg";
					break;
			}
			return require("@/assets/images/" + iconName);
		},
		editDeviceAdvCancel: function() {
			this.deviceAdvancedEditorVisible = false;
		},
		editDeviceAdvSave: function(device) {
			this.$ky.post(this.apiUrl + "/EditSaveDeviceAdvanced", {
				cache: "no-cache", json: device, timeout: this.$gconst.timeouts.medium, retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					// Check if the controller has returned an error (i.e. the controller responded, but with an error.)
					if (responseJson.value.messageSuccessCode != 0) {	//Error reported by the controller
						this.$refs.devAdvEdit.setError("Error: " + this.getErrText(responseJson.value.messageSuccessCode));
					}
					else {
						// Refresh the devices of the parent controller.
						var parentController = this.controllers.find(ctrl => ctrl.controllerId == device.controllerId)
						this.getDevices(parentController);
						this.deviceAdvancedEditorVisible = false;
					}
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		editDeviceAdvDelete: function() {
			alert("You shouldn't be here.");
			this.deviceAdvancedEditorVisible = false;
		},
		editDeviceAdvanced: function(controller, deviceId) {
			this.$ky(this.apiUrl + "/GetDevice", {
				method: "GET",
				searchParams: {
					deviceDbId: deviceId,
					timeout: this.$gconst.timeouts.medium
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.bigger,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				this.editor.deviceAdv = responseJson;
				this.editor.deviceAdv.controllerId = controller.controllerId;
				this.deviceAdvancedEditorVisible = true;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		deviceValueChanged: function(device) {
			//console.log(device.dbId, device.stateValue);
			this.$ky(this.apiUrl + "/DeviceSetValue", {
				method: "GET",
				searchParams: {
					deviceDbId: device.dbId,
					newValue: device.stateValue,
					timeout: this.$gconst.timeouts.medium
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.bigger,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.errorCode == 2) {		// Timeout
					alert(this.ML.trans('dev_not_responding'));
					device.stateValue = device.stateValue == device.maxValue ? device.minValue : device.maxValue;
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		toggleDevicesVisibility: function(controller) {
			controller.devicesVisible = !controller.devicesVisible;
			if (controller.devicesVisible) {
				// Refresh the devices, just in case.
				this.getDevices(controller);
			}
		},
		getDevices: function(controller) {
			this.$ky(this.apiUrl + "/GetControllerDevices", {
				method: "GET",
				searchParams: {
					controllerId: controller.controllerId,
					excludePermanentDevices: false,
					excludeCustomDevices: false
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				responseJson.sort((a, b) => a.boxConnectorNumber < b.boxConnectorNumber ? -1 : 1);
				controller.devices = reactive(responseJson);
				console.log(responseJson);
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		refresh: function() {
			this.controllers.forEach(controller => {
				controller.mqttClient = ref(undefined);
			});
			this.showWait(true);
			this.getControllers();
		},

		editDevice: function(device) {
			this.editedDevice = device;
			this.deviceBasicEditorVisible = true;
		},
		editDeviceCancel: function() {
			this.deviceBasicEditorVisible = false;
		},
		editDeviceSave: function(device) {
			this.$ky(this.apiUrl + "/EditSaveDeviceBasic", {
				method: "GET",
				searchParams: {
					...device		// [KH]
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					//this.getControllers();
					// Find the parent controller and refresh all its edevices.
					var controller = this.controllers.find(controller => controller.controllerId == device.controllerId);
					this.getDevices(controller);
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.deviceBasicEditorVisible = false;
			});
		},
		editDeviceDelete: function(device) {
			if (device.isPermanent) {
				alert(this.ML.trans('cannot_del_perm_dev_in_basic_mode'));
				this.deviceBasicEditorVisible = false;
				return;
			}
			this.$ky.get(this.apiUrl + "/DeleteDevice", {
				searchParams: {deviceDbId: device.dbId}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
				.json()
				.then(response => {
					if (!response.success) {
						alert(response.errorDescription);
					}
					else {
						// Refresh device list
						var parentController = this.controllers.find(ctrl => ctrl.controllerId == device.controllerId)
						this.getDevices(parentController);
						this.deviceBasicEditorVisible = false;
					}
				})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},



		editController: function(controller) {
			this.editedController = controller;
			this.controllerEditEditorVisible = true;
		},
		editControllerCancel: function() {
			this.controllerEditEditorVisible = false;
		},
		editControllerSave: function(controller) {
			this.$ky.post(this.apiUrl + "/EditSaveController", {
				cache: "no-cache", json: controller, timeout: this.$gconst.timeouts.medium, retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					this.getControllers();
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.controllerEditEditorVisible = false;
			});
		},
		editControllerDelete: function(controller) {
			this.$ky(this.apiUrl + "/RemoveControllerFromUser", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					controllerId: controller.controllerId,
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					this.getControllers();
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.controllerEditEditorVisible = false;
			});
		},
		addController: function() {
			this.controllerAddEditorVisible = true;
		},
		addControllerCancel: function(data) {
			this.$refs.addControllerComponent.clearPairing();	// If a pairing was made, clear it.

			this.controllerAddEditorVisible = false;
		},
		addControllerSave: function(data) {
			this.showWait(true);

			this.$ky(this.apiUrl + "/AttachControllerToUser", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					pairingToken: data.pairing.pairingToken,
					stampId: data.pairing.controllerStampId,
					controllerName: data.controllerName
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.errorCode != 0) {
					alert(responseJson.errorDescription);
				}
				else {
					this.getControllers();
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
				this.controllerAddEditorVisible = false;
			});
		},
		getControllers: function() {
			this.getUserControllers()
			.then((responseJson) => {
				if (responseJson.success) {
					responseJson.value.forEach(controller => {
						controller = reactive(controller);
						controller.mqttClient = ref(undefined);
						controller.devicesVisible = ref(false);
					});
					this.controllers = responseJson.value.sort((ctrl1, ctrl2) => ctrl1.name < ctrl2.name ? -1 : 1);
					this.controllers.forEach(controller => {
						this.updateControllerState(controller);
					});
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
			});
		}
	}
}
</script>

<style>
</style>