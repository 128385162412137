import { createStore } from 'vuex'

import MultiLanguage from "./storeML"

export default createStore({
	state: {
		goptions: {
			currentuser: null,
			isUserAdmin: false,
			impersLastRouteAdmin: "",
			impersLastRouteUser: "",
			adminUI: true,
			bulmaMobileMenu: false,
			waitPopupVisible: false,
			commandLog: [],
			localSettings: {
			},
			cachedJwt: null,
			serviceWorkerState: {
				newSwAvailable: false,
				deferredInstallPrompt: null
			},
			notificationsEnabled: false,
			notificationsState: ""
		}
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		MultiLanguage
	}
})
