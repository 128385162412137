export const datesLib = {
	data() {
		return {
			convertToDate: function (str) {
				try {
					var arrMainAtoms = str.split(" ");
					var dateAtoms = arrMainAtoms[0].split("-");
					var timeAtoms = arrMainAtoms[1].split(":");
		
					for (var i = 0; i < 3; i++) {
						dateAtoms[i] = Number(dateAtoms[i]);
					}
					for (var i = 0; i < 3; i++) {
						timeAtoms[i] = Number(timeAtoms[i]);
					}
					var d = new Date(dateAtoms[0], dateAtoms[1] - 1, dateAtoms[2], timeAtoms[0], timeAtoms[1], timeAtoms[2], 0);
					if (d.toString() == "Invalid Date") {
						return null;
					}
					// Check against the initial string.
					dateAtoms[0] = d.getFullYear().toString();
					dateAtoms[1] = (d.getMonth() + 1 <= 9 ? "0" : "") + (d.getMonth() + 1);
					dateAtoms[2] = (d.getDate() <= 9 ? "0" : "") + d.getDate().toString();
					timeAtoms[0] = (d.getHours() <= 9 ? "0" : "") + d.getHours();
					timeAtoms[1] = (d.getMinutes() <= 9 ? "0" : "") + d.getMinutes();
					timeAtoms[2] = (d.getSeconds() <= 9 ? "0" : "") + d.getSeconds();
					var dStr = dateAtoms[0] + "-" + dateAtoms[1] + "-" + dateAtoms[2] + " " + timeAtoms[0] + ":" + timeAtoms[1] + ":" + timeAtoms[2];
					if (dStr != str) {
						return null;
					}
				}
				catch (err) {
					return null;
				}
		
				return d;
			},
			timespanStrToSeconds: function(timespanStr) {
				var timeAtoms = timespanStr.split(":");
				var seconds = Number(timeAtoms[0] * 3600) + Number(timeAtoms[1] * 60 + Number(timeAtoms[2]));
				return seconds;
			},
			dateAddTimeSpanStr: function(dateStr, timespanStr) {
				return this.dateAddSeconds(this.convertToDate(dateStr), this.timespanStrToSeconds(timespanStr));
			},
			//======================================================================
			DmyStringToDate: function(dateStr) {
				/*
				* Converts a string in d-m-y format, to a date, and returns it.
				* If the conversion fails, null is returned
				*/
				// Split and check the number of atoms.
				var arr = dateStr.split("-");
				if (arr.length != 3) {
					return null;
				}
				for (var i = 0; i < 3; i++) {
					if (isNaN(arr[i])) {
						return null;
					}
				}

				var day = 1 * arr[0];
				var month = 1 * arr[1];
				var year = 1 * arr[2];

				// Rough test - the Date object is generally too permissive, so this won't filter much.
				var isDate = true;
				var date = null;
				try {
					date = new Date(year, month - 1, day);
				}
				catch (ex) {
					isDate = false;
				}
				if (!isDate) {
					return null;
				}

				// Compare the date fields with the atom numeric values.
				var d = date.getDate();
				var m = date.getMonth();
				var y = date.getFullYear();
				if (date.getDate() != day || date.getMonth() != month - 1 || date.getFullYear() != year) {
					return null;
				}

				return date;
			},
			UnixUtcToLocalDateString: function (d, dateFormat, partsToInclude, dateSeparator, timeSeparator, dtSeparator) {
				// IMPORTANT:
				// It looks like the Date created based un a Unix datetime considers the input (Unix datetime) as UTC, and applies
				// automatically the client's timezone to the resulting datetime.
				// Therefore, the resulting Date object represents the UTC time (the Unix datetime) automatically converted to local datetime.
				var date = new Date(d * 1000);

				return this.dateToIsoStr(date, dateFormat, partsToInclude, dateSeparator, timeSeparator, dtSeparator);
			},
			getUTCNowDateTime: function () {
				var now = new Date();
				return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
			},
			getTimeZoneOffset: function () {
				var d = new Date();
				return d.getTimezoneOffset(); // Return the timezone difference between UTC and Local Time, in minutes. Ex: If your time zone is GMT+2, -120 will be returned.
			},
			getDateFromIso8601String: function(dateStr) {
				/*
				Converts a string in the Iso8601 format (ex. "2021-01-12T08:00:00") to a
				javascript Date object.
				*/
				return new Date(dateStr);
			},
			isoDateStrToIso8601: function(isoDateStr) {
				return isoDateStr.replace(" ", "T");
			},
			reformatIsoDateString(dateTimeStr, format, dateSeparator, includeTime) {
				// Takes a ISO date string and re-formats the date part
				// according to the format parameter.
				try {
					// Formats: DMY, MDY, YMD etc.
					// 2021-03-01 11:12:13

					var arrDateParts = ["", "", ""];
					format = format.toUpperCase();
					var dateStr = dateTimeStr.substr(0, 10);
					var timeStr = dateTimeStr.substr(11, 8);

					var year = dateStr.substr(0, 4);
					var month = dateStr.substr(5, 2);
					var day = dateStr.substr(8, 2);

					var yearPos = format.indexOf("Y");
					var monthPos = format.indexOf("M");
					var dayPos = format.indexOf("D");

					arrDateParts[yearPos] = year;
					arrDateParts[monthPos] = month;
					arrDateParts[dayPos] = day;

					return arrDateParts.join(dateSeparator) + (includeTime ? " " + timeStr : "");
				}
				catch(ex) {
					return "??????";
				}
			},
			dateToIsoStr: function(date, dateFormat, partsToInclude, dateSeparator, timeSeparator, dtSeparator) {
				/*
				Converts a javascript Date object to an ISO formatted string.
					dateFormat: DMY, MDY, YMD etc.
					partsToInclude: DT, D, or T. Indicates what is to be returned - the date part, the time part, or both.
					dtSeparator: if DT format is specified, this is the delimiter between the date part and the time part.
				*/
				// Defaults
				dateFormat = (dateFormat != null ? dateFormat : "DMY").toUpperCase();
				partsToInclude = (partsToInclude != null ? partsToInclude : "DT");
				dateSeparator = (dateSeparator != null ? dateSeparator : "-");
				timeSeparator = (timeSeparator != null ? timeSeparator : ":");
				dtSeparator = (dtSeparator != null ? dtSeparator : " ");

				var includeDate = partsToInclude.includes("D");
				var includeTime = partsToInclude.includes("T");

				var dayStr = String(date.getDate()).padStart(2, '0');
				var monthStr = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yearStr = date.getFullYear();
				var hourStr = String(date.getHours()).padStart(2, '0');
				var minuteStr = String(date.getMinutes()).padStart(2, '0');
				var secondStr = String(date.getSeconds()).padStart(2, '0');

				// Arrange the date part according to the required format.
				var arrDateParts = ["", "", ""];
				var yearPos = dateFormat.indexOf("Y");
				var monthPos = dateFormat.indexOf("M");
				var dayPos = dateFormat.indexOf("D");
				arrDateParts[yearPos] = yearStr;
				arrDateParts[monthPos] = monthStr;
				arrDateParts[dayPos] = dayStr;
				
				var datePartStr = arrDateParts.join(dateSeparator);
				var timePartStr = [hourStr, minuteStr, secondStr].join(timeSeparator);

				return (includeDate ? datePartStr : "") + (includeDate && includeTime ? dtSeparator : "") + (includeTime ? timePartStr : "");
			},
			datesDiff: function(date1, date2, unit) {
				/*
				Returns the difference between two dates, either in days (default), hours, minutes
				or seconds.
				*/
				// Defaults
				unit = (unit != null ? unit : "d");		// "d" (days), "h" (hours), "m" (minutes), "s" (seconds)

				if (!["d", "h", "m", "s"].includes(unit)) {
					unit = "d";
				}

				var factor = 0;
				if (unit == "d") {
					factor = 1000*60*60*24;
				} else if (unit == "h") {
					factor = 1000*60*60;
				} else if (unit == "m") {
					factor = 1000*60;
				} else {		// "s" remained
					factor = 1000;
				}

				var millis = date1.getTime() - date2.getTime();
				return millis / factor;
			},
			dateAddDays: function (date, days) {
				const copy = new Date(Number(date));
				copy.setDate(date.getDate() + days);
				return copy;
			},
			dateAddMinutes: function(date, minutes) {
				const copy = new Date(Number(date));
				copy.setMinutes(date.getMinutes() + minutes);
				return copy;
			},
			dateAddSeconds: function(date, seconds) {
				const copy = new Date(Number(date));
				copy.setSeconds(date.getSeconds() + seconds);
				return copy;
			},
			formatIsoDateString(dateTimeStr, format, dateSeparator, includeTime) {
				try {
					// Formats: DMY, MDY, YMD etc.
					// 2021-03-01 11:12:13

					var arrDateParts = ["", "", ""];
					format = format.toUpperCase();
					var dateStr = dateTimeStr.substr(0, 10);
					var timeStr = dateTimeStr.substr(11, 8);

					var year = dateStr.substr(0, 4);
					var month = dateStr.substr(5, 2);
					var day = dateStr.substr(8, 2);

					var yearPos = format.indexOf("Y");
					var monthPos = format.indexOf("M");
					var dayPos = format.indexOf("D");

					arrDateParts[yearPos] = year;
					arrDateParts[monthPos] = month;
					arrDateParts[dayPos] = day;

					return arrDateParts.join(dateSeparator) + (includeTime ? " " + timeStr : "");
				}
				catch(ex) {
					return "??????";
				}
			},
			localTimeStrFromUtcDateStr: function(utcDateStr, timezoneOffsetMinutes, dstShiftMinutes) {
				// Returns, as string, a local time based on the value utcDateStr (considered as UTC date-time)
				// shifted with an offset (in minutes).
				if (dstShiftMinutes == null) {
					dstShiftMinutes = 0;
				}
				return this.dateToIsoStr(this.dateAddMinutes(this.convertToDate(utcDateStr), timezoneOffsetMinutes + dstShiftMinutes), "YMD", "T");
			},
			localDateTimeStrFromUtcDateStr: function(utcDateStr, timezoneOffsetMinutes, dstShiftMinutes) {
				// Returns, as string, a local date-time based on the value utcDateStr (considered as UTC date-time)
				// shifted with an offset (in minutes) and (optionally) a DST (daylight saving time) shift (in minutes).
				if (dstShiftMinutes == null) {
					dstShiftMinutes = 0;
				}
				return this.dateToIsoStr(this.dateAddMinutes(this.convertToDate(utcDateStr), timezoneOffsetMinutes + dstShiftMinutes), "YMD", "DT");
			},
			localDateTimeFromUtcDateStrAuto: function(utcDateTimeStr) {
				// Converts a date-time string in format "YYYY-MM-DD hh:mm:ss" to local date, by using the
				// default javascript conversion. "UTC" is added to the UTC date string to specify it's an UTC date.
				return new Date(utcDateTimeStr + " UTC");
			},
			utcDateTimeStrWithSetTimeStr: function(localTimeStr, timezoneOffsetMinutes, dstShiftMinutes) {
				// Converts a local time string to the corresponding UTC date-time, and returns it as ISO string.

				if (dstShiftMinutes == null) {
					dstShiftMinutes = 0;
				}
				// Set the "local" date-time (considering the passed timezone offset)
				// The date part will be used for the job's dueDateTime date part.
				// Reason: due to time diff, the current user date part can be different than the browser local date part.
				var dtUserLocal = this.dateAddMinutes(this.getUTCNowDateTime(), timezoneOffsetMinutes + dstShiftMinutes);
				// Now combine the "normalized" date part with the specified local time.
				// => the job's due date-time, in the user's timezone
				var withLocalTimeSet = this.dateToIsoStr(dtUserLocal, "YMD", "D") + " " + localTimeStr;
				// Convert the above user (local) date-time to UTC.
				var utcDateTime = this.dateAddMinutes(this.convertToDate(withLocalTimeSet), -(timezoneOffsetMinutes + dstShiftMinutes));

				return this.dateToIsoStr(utcDateTime, "YMD", "DT");
			},
			timeStringFormat: function(timeStr, format) {
				if (format == null) {
					format = "HMS";
				}
				var atoms = timeStr.split(":");
				if (atoms.length != 3) {
					return timeStr;
				}
				var hPos = format.indexOf("H");
				var mPos = format.indexOf("M");
				var sPos = format.indexOf("S");

				var arrTimeParts = Array(format.length).fill("");
				if (hPos != -1) {
					arrTimeParts[hPos] = atoms[0];
				}
				if (mPos != -1) {
					arrTimeParts[mPos] = atoms[1];
				}
				if (sPos != -1) {
					arrTimeParts[sPos] = atoms[2];
				}

				return arrTimeParts.join(":");
			}
		}
	}
}
