<template>
	<div>
		<div class="columns ">
			<div class="column is-12">
				<div class="element-label">Mount cmd</div>
				<label>000,smd,</label>
				<input type="text" v-model="device.mountCommand" placeholder="Enter the mount command" style="width: 100%;">
			</div>
		</div>
		<div class=columns>
			<div v-if="errorText != null" class="column has-text-danger">{{errorText}}</div>
		</div>
		<div class="columns whitespace-preserve is-mobile">
			<div class="column whitespace-preserve is-narrow element-label">Auto Mount</div>
			<div class="column is-narrow"><input type="checkbox" id="checkboxDeviceAutoMount" name="checkboxDeviceAutoMount" value="yes" v-model="device.autoMount" /></div>
		</div>
		<div class="columns is-mobile">
			<div class="column whitespace-preserve is-narrow element-label">Min value</div>
			<div class="column is-narrow"><input type="text" id="textboxMinValue" name="textboxMinValue" v-model="device.minValue" size="10" /></div>
		</div>
		<div class="columns is-mobile">
			<div class="column whitespace-preserve is-narrow element-label">Max value</div>
			<div class="column is-narrow"><input type="text" id="textboxMaxValue" name="textboxMaxValue" v-model="device.maxValue" size="10" /></div>
		</div>

		<div class="buttons is-centered">
			<a class="button is-primary" @click.prevent="doSave()">OK</a>
			<a class="button is-primary" @click.prevent="doCancel()">Cancel</a>
			<a v-if="device.isPermanent" class="button is-primary" @click.prevent="doDelete()">Delete</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "DeviceAdvancedEdit",
	props: ["targetDevice"],
	data: function() {
		return {
			device: null,
			errorText: null
		};
	},
	created: function() {
		this.device = {...this.targetDevice};

		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	methods: {
		setError: function(errorText) {
			this.errorText = errorText;
		},
		onKeydown: function(event) {
			// Manage the Enter key down
			if (event.keyCode == 13) {
				// Trigger the default action
				this.doSave();
			}
		},
		doSave: function() {
			this.$emit("askSave", this.device);
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		},
		doDelete: function() {
			if (confirm("Are you sure you want to remove the device \"" + this.device.deviceName + "?")) {
				this.$emit("askDelete", this.device);
			}
		}
	}
}
</script>

<style>

</style>
