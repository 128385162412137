<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('user_registration') }}</div>
		<div class="container">
			<div class="box">
				<div v-if="wizz.step == 0">
					<div class="columns is-centered is-5 is-variable">
						<div class="column is-half has-text-right-tablet">
							{{ ML.trans('user_name') }}
						</div>
						<div class="column is-half has-text-left">
							<input type="text" ref="username" v-model="user.name" placeholder="Specify a user name">
							<span v-if="err.name != ''" class="spaceleft has-text-danger"><br>{{ err.name }}</span>	<!-- error message -->
						</div>
					</div>

					<div class="columns is-centered is-5 is-variable">
						<div class="column is-half has-text-right-tablet">
							Email
						</div>
						<div class="column is-half has-text-left">
							<input type="text" ref="email" v-model="user.email" placeholder="Your email address">
							<span v-if="err.email != ''" class="spaceleft has-text-danger"><br>{{ err.email }}</span> <!-- error message -->
						</div>
					</div>

					<div class="columns is-centered is-5 is-variable">
						<div class="column is-half has-text-right-tablet">
							{{ ML.trans('password') }}
						</div>
						<div class="column is-half has-text-left">
							<input :type="passwordDisplayMode" v-model="user.password" placeholder="At least 6 characters">
							<span class="spaceleft ls-feels-link" @click="switchPassDisplay()">{{passwordDisplayButtonText}}</span>
							<span v-if="err.password != ''" class="spaceleft has-text-danger"><br>{{ err.password }}</span>	<!-- error message -->
						</div>
					</div>
				</div>


				<div v-if="wizz.step == 1">
					<ControllerPairing @pairingAttempted="pairingAttemptDone" />
				</div>

				<hr>

				<div class="columns is-centered is-8 is-variable is-mobile">
					<div v-if="wizzVisible('prev')" class="column has-text-centered">
						<a class="button is-primary" @click="wizNav('prev')">{{ ML.trans('back') }}</a>
					</div>
					<div v-if="wizzVisible('next')" class="column has-text-centered">
						<a class="button is-primary" @click="wizNav('next')">{{ ML.trans('continue') }}</a>
					</div>
					<div v-if="wizzVisible('finish')" class="column has-text-centered">
						<a class="button is-primary" @click="wizNav('finish')">{{ ML.trans('finish') }}</a>
					</div>
					<div class="column has-text-centered">
						<a class="button is-danger" @click="wizNav('cancel')">{{ ML.trans('cancel') }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import ControllerPairing from "@/components/ControllerPairing.vue"

export default {
	name: "UserRegistration",
	components: {ControllerPairing},
	data: function() {
		return {
			showPassword: false,
			validControllerPairing: null,
			passwordMinLength: 6,
			controllerId: 0,
			wizz: {
				step: 0,
				totalSteps: 2
			},
			user: {
				name: "",
				password: "",
				email: ""
			},
			err: {
				email: "",
				name: "",
				password: "",
			},
			userInfo: {
				geoLocation: ""
			}
		};
	},
	created: function() {
		//this.setCurrentGeoLocation();
	},
	mounted: function() {
		this.$refs.username.focus();
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		passwordDisplayMode: function() {
			return (this.showPassword ? "text" : "password");
		},
		passwordDisplayButtonText: function() {
			return (this.showPassword ? "Hide" : "Show");
		}
	},
	methods: {
		pairingAttemptDone: function(pairing) {
			this.validControllerPairing = pairing;
			if (this.validControllerPairing != null) {
				this.getPairingControllerInfo();
			}
		},
		clearPairing: function() {
			// Set the controller's pairing mode to off (if pairing is in course). This will remove also the pairing info from the controller.
			if (this.validControllerPairing != null) {
				this.serialCommandExecute(this.controllerId, "pairoff", 3000, false)
				.then((responseJson) => {
				})
				.catch((err) => {
					alert("Error: " + err.message);
				});
			}
		},
		getPairingControllerInfo: function() {
			this.getPairingControllerByStampId(this.validControllerPairing.controllerStampId)
			.then((responseJson) => {
				if (responseJson.success) {
					this.controllerId = responseJson.value.controllerId;
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		registerAndLogin: function() {
			this.showWait(true);
			this.$ky(this.apiUrl + "/SaveRegistrationAndPairing", {
				method: "GET",
				searchParams: {
					userName: this.user.name,
					userPassword: this.user.password,
					userEmail: this.user.email,
					pairingToken: this.validControllerPairing.pairingToken,
					stampId: this.validControllerPairing.controllerStampId,
					utcOffset: -this.getTimeZoneOffset(),
					geoLocation: this.userInfo.geoLocation
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.errorCode != 0) {
					alert(responseJson.errorDescription);
				}
				else {
					this.goptions.currentuser = responseJson.value.user;
					this.authSetToken(responseJson.value.jwt);
					this.$router.push({ name: "Dashboard", query: { 'nav': true } });
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
			});
		},
		wizNav: async function(to) {
			switch(to) {
				case "prev":
					this.wizz.step--;
					break;
				case "next":
					if (await this.wizzValidateStep()) {
						this.wizz.step++;
					}
					break;
				case "finish":
					if (await this.wizzValidateStep()) {
						this.registerAndLogin();
						this.clearPairing();
					}
					break;
				case "cancel":
					this.clearPairing();
					this.$router.push({ name: "Auth", query: { 'nav': true } });
					break;
			}
		},
		wizzValidateStep: async function() {
			this.err.email = this.err.name = this.err.password = "";
			switch(this.wizz.step) {
				case 0:
					this.user.name = this.user.name.trim();
					this.user.password = this.user.password.trim();
					this.user.email = this.user.email.trim();

					var hasErrors = false;
					if (this.user.name == "") {
						this.err.name = this.ML.trans('cannot_be_empty');
						hasErrors = true;
					}

					if (this.user.password == "") {
						this.err.password = this.ML.trans('cannot_be_empty');
						hasErrors = true;
					}
					else if (this.user.password.length < this.passwordMinLength) {
						this.err.password = this.ML.trans('min_char_count') + ": " + this.passwordMinLength;
						hasErrors = true;
					}

					if (this.user.email == "") {
						this.err.email = this.ML.trans('cannot_be_empty');
						hasErrors = true;
					}
					else if (this.validateEmail(this.user.email)) {
						this.err.email = this.ML.trans('invalid_email');
						hasErrors = true;
					}

					if (!hasErrors) {	// Don't bother to check for uniqueness if there are already errors.
						var result = await this.checkUserMainInfoUnique(this.user);
						if (!result.isUnique) {
							hasErrors = true;
							if (result.nameExists) {
								this.err.name = this.ML.trans('name_already_used');
							}
							if (result.emailExists) {
								this.err.email = this.ML.trans('email_already_used');
							}
						}
					}

					return !hasErrors;
				case 1:
					if (this.validControllerPairing == null) {
						return false;
					}
					return true;
				default:
					alert("Validation not implemented for step " + this.wizz.step);
					return false;
			}
		},
		validateEmail: function (email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return !re.test(String(email).toLowerCase());
		},
		wizzVisible: function(buttonType) {
			switch (buttonType)
			{
				case "prev":
					return this.wizz.step > 0;
					break
				case "next":
					return this.wizz.step < this.wizz.totalSteps - 1;
					break
				case "finish":
					return this.wizz.step == this.wizz.totalSteps - 1;
					break
			}
		},
		switchPassDisplay: function() {
			this.showPassword = !this.showPassword;
		},
		setCurrentGeoLocation: function() {
			if ("geolocation" in navigator) {
				var userInfo = this.userInfo;
				navigator.geolocation.getCurrentPosition(function (location) {
					userInfo.geoLocation = location.coords.latitude.toFixed(4) + "," + location.coords.longitude.toFixed(4);
				});
			}
		}
	}
}
</script>

<style>
</style>