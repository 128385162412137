<template>
	<div>
		<div class="columns is-mobile">
			<div class="column title is-size-4-mobile is-size-3-tablet is-narrow brand-color">{{ ML.trans('settings') }}</div>
			<div class="column has-text-right sectionTitle" >
				<span class="ls-is-vam">{{ ML.trans('version') }} {{ appVersion }}</span>
				<span class="is-hidden-tablet spaceleft">
					<img v-if="themeIsDark" class="ls-is-vam ls-menu-icon" @click="toggleTheme(false)" src="@/assets/images/sun.svg" />
					<img v-else class="ls-is-vam ls-menu-icon" @click="toggleTheme(true)" src="@/assets/images/moon.svg" />
				</span>
				<span class="is-hidden-tablet spaceleft ls-feels-link" @click="logout()">{{ ML.trans('sign_out') }}</span>
			</div>
		</div>
		
		<!-- Push messages registration -->
		<div class="container block">
			<div class="box">
				<div class="columns is-centered is-variable mb-4">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('notifications') }}</span>
						<br/>
						<span class="is-size-7">{{ ML.trans('on_this_device', 'l') }}</span>
					</div>
				</div>

				<div class="columns">
					<div class="column">
						<!--
						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">Current notification permission</span>
							</div>
							<div class="column">
								{{ getNotificationPermission() }}
							</div>
						</div>

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">Subscription info</span>
							</div>
							<div class="column">
								{{ notification.subscription != null ? notification.subscription : "(none)" }}
							</div>
						</div>
						-->

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('subscription') }}</span>
							</div>
							<div class="column">
								<div v-if="notification.subscription != null" class="mb-2">{{ ML.trans('subscribed') }}</div>
								<div v-else="" class="mb-2">{{ ML.trans('not_subscribed') }}</div>

								<button v-if="notification.state.permission == notifPermission.default || (notification.state.permission == notifPermission.granted && notification.subscription == null)" class="button is-primary" @click="registerAllChain()">{{ ML.trans('subscribe') }}</button>
								<button v-if="notification.subscription != null" class="button is-primary" @click="unregisterAllChain()">{{ ML.trans('unsubscribe') }}</button>
								<span v-if="notification.subscription == null && notification.state.permission == notifPermission.denied" class="has-text-danger">{{ ML.trans('subscribe_denied') }}</span>
							</div>
						</div>

						<div v-if="notification.userServerSubscription != null" class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('notified_events') }}</span>
							</div>
							<div class="column">
								<input type="checkbox" class="spaceright" v-model="notification.notiFilter.progStartFinish" />
								<span >{{ ML.trans('program') }}</span>
								<br/>
								<input type="checkbox" class="spaceright" v-model="notification.notiFilter.executionIssues" />
								<span>{{ ML.trans('incidents') }}</span>
								<br/>
								<input type="checkbox" class="spaceright" v-model="notification.notiFilter.appUpdates" />
								<span>{{ ML.trans('updates') }}</span>
								<br/>
								<input type="checkbox" class="spaceright" v-model="notification.notiFilter.connection" />
								<span>{{ ML.trans('connection') }}</span>
							</div>
						</div>


					</div>
				</div>

			</div>
		</div>

		<!-- Secondary users -->
		<div v-if="isPrincipal()" class="container block">
			<div class="box">
				<div class="columns is-centered is-variable mb-4">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('secondary_users') }}</span>
					</div>
				</div>


				<div v-for="user in childUsers" class="columns is-mobile is-vcentered">
					<div class="column has-text-right mr-3">
						<span class="ls-is-vam ls-is-label">{{ user.userName }}</span>
					</div>

					<div class="column">
						<div class="columns is-gapless is-multiline is-mobile">
							<div class="column is-narrow ls-is-vam">
								<span class="select is-primary spaceleft ls-is-vam">
									<select class="is-prime-color" id="comboRights" v-model="user.rights" @change="childUserRightsChanged(user)">
										<option v-for="right in enumPresenter.childUserRights" v-bind:value="right.value" :key="right.value">
											{{ ML.trans(getMlidFromVal(enumPresenter.childUserRights, right.value, "notdef")) }}
										</option>
									</select>
								</span>
								<br>
								<span class="spaceleft ls-feels-link ls-is-vam" @click="deleteChildUser(user)">{{ ML.trans('delete') }}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="childUsers.length == 0" class="has-text-centered mb-3">(none)</div>


				<div class="columns">
					<div class="column has-text-centered">
						<a v-if="!childUserEdit.isEditing" class="button is-primary" @click="addChildUser()">{{ ML.trans('add') }}</a>
						<div v-if="childUserEdit.isEditing">
							<div>{{ ML.trans('user_name') }}</div>
							<div>
								<input type="text" ref="username" v-model="childUserEdit.name" size="30" :placeholder="ML.trans('spec_user_name')">
							</div>
							<div v-if="childUserEdit.err.name != ''" class="has-text-danger">{{ childUserEdit.err.name }}</div>	<!-- error message -->
							<div class="mt-3">Email</div>
							<div>
								<input type="text" ref="email" v-model="childUserEdit.email" size="30" :placeholder="ML.trans('email_address')">
							</div>
							<div v-if="childUserEdit.err.email != ''" class="has-text-danger">{{ childUserEdit.err.email }}</div> <!-- error message -->
							<div v-if="childUserEdit.isEditing" class="buttons is-grouped mt-3 is-centered">
								<a class="button is-primary" @click="saveChildUser()">{{ ML.trans('save') }}</a>
								<a class="button is-primary" @click="cancelAddChildUser()">{{ ML.trans('cancel') }}</a>
							</div>
						</div>
					</div>
				</div>



			</div>
		</div>

		<!-- Local settings -->
		<div class="container block">
			<div class="box">
				<div class="columns is-centered is-variable mb-4">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('preferences') }}</span>
					</div>
				</div>

				<div class="columns">
					<div class="column">

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans("language") }}:</span>
							</div>
							<div class="column">
								<span class="ls-is-vam select is-primary content">
									<select v-model="currentLanguage" class="is-prime-color">
										<option value="en">English</option>
										<option value="fr">Français</option>
										<option value="ro">Română</option>
									</select>
								</span>
							</div>
						</div>

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans("date_format") }}:</span>
							</div>
							<div class="column">
								<span class="ls-is-vam select is-primary content">
									<select v-model="dateFormat" class="is-prime-color">
										<option value="DMY">{{ ML.trans("day") }}-{{ ML.trans("month") }}-{{ ML.trans("year") }}</option>
										<option value="MDY">{{ ML.trans("month") }}-{{ ML.trans("day") }}-{{ ML.trans("year") }}</option>
										<option value="YMD">{{ ML.trans("year") }}-{{ ML.trans("month") }}-{{ ML.trans("day") }}</option>
									</select>
								</span>
							</div>
						</div>

						<!-- Session timeout - not used -->
						<!-- <div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans("session_timeout") }} ({{ ML.trans("minutes").toLowerCase() }}):</span>
							</div>
							<div class="column">
								<span class="ls-is-vam select is-primary content">
									<select v-model="sessionTimeout" class="is-prime-color">
										<option :value="0">{{ ML.trans("never").toLowerCase() }}</option>
										<option :value="1">1</option>
										<option :value="5">5</option>
										<option :value="15">15</option>
										<option :value="60">60</option>
									</select>
								</span>
							</div>
						</div> -->

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans("keep_me_signed") }}</span>
							</div>
							<div class="column">
								<span class="ls-is-vam spaceright"><input type="checkbox" v-model="goptions.localSettings.keepMeSigned" title="Burger menu" /></span>
							</div>
						</div>

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label" style="color: #aaaa00;">Mobile popup menu</span>
							</div>
							<div class="column">
								<span class="ls-is-vam spaceright"><input type="checkbox" v-model="goptions.bulmaMobileMenu" title="Burger menu" /></span>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>


		<!-- Account settings -->
		<div class="container block">
			<div class="box">
				<div class="columns is-centered is-variable mb-4">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('account') }}: {{user.userName}}</span>
					</div>
				</div>

				<div class="columns">
					<div class="column">

						<div v-if="isPrincipal()" class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('time_zone') }}</span>
							</div>
							<div class="column">
								<span class="ls-is-vam select is-primary content">
									<select v-model="user.utcOffset" :disabled="userJobsExecuting || (!isPrincipal())" class="is-prime-color" :title="userJobsExecuting ? ML.trans('job_exec_no_timezone') : ''">
										<option v-for="offset in utcOffsets" :value="offset.value">{{ offset.label }}</option>
									</select>
								</span>
							</div>
						</div>

						<div v-if="isPrincipal()" class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('dst') }}</span>
							</div>
							<div class="column">
								<span class="ls-is-vam spaceright"><input type="checkbox" v-model="user.isDST" :disabled="userJobsExecuting || (!isPrincipal())" :title="userJobsExecuting ? ML.trans('job_exec_no_timezone') : ''" /></span>
							</div>
						</div>

						<div v-if="isPrincipal()" class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('first_day_of_week') }}</span>
							</div>
							<div class="column">
								<span class="ls-is-vam select is-primary content">
									<select v-model="user.firstDayOfWeek" class="is-prime-color" :disabled="!isPrincipal()">
										<option :value="0">{{ ML.trans(this.getMlidFromVal(enumPresenter.daysOfWeek, 0, "notdef")) }}</option>
										<option :value="6">{{ ML.trans(this.getMlidFromVal(enumPresenter.daysOfWeek, 6, "notdef")) }}</option>
									</select>
								</span>
							</div>
						</div>

						<div v-if="isPrincipal()" class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('geo_location') }}</span>
							</div>
							<div class="column">
								<span class="ls-is-vam content">
									<span>{{geoLocation == null ? '(none)' : geoLocation}}</span>
									<a v-if="isPrincipal()" class="spaceleft" @click="setCurrentGeoLocation()">{{ ML.trans('set_crt_location') }}</a>
								</span>
							</div>
						</div>

						<div v-if="isPrincipal()" class="columns is-vcentered">
							<div class="column has-text-right-tablet has-text-centered-mobile">
								<span class="ls-is-vam ls-is-label">{{ ML.trans("on_prog_cancel") }}:</span>
							</div>
							<div class="column has-text-centered-mobile">
								<span class="ls-is-vam select is-primary content">
									<select v-model="programCancelResetsDevices" class="is-prime-color">
										<option :value="true">{{ ML.trans("prog_cancel_reset_devs") }}</option>
										<option :value="false">{{ ML.trans("prog_cancel_leave_devs") }}</option>
									</select>
								</span>
							</div>
						</div>

						<div v-if="!isPrincipal()">
							<div class="columns is-vcentered">
								<div class="column has-text-right-tablet has-text-centered-mobile">
									<span class="ls-is-vam ls-is-label">{{ ML.trans("type") }}:</span>
								</div>
								<div class="column has-text-centered-mobile">
									{{ ML.trans("secondary") }}
								</div>
							</div>
						</div>

						<div class="columns is-mobile is-vcentered">
							<div class="column has-text-right">
								<span class="ls-is-vam ls-is-label">{{ ML.trans('change_password') }}</span>
							</div>
							<div class="column">
								<div class="columns">
									<div class="column">
										<span>{{ ML.trans('current_pass') }}</span>
										<span><input type="password" class="input is-primary" maxlength="40" autocomplete="off" v-model="password.current" /></span>
									</div>
									<div class="column">
										<span>{{ ML.trans('new_pass') }}</span>
										<span><input type="password" class="input is-primary" maxlength="40" autocomplete="off" v-model="password.new" /></span>
									</div>
									<div class="column">
										<br>
										<a class="button is-primary" @click="changePassword()">{{ ML.trans('change') }}</a>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="columns">
					<!-- <div class="column has-text-left-tablet has-text-centered-mobile">
						<a class="button is-primary" @click="saveAccount()"><strong>{{ ML.trans('save') }}</strong></a>
					</div> -->
					<div v-if="isPrincipal()" class="column has-text-right-tablet has-text-centered-mobile mt-6">
						<a class="button is-danger" @click="deleteAccount()"><strong>{{ ML.trans('delete_account') }}</strong></a>
					</div>
				</div>
			</div>
		</div>



		<!-- Weather settings -->
		<div v-if="isPrincipal()" class="container block">
			<div class="box">
				<div class="columns is-centered is-variable mb-4">
					<div class="column has-text-centered ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('weather') }}</span>
					</div>
				</div>

				<div v-if="isPrincipal()" class="columns is-mobile is-vcentered">
					<div class="column has-text-right">
						<span class="ls-is-vam ls-is-label">{{ ML.trans('weather_source') }}</span>
					</div>
					<div class="column">
						<span class="ls-is-vam select is-primary content">
							<select v-model="user.weatherChannel" class="is-prime-color" :disabled="!isPrincipal()">
								<option :value="1">met.no</option>
								<option :value="2">tomorrow.io</option>
							</select>
						</span>
					</div>
				</div>

				<table v-if="user.geoLocation != null" style="margin-left: auto; margin-right: auto;">
					<tr>
						<td></td>
						<td colspan="2" class="ls-is-label" style="text-align: center; border-bottom: 1px solid grey;">Days</td>
					</tr>
					<tr>
						<td></td>
						<td style="text-align: center;">Before</td>
						<td style="text-align: center;">After</td>
					</tr>
					<tr style="border-bottom: 1px solid grey;">
						<td style="vertical-align: middle;"><span class="ls-is-label spaceright">Total precipitations</span></td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherPrecDaysBefore" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherPrecDaysAfter" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
					</tr>
					<tr>
						<td style="vertical-align: middle;"><span class="ls-is-label spaceright">Mean humidity</span></td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherHumDaysBefore" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherHumDaysAfter" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
					</tr>
					<tr style="border-bottom: 1px solid grey;">
						<td style="vertical-align: middle;"><span class="ls-is-label spaceright">Mean temperature</span></td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherTempDaysBefore" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
						<td>
							<span class="ls-is-vam select is-primary content">
								<select v-model="user.weatherTempDaysAfter" class="is-prime-color">
									<option v-for="val in weatherComputeDays" :value="val">{{ val }}</option>
								</select>
							</span>
						</td>
					</tr>
				</table>
				<div v-else="" class="has-text-centered">({{ ML.trans('geo_not_set') }})</div>

			</div>
		</div>


	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { themes } from "@/components/mixins/themes";
import { pushMessagesLib } from "@/components/mixins/pushMessagesLib.js"
import { osInfo } from "@/components/mixins/osInfo";

export default {
	name: 'Settings',
	mixins: [themes, pushMessagesLib, osInfo],
	data: function() {
		return {
			user: {},
			utcOffsets: [],
			password: {
				current: "",
				new: ""
			},
			userJobsExecuting: false,
			weatherComputeDays: [0,1,2,3,4,5,6,7],
			notification: {
				subscription: null,		// string - stringified current subscription, or null if no subscription
				state: {
					permission: "default",
					subscriptionToPushService: null,	// current subscription object, or null if no subscriptuon
					subscribedToServer: false
				},
				userServerSubscription: null,
				notiFilter: {
					progStartFinish: null,	// Trick: even if this value is boolean, in order not to trigger on view load the save action, the watcher does nothing if the initial value is null.
					executionIssues: null,
					appUpdates: null,
					connection: null
				},
				filterSave: false
			},
			userSubscriptions: [],
			childUsers: [],
			childUserEdit: {
				isEditing: false,
				name: "",
				email: "",
				err: {
					name: "",
					email: ""
				}
			}
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		utcOffset: function() {
			return this.user.utcOffset;
		},
		isDST: function() {
			return this.user.isDST;
		},
		geoLocation: function() {
			return this.user.geoLocation;
		},
		firstDayOfWeek: function() {
			return this.user.firstDayOfWeek;
		},
		weatherChannel: function() {
			return this.user.weatherChannel;
		},
		sessionTimeout: {
			get: function() {
				return this.goptions.localSettings.sessionTimeout;
			},
			set: function(newValue) {
				this.goptions.localSettings.sessionTimeout = newValue;
				this.saveLocalSettings();
			}
		},
		dateFormat: {
			get: function() {
				return this.goptions.localSettings.dateFormat;
			},
			set: function(newValue) {
				this.goptions.localSettings.dateFormat = newValue;
				this.saveLocalSettings();
			}
		},
		currentLanguage: {
			get: function() {
				return this.ML.translationOptions.defaultLanguage;
			},
			set: function(newValue) {
				this.ML.translationOptions.defaultLanguage = newValue;
				this.goptions.localSettings.language = newValue;
				this.saveLocalSettings();
			}
		},
		programCancelResetsDevices: {
			get: function() {
				return this.user.resetDevicesOnJobCancel;
			},
			set: function(newValue) {
				this.user.resetDevicesOnJobCancel = newValue;
			}
		},
		notificationsPermission: function() {
			return this.goptions.notificationsState;
		},
		notificationsEnabled: function() {
			return this.goptions.notificationsEnabled;
		}
	},
	watch: {
		utcOffset: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		isDST: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		geoLocation: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		firstDayOfWeek: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		weatherChannel: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		programCancelResetsDevices: function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherTempDaysBefore": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherTempDaysAfter": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherPrecDaysBefore": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherPrecDaysAfter": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherHumDaysBefore": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		"user.weatherHumDaysAfter": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveAccount();
			}
		},
		sessionTimeout: function() {
			console.log("changed");
		},
		"goptions.localSettings.keepMeSigned": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveLocalSettings();
			}
		},
		"notification.notiFilter.progStartFinish": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveNotificationsFilter();
			}
		},
		"notification.notiFilter.executionIssues": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveNotificationsFilter();
			}
		},
		"notification.notiFilter.appUpdates": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveNotificationsFilter();
			}
		},
		"notification.notiFilter.connection": function(newValue, oldValue) {
			if (oldValue != null) {	// It's not the initial value being set
				this.saveNotificationsFilter();
			}
		},
		notificationsPermission: function() {
			this.getNotificationChainState();

			if (this.notification.state.permission == this.notifPermission.granted) {
				// The permission state has been changed to "granted" => continue registration
				console.log(">>> Permission changed to 'granted'");

				this.doSubscribeToNotifications()
				.then(subscription => {
					if (subscription == null) {
						console.log(">>> Subscribed to push service - NOT OK");
					}
					else {
						console.log(">>> Subscribed to push service - OK");
	
						// Send subscription to the server.
						this.sendSubscriptionToServer(subscription)
					}
					
					// [...][De verificat]
					this.getNotificationChainState();
				})

			}

			if (this.notification.state.permission == this.notifPermission.denied) {
				// The permission state has been changed to "denied" => remove registration from server
				alert("Notification changed to Denied");
			}
		}
	},
	created: function() {
		var caption = "";
		for (var i = -12; i <= 14; i++) {
			if (i < 0) {
				caption = "GMT " + i + ":00";
			}
			else {
				caption = "GMT +" + i + ":00";
			}
			this.utcOffsets.push({value: i * 60, label: caption});
		}

		this.user = this.deepClone(this.goptions.currentuser);

		// Check if any user job is currently executing
		this.$ky.get(this.apiUrl + "/AnyUserJobExecuting", {
			headers: { "Authorization": this.authHeaderVal },
			cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
		.json()
		.then((responseIsExecuting) => {
			this.userJobsExecuting = responseIsExecuting;
		})
		.catch((err) => {
			alert("Error: " + err.message);
		});

		// Push notifications
		this.getNotificationChainState();
		this.getServerSubscriptions();
		this.storeNotificationEnabled();
		this.displaySubscription();

		this.getChildUsers();
	},
	methods: {
		deleteChildUser: function(user) {
			if (!confirm(this.ML.trans('confirm_user_delete') +  " \"" + user.userName + "\"?")) {
				return;
			}
			this.$ky.get(this.apiUrl + "/DeleteChildUser", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					userId: user.userId,
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					this.getChildUsers();
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		},
		childUserRightsChanged: function(user) {
			//console.log(user.rights);
			this.$ky.get(this.apiUrl + "/ChangeUserRights", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					userId: user.userId,
					newRights: user.rights
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
				}
				else {
					switch(responseJson.errorCode) {
						case 1:
							alert(this.ML.trans('user_not_found'));	// Shouldn't be the case
							break;
						default:
							alert(this.ML.trans('unknown_error'));
							break;
					}
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		},
		getChildUsers: function() {
			this.$ky.get(this.apiUrl + "/GetChildUsers", {
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseUserList) => {
				this.childUsers = responseUserList;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		addChildUser: function() {
			this.childUserEdit.name = "";
			this.childUserEdit.email = "";
			this.childUserEdit.err.name = "";
			this.childUserEdit.err.email = "";
			this.childUserEdit.isEditing = true;
		},
		saveChildUser: async function() {
			// Validate
			if (!await this.validateUser()) {
				return;
			}
			// Save the new child user
			this.createChildUser(this.childUserEdit.name, this.childUserEdit.email)
			.then((responseJson) => {
				if (responseJson.success) {
					this.getChildUsers();
					this.childUserEdit.isEditing = false;
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		cancelAddChildUser: function() {
			this.childUserEdit.isEditing = false;
		},
		validateUser: async function() {
			this.childUserEdit.err.email = this.childUserEdit.err.name = "";

			this.childUserEdit.name = this.childUserEdit.name.trim();
			this.childUserEdit.email = this.childUserEdit.email.trim();

			var hasErrors = false;
			if (this.childUserEdit.name == "") {
				this.childUserEdit.err.name = this.ML.trans('cannot_be_empty');
				hasErrors = true;
			}
			if (this.childUserEdit.email == "") {
				this.childUserEdit.err.email = this.ML.trans('cannot_be_empty');
				hasErrors = true;
			}
			else if (this.validateEmail(this.childUserEdit.email)) {
				this.childUserEdit.err.email = this.ML.trans('invalid_email');
				hasErrors = true;
			}

			if (!hasErrors) {	// Don't bother to check for uniqueness if there are already errors.
				var result = await this.checkUserMainInfoUnique(this.childUserEdit);
				if (!result.isUnique) {
					hasErrors = true;
					if (result.nameExists) {
						this.childUserEdit.err.name = this.ML.trans('name_already_used');
					}
					if (result.emailExists) {
						this.childUserEdit.err.email = this.ML.trans('email_already_used');
					}
				}
			}

			return !hasErrors;
		},
		validateEmail: function (email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return !re.test(String(email).toLowerCase());
		},
		packNotificationFilter: function() {
			return (this.notification.notiFilter.progStartFinish ? 1 : 0) | (this.notification.notiFilter.executionIssues ? 2 : 0) | (this.notification.notiFilter.appUpdates ? 4 : 0) | (this.notification.notiFilter.connection ? 8 : 0);
		},
		saveNotificationsFilter: function() {
			this.$ky(this.apiUrl + "/UpdateNotificationsFilter", {
				method: "GET",
				searchParams: {
					subscriptionId: this.notification.userServerSubscription.subscriptionId,
					notificationsFilter: this.packNotificationFilter()
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
				})
				.json()
				.then(responseJson => {
					if (!responseJson.success) {
						alert("Error: " + responseJson.errorDescription);
					}
					else {
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		getNotificationChainState: async function() {
			// Reset the states
			this.notification.state = {
					permission: this.notifPermission.default,
					subscriptionToPushService: false,
					subscribedToServer: false
			};

			// Check each state
			this.notification.state.permission = this.getNotificationPermission();
			this.notification.state.subscriptionToPushService = await this.getSubscription();
		},
		askNotificationPermission: function() {
			this.requestNotificationPremissions()
			.then(() => {
				this.$forceUpdate();
			});	// Possible values: denied, granted, default
		},
		async doSubscribeToNotifications() {
			var subscription = await this.subscribeToNotifications();

			this.displaySubscription();

			return subscription;
		},
		displaySubscription() {
			this.getSubscription()
			.then(subs => {
				//console.log("Current subscription X: ", subs);
				this.notification.subscription = (subs == null ? null : JSON.stringify(subs, null, "\t"));
			});
		},
		registerAllChain: function() {
			this.getNotificationChainState();
			if (this.notification.state.permission == this.notifPermission.default) {
				this.askNotificationPermission();
				return;
			}
			if (this.notification.state.permission == this.notifPermission.granted) {
				/*
				We are in the following situation:
				- the notifications are granted
				- there is no regidtration to the push service
				This can happen if
				- (after a previous successful refistration) the user blocks the notifications in the browser
				- the user comes back and enables (state "granted") the notifications in the browser.

				=> We have notifications granted, but no registration to the push service, and possible orphan registration on the server.
				*/
				this.doSubscribeToNotifications()
				.then(subscription => {
					if (subscription == null) {
					}
					else {
						// Send subscription to the server.
						this.sendSubscriptionToServer(subscription);
					}

					// [...][De verificat]
					this.getNotificationChainState();
				})
			}
		},
		unregisterAllChain: async function() {
			// Delete subscription from device
			var result = await this.removeSubscription();
			console.log("deleteSubscription result: ", result);
			this.notification.subscription = null;

			// Delete subscription from server
			// - Identify the server id of the current subscription.
			var serverSubscription = this.userSubscriptions.find(el => el.endpoint == this.notification.state.subscriptionToPushService.endpoint);
			if (serverSubscription == null) {
				console.log("No corresponding sunscription on server side");
				this.getNotificationChainState();
				this.getServerSubscriptions();
				return;
			}

			this.$ky(this.apiUrl + "/DeletePushSubscription", {
				method: "GET",
				searchParams: {
					subscriptionId: serverSubscription.subscriptionId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
				})
				.json()
				.then(responseJson => {
					this.getNotificationChainState();
					this.getServerSubscriptions();
					if (!responseJson.success) {
						alert("Error: " + responseJson.errorDescription);
					}
					else {
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		sendSubscriptionToServer(subscription) {
			console.log("sendSubscriptionToServer", subscription);
			var subs = JSON.parse(JSON.stringify(subscription));

			const formData = new FormData();
			formData.append("endpoint", subs.endpoint);
			formData.append("p256dh", subs.keys.p256dh);
			formData.append("auth", subs.keys.auth);
			formData.append("osInfo", JSON.stringify(this.browserInfo()));
			this.$ky.post(this.apiUrl + "/AddPushSubscription", {
				headers: { "Authorization": this.authHeaderVal },
				body: formData,
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
  			})
			.json()
			.then(responseJson => {
				this.getServerSubscriptions();
				this.showWait(false);
			})
			.catch(error => {
				this.showWait(false);
				alert("Error: " + error.message);
			});
		},
		getServerSubscriptions() {
			this.showWait(true);

			this.$ky(this.apiUrl + "/GetUserPushSubscriptions", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
				})
				.json()
				.then(responseJson => {
					// Deserialize the os-related field, returned from server as JSON formatted string.
					for (let i = 0; i < responseJson.subscriptions.length; i++) {
						responseJson.subscriptions[i].osInfo = JSON.parse(responseJson.subscriptions[i].osInfo);
					}
					
					this.userSubscriptions = responseJson.subscriptions;
					if (this.notification.state.subscriptionToPushService != null) {
						this.notification.userServerSubscription = this.userSubscriptions.find(us => us.endpoint == this.notification.state.subscriptionToPushService.endpoint);
					}
					else {
						this.notification.userServerSubscription = null;
					}
					if (this.notification.userServerSubscription != null) {
						// Expand notifications filter
						this.notification.notiFilter.progStartFinish = ((this.notification.userServerSubscription.notificationsFIlter & 1) != 0);
						this.notification.notiFilter.executionIssues = ((this.notification.userServerSubscription.notificationsFIlter & 2) != 0);
						this.notification.notiFilter.appUpdates = ((this.notification.userServerSubscription.notificationsFIlter & 4) != 0);
						this.notification.notiFilter.connection = ((this.notification.userServerSubscription.notificationsFIlter & 8) != 0);
					}

					this.showWait(false);
				})
				.catch(error => {
					this.showWait(false);
					alert("Error: " + error.message);
				});
		},
		// ===================================================================

		logout: function() {
			this.$router.push({ name: "Auth", query: { 'nav': true } });
		},
		toggleTheme: function(setDark) {
			this.themeIsDark = setDark;
			this.saveLocalSettings();
		},
		setCurrentGeoLocation: function() {
			if ("geolocation" in navigator) {
				var that = this;
				navigator.geolocation.getCurrentPosition(function (location) {
					var newGeoLOcation = location.coords.latitude.toFixed(4) + "," + location.coords.longitude.toFixed(4);
					that.updateGeoLocation(newGeoLOcation);
				},
				function(locationError){alert(locationError.message)});
			}
			else {
				alert(this.ML.trans('geolocation_not_supported'));
			}
		},
		changePassword: function() {
			if (this.password.new == "") {
				alert(this.ML.trans('pass_cannot_empty'));
				return;
			}
			this.$ky.get(this.apiUrl + "/ChangePassword", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					targetUserId: this.goptions.currentuser.userId,
					currentPass: this.password.current,
					newPass: this.password.new
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					var token = this.authStoredToken;
					token.password = this.password.new;
					this.authStoredToken = token;
					alert(this.ML.trans('pass_changed'));
				}
				else {
					switch(responseJson.errorCode) {
						case 1:
							alert(this.ML.trans('user_not_found'));	// Shouldn't be the case
							break;
						case 2:
							alert(this.ML.trans('current_pass_incorrect'));
							break;
						default:
							alert(this.ML.trans('unknown_error'));
							break;
					}
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		},
		saveAccount: function() {
			//this.showWait(true);
			this.$ky.post(this.apiUrl + "/UpdateAccount", {
				json: this.user, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					// Go back to the jobs page
					this.goptions.currentuser = this.user;
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {this.showWait(false)});
		},
		deleteAccount: function() {
			if (!confirm(this.ML.trans("sure_del_account"))) {
				return;
			}

			this.showWait(true);
			this.$ky(this.apiUrl + "/RemoveAccount", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (!responseJson.success) {
					alert(responseJson.errorDescription);
				}
				else {
					this.goptions.currentuser = null;
					this.$router.push({ name: "Auth", query: { 'nav': true } });
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
			});
		},
		updateGeoLocation: function(newGeolocation) {
			this.showWait(true);
			this.$ky(this.apiUrl + "/UpdateGeoLocation", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					geoLocation: newGeolocation,
					updateWeather: true
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.long,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (!responseJson.success) {
					alert(responseJson.errorDescription);
				}
				else {
					this.user.geoLocation = this.goptions.currentuser.geoLocation = newGeolocation;
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
			});
		}
	}
}
</script>

<style>

</style>