<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('weather') }}</div>

		<div class="container block">
			<div class="box">
				<div class="columns">
					<div class="column">
						<div class="columns is-centered is-vcentered is-mobile">
							<div class="column has-text-right">{{ ML.trans('past_days') }}: </div>
							<div class="column has-text-left">
								<span class="select is-primary content">
									<select v-model="daysBefore" class="is-prime-color">
										<option v-for="day in daysCombo" :key="day" :value="day">{{day}}</option>
									</select>
								</span>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="columns is-centered is-vcentered is-mobile">
							<div class="column has-text-right ls-no-wrap">{{ ML.trans('next_days') }}: </div>
							<div class="column has-text-left">
								<span class="select is-primary content">
									<select v-model="daysAfter" class="is-prime-color">
										<option v-for="day in daysCombo" :key="day" :value="day">{{day}}</option>
									</select>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="columns">
					<div class="column">
						<div class="columns is-right is-mobile">
							<div class="column has-text-right ls-no-wrap">{{ ML.trans('tot_precip_amount') }}: </div>
							<div class="column has-text-left">{{weatherInfo.totalPrecipitationAmount}} mm</div>
						</div>
					</div>
					<div class="column">
						<div class="columns is-centered is-mobile">
							<div class="column has-text-right ls-no-wrap">{{ ML.trans('mean_rel_humidity') }}: </div>
							<div class="column has-text-left">{{weatherInfo.meanRelativeHumidity}} %</div>
						</div>
					</div>
					<div class="column">
						<div class="columns is-left is-mobile">
							<div class="column has-text-right ls-no-wrap">{{ ML.trans('mean_air_temp') }}: </div>
							<div class="column has-text-left">{{weatherInfo.meanAirTemperature}} °C</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Graphs -->
		<div class="container block">
			<div class="box">
				<apexchart type="line" height="350" :options="charts.precipitationAmount.chartOptions" :series="charts.precipitationAmount.series"></apexchart>
			</div>
		</div>
		<div class="container block">
			<div class="box">
				<apexchart type="line" height="350" :options="charts.relativeHumidity.chartOptions" :series="charts.relativeHumidity.series"></apexchart>
			</div>
		</div>
		<div class="container block">
			<div class="box">
				<apexchart type="line" height="350" :options="charts.airTemperature.chartOptions" :series="charts.airTemperature.series"></apexchart>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { themes } from "@/components/mixins/themes";
import apexchart from "vue3-apexcharts"

export default {
	name: 'Weather',
	mixins: [themes],
	components: {apexchart},
	data: function() {
		return {
			weatherInfo: {},
			daysBefore: 0,
			daysAfter: 0,
			refDate: null,
			daysCombo: [],
			charts: {
				airTemperature: {},
				precipitationAmount: {},
				relativeHumidity: {},
				graphTemplate: {
					series: [
						{
						name: "<<Series name>>",
						data: []
						// data: [28, 29, 33, 36, 32, 32, 33]
						}
					],
					chartOptions: {
						tooltip: {
							enabled: true,
							theme: "dark"
						},
						chart: {
							height: 350,
							type: 'line',
							dropShadow: {
								enabled: true,
								color: '#000',
								top: 18,
								left: 7,
								blur: 10,
								opacity: 0.2
							},
							toolbar: {
								show: false
							},
							zoom: {
								enabled: false
							}
						},
						colors: ['#77B6EA', '#33ff33'],		// Line colors
						dataLabels: {
							enabled: true,
							style: {
								colors: ["var(--my-body-text-color2)"]
							}
						},
						stroke: {
							curve: 'smooth'
						},
						title: {
							text: '<<The graph title>>',
							align: 'left',
							style: {
								color: "var(--my-prime-color)"
							}
						},
						markers: {
							size: 1
						},
						xaxis: {
							// categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
							categories: [],
							title: {
								text: '<<X axis title>>',
								style: {
									color: "var(--my-prime-color)"
								}
							},
							labels: {
								style: {
									colors: 'var(--my-prime-color)'
								}
							}
						},
						yaxis: {
							labels: {
								style: {
									colors: "var(--my-prime-color)"
								}
							},

							title: {
								text: '<<Y axis title>>',
								style: {
									color: "var(--my-prime-color)"
								}
							},
							// min: 5,
							// max: 40
						},
						legend: {
							labels: {
								colors: ["var(--my-prime-color)"],
								useSeriesColors: false
							},
							position: 'top',
							horizontalAlign: 'right',
							floating: true,
							offsetY: -25,
							offsetX: -5
						}
					}
				}
			}
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	watch: {
		daysBefore: function() {
			this.initializeCumulative();
		},
		daysAfter: function() {
			this.initializeCumulative();
		}
	},
	created: function() {
		for (var i = 0; i < 10; i++) {
			this.daysCombo.push(i);
		}
		this.initializeCumulative();
		// Initialize graphs
		this.initGraphTemplates();
		this.initGraphs();
	},
	methods: {
		initializeCumulative: function() {
			this.refDate = this.getUTCNowDateTime();
			this.$ky.get(this.apiUrl + "/GetAllWeatherInfo", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					utcDateTimeStr: this.dateToIsoStr(this.refDate, "YMD"),
					daysBefore: this.daysBefore,
					daysAfter: this.daysAfter
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((response) => {
				if (response.success) {
					response.value.totalPrecipitationAmount = response.value.totalPrecipitationAmount.toFixed(2)
					response.value.meanRelativeHumidity = response.value.meanRelativeHumidity.toFixed(2)
					response.value.meanAirTemperature = response.value.meanAirTemperature.toFixed(2)
					this.weatherInfo = response.value;
					console.log(this.weatherInfo);
				}
				else {
					alert(response.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		},
		initGraphTemplates: function() {
			// Temperature
			this.charts.airTemperature = this.deepClone(this.charts.graphTemplate);
			// Precipitation amount
			this.charts.precipitationAmount = this.deepClone(this.charts.graphTemplate);
			// Relative humidity
			this.charts.relativeHumidity = this.deepClone(this.charts.graphTemplate);
		},
		initGraphs: function() {
			this.$ky.get(this.apiUrl + "/GetAllWeatherDays", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((days) => {
				console.log("GetAllWeatherDays", days);
				var dates = days.map(day => this.dateToIsoStr(new Date(day.date), "DMY", "D"));
				var temperatureSeries = days.map(day => day.meanAirTemperature.toFixed(2));
				var precipitationSeries = days.map(day => day.totalPrecipitationAmount.toFixed(2));
				var humiditySeries = days.map(day => day.meanRelativeHumidity.toFixed(2));

				// AIr temperature
				this.initGraph(this.charts.airTemperature, dates, temperatureSeries, this.ML.trans('dates'), this.ML.trans('temperature'), this.ML.trans('air_temp'), "#77B6EA");
				this.initGraph(this.charts.precipitationAmount, dates, precipitationSeries, this.ML.trans('dates'), this.ML.trans('precipitations'), this.ML.trans('precipitations'), "#55ffff");
				this.initGraph(this.charts.relativeHumidity, dates, humiditySeries, this.ML.trans('dates'), this.ML.trans('humidity'), this.ML.trans('humidity'), "#55ff55");
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		},
		initGraph: function(chart, xData, yData, xTitle, yTitle, graphTitle, lineColor) {
			var todayDateStr = this.dateToIsoStr(new Date(), "DMY", "D");

			chart.chartOptions = {...chart.chartOptions, ...{
				colors: [lineColor],
				xaxis: {
					categories: xData,
					title: {
						text: xTitle
					}
				},
				yaxis: {
					title: {
						text: yTitle,
						style: {
							color: "var(--my-prime-color)"
						}
					}
				},
				title: {
					text: graphTitle
				},
				// Mark today if today is one of the rendered dates.
				annotations: {
					xaxis: (xData.includes(todayDateStr) ? [
						{
							x: todayDateStr,
							borderColor: '#00E396',
							label: {
								borderColor: '#00E396',
								orientation: 'horizontal',
								text: this.ML.trans('today')
							}
						}
					] : [])
				}
			}};
			chart.series[0] = {
				name: yTitle,
				data: yData
			}
		}
	}
}
</script>

<style>

</style>