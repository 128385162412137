<template>
	<div>
		<div class="title is-size-6-mobile is-size-3-tablet brand-color">Users</div>

		<div class="table-container">
			<table class="table is-hoverable container">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>SuperAdmin</th>
						<th>Principal</th>
						<th>UTC Offset</th>
						<th>DST</th>
						<th>Weather updated (met)</th>
						<th>Weather updated (tomorrow)</th>
						<th>Weather update</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="user in users">
						<td>{{ user.userName }}</td>
						<td>{{ user.email }}</td>
						<td>{{ user.adminRights & enumPresenter.adminRights.superAdmin > 0 ? "✔" : "✖" }}</td>
						<td>{{ user.parentUserId == 0 ? "✔" : "" }}</td>
						<td>{{ user.utcOffset }}</td>
						<td>{{ user.isDST ? "✔" : "✖" }}</td>
						<td>
							<span v-if="user.isPrincipal">{{ user.weatherUpdated.met }}</span>
						</td>
						<td>
							<span v-if="user.isPrincipal">{{ user.weatherUpdated.tomorrow }}</span>
						</td>
						<td>
							<span v-if="user.isPrincipal" class="ls-feels-link" @click="updateWeather(user.userId)">Update weather info</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div style="text-align: center;">
			<a class="button is-primary" @click="updateWeatherForAllUsers()" >Update weather for all users</a>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";

export default {
	name: "Users",
	data: function() {
		return {
			users: []
		};
	},
	created: function() {
		this.getAllUsers();
	},
	methods: {
		updateWeatherForAllUsers: function() {
			var dateStart = new Date();
			this.showWait(true);
			this.$ky(this.apiUrl + "/UpdateWeatherForAllUsers", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.huge,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				var dateDiff = (((new Date()) - dateStart) * 1.0 / 1000).toFixed(2);
				this.showWait(false);
				var successUpdates = responseJson.successUpdates;
				var failedUpdates = responseJson.failedUpdates;
				this.getAllUsers();
				alert("Done in " + dateDiff + " seconds \n\nSucceeded: " + successUpdates + ", Failed: " + failedUpdates);
			})
			.catch((err) => {
				this.showWait(false);
				alert("Error: " + err.message);
			});
		},
		updateWeather: function(userId) {
			this.showWait(true);
			this.$ky(this.apiUrl + "/UpdateWeatherForUser", {
				method: "GET",
				searchParams: {
					userId: userId,
					weatherChannelId: 0		// Update all the channels
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				this.showWait(false);
				if (responseJson.success) {
					// Refresh the weather updated info
					this.getAllUsers();
					alert("Done");
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				this.showWait(false);
				alert("Error: " + err.message);
			});
		},
		getAllUsers: function() {
			this.$ky(this.apiUrl + "/GetAllUsers", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				//this.users = responseJson;
				var users = responseJson;

				this.getWeatherUpdatedInfo()
				.then((weatherUpdateInfo) => {
					var met, tomorrow;
					users.forEach(user => {
						user = reactive(user);
						var userWeatherUpdateInfo = {};
						met = weatherUpdateInfo.find(wui => wui.userId == user.userId && wui.weatherChannel == 1);
						userWeatherUpdateInfo.met = (met != null ? met.updatedUtc : null);
						tomorrow = weatherUpdateInfo.find(wui => wui.userId == user.userId && wui.weatherChannel == 2);
						userWeatherUpdateInfo.tomorrow = (tomorrow != null ? tomorrow.updatedUtc : null);

						user.weatherUpdated = reactive(userWeatherUpdateInfo);
					});
					
					this.users = users;
				})
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		getWeatherUpdatedInfo: function() {
			return this.$ky(this.apiUrl + "/GetWeatherUpdatedInfo", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
	}
}
</script>

<style>
</style>