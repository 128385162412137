<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('map') }}</div>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'Map',
	mixins: [],
	data: function() {
		return {
			flatInfo: {}
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	created: async function() {
		this.flatInfo = await this.getFlatInfo();
		console.log("flatInfo", this.flatInfo);

		console.log("Controller children", this.getChildren(this.flatInfo, 3, "controller"));
		console.log("Device children", this.getChildren(this.flatInfo, 20, "device"));
		console.log("Task children", this.getChildren(this.flatInfo, 8, "task"));
	},
	watch: {
	},
	methods: {
		getUsage: function(flatInfo, objId, objType) {
			switch(objType) {
				case "controller":
					break;
				case "device":
					break;
				case "task":
					break;
				case "job":
					break;
			}
		},
		getEntityNameField: function(objType) {
			var retVal = "";
			switch(objType) {
				case "controller":
					retVal = "name";
					break;
				case "device":
					retVal = "deviceName";
					break;
				case "task":
					retVal = "taskName";
					break;
				case "job":
					retVal = "name";
					break;
			}
		}
	}
}
</script>

<style>
</style>