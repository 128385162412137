<template>
<div>
	<div v-if="pairingState != 3">
		<div class="columns is-centered">
			<div class="column has-text-centered">
				<span v-if="controllerPairingMethod == 2">
					<br>(1) {{ ML.trans('enter_controller_id') }}<br>
					<input type="text" v-model="controllerCheckId">
					<br>
				</span>
				({{ controllerPairingMethod == 1 ? 1 : 2 }}) {{ ML.trans('set_pair_mode') }}
				<br><span class="is-size-7">{{ ML.trans('pair_blink_blue') }}</span>
				<div class="blink-bg-circle spaceleft ls-is-vam"></div>
				<br>({{ controllerPairingMethod == 1 ? 2 : 3 }}) {{ ML.trans('in_pair_click') }}:
			</div>
		</div>
		<div class="columns is-centered">
			<div class="column has-text-centered">
				<a v-if="controllerPairingMethod == 1" class="button is-primary" @click="pairController()" >{{ ML.trans('pair_controller') }}</a>
				<a v-if="controllerPairingMethod == 2" class="button is-primary" @click="pairControllerWithCheckId()" >{{ ML.trans('pair_controller') }}</a>
			</div>
		</div>
		<div v-if="err.pair != ''" class="columns is-centered">
			<div class="column has-text-centered">
				<span class="spaceleft has-text-danger">{{ err.pair }}</span>
			</div>
		</div>
	</div>
	<div class="has-text-centered mt-3">
		<span>{{ pairedControllerText }}</span>
		<span v-if="pairingState == 3" class="spaceleft">&#x2714;</span>
	</div>

	<!-- <hr> -->

	<!-- <div v-if="pairingState == 3">
		<div class="columns is-centered is-5 is-mobile">
			<div class="column is-half has-text-right">
				{{ ML.trans('controller_name') }}
			</div>
			<div class="column is-half has-text-left">
				<input type="text" size=30 v-model="controllerName" :placeholder="ML.trans('enter_ctrl_name')">
			</div>
		</div>
		<hr>
	</div>

	<div class="columns is-centered is-8 is-mobile">
		<div class="column is-half has-text-centered">
			<a v-if="validControllerPairing != null" class="button is-primary spaceright" @click="doSave()">{{ ML.trans('finish') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
		</div>
	</div> -->

</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "ControllerPairing",
	data: function() {
		return {
			validControllerPairing: null,
			pairingState: 0,
			err: {
				pair: ""
			},
			controllerId: 0,
			controllerCheckId: ""
		};
	},
	created: function() {
		// State codes: 1 = no pairing attempt yet, 2 = pairing, 3 = pairing successful, 4 = pairing unsuccessful
		this.pairingState = 1;
		this.validControllerPairing = null;
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		pairedControllerText: function() {
			switch(this.pairingState) {
				case 1:
					return "(" + this.ML.trans('pairstate_none') + ")";
				case 2:
					return this.ML.trans('pairstate_working') + "...";
				case 3:
					return "✅" + this.ML.trans('pairstate_ok') + " (" + this.validControllerPairing.pairingToken + ")";
				case 4:
					return "❌" + this.ML.trans('pairstate_notfound');
				default:
					break;
			}
		}
	},
	methods: {
		signalPairingAttempted: function() {
			this.$emit("pairingAttempted", this.validControllerPairing);
		},
		clearErrors: function() {
			this.err.pair = "";
		},
		getPairings: function() {
			return this.$ky(this.apiUrl + "/GetPairings", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getPairing: function() {
			return this.$ky(this.apiUrl + "/GetPairing", {
				method: "GET",
				searchParams: {
					checkId: this.controllerCheckId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		validatePairing: async function(pairingData) {
			var controllerApiMethodUrl = "http://" + pairingData.controllerLocalIp + ":" + pairingData.controllerLocalPort + "/pair";
			return this.$ky(controllerApiMethodUrl, {
				method: "GET",
				searchParams: {
					token: pairingData.pairingToken,
					stampid: pairingData.controllerStampId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.small,
				retry: 1
			})
			.json();
		},
		pairController: async function() {
			if (this.pairingState == 2) {	// Do nothing if already pairing
				return;
			}
			this.showWait(true);
			this.clearErrors();
			this.pairingState = 2;
			this.validControllerPairing = null;
			this.getPairings()
				.then(async (response) => {
					var pairings = response.pairings;

					// Simulate an inexistent / inaccessible URL:
					/*
					var p = {...pairings[0]};
					p.controllerLocalIp = "192.168.0.119";
					pairings.splice(0, 0, p);
					*/

					// Simulate that no pairing corresponds to the pairing controller
					/*
					pairings.forEach(pairing => {
						pairing.controllerLocalIp = "192.168.2.14";
					});
					*/

					// Try to validate all the pairings. Terminate on first success (if any)
					for (var i = 0; i < pairings.length; i++) {
						try{
							var result = await this.validatePairing(pairings[i]);
							if (result.success) {
								this.validControllerPairing = pairings[i];
								break;
							}
						}
						catch(err) {
							//console.log("Failed request to ", pairings[i]);
						}
					}
					this.showWait(false);
					if (this.validControllerPairing != null) {
						this.pairingState = 3;
					}
					else {
						this.pairingState = 4;
					}
					this.signalPairingAttempted();
				});
		},
		pairControllerWithCheckId: function() {
			if (this.pairingState == 2) {	// Do nothing if already pairing
				return;
			}
			if (this.controllerCheckId.length < 8) {
				alert(this.ML.trans('controller_id_too_short'));
				return;
			}
			this.showWait(true);
			this.clearErrors();
			this.pairingState = 2;
			this.validControllerPairing = null;
			this.getPairing()
				.then(pairing => {
					this.validControllerPairing = pairing;
					this.showWait(false);
					if (this.validControllerPairing != null) {
						this.pairingState = 3;
					}
					else {
						this.pairingState = 4;
					}
					this.signalPairingAttempted();
				});
		}
	}
}
</script>

<style>
</style>