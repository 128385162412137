import { mapState, mapMutations, Store } from 'vuex'
import { datesLib } from "@/components/mixins/datesLib";

export const auth = {
	mixins: [datesLib],
	data() {
		return {
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		authStoredToken: {
			cache: false,
			get: function() {
				var storedtoken = localStorage.getItem("token");
				return (storedtoken != null ? JSON.parse(storedtoken) : null) ;
			},
			set: function(newValue) {
				if (newValue == null) {
					localStorage.removeItem("token");
					this.authJwt = null;
					return;
				}
				localStorage.token = JSON.stringify(newValue);
				this.authJwt = newValue.jwt;
			}
		},
		authVoidToken: function() {
			return {
				lastDateTimeStr: this.dateToIsoStr(new Date(), "YMD"),
				jwt: null
			};
		},
		authJwt: {
			cache: false,
			get: function() {
				return this.goptions.cachedJwt;
			},
			set: function(newValue) {
				this.goptions.cachedJwt = newValue;
			}
		},
		authHeaderVal: {
			get: function() {
				return "Bearer " + this.goptions.cachedJwt;
			}
		},
		hasWeather: {
			get: function() {
				return this.authAuthenticated() && this.goptions.currentuser.geoLocation != null;
			}
		}
	},
	methods: {
		authAuthenticated: function() {
			return this.goptions.currentuser != null;
		},
		isCurrentUserSysAdmin: function() {
			return this.goptions.currentuser != null && this.goptions.currentuser.adminRights & 1 > 0;
		},
		saveLocalSettings: function() {
			localStorage.settings = JSON.stringify(this.goptions.localSettings);
		},
		authSetToken: function(jwt, lastDateTime) {
			lastDateTime = (lastDateTime == null ? new Date() : lastDateTime);
			var token = this.authStoredToken;
			if (token == null) {
				token = this.authVoidToken;
			}
			token.jwt = jwt;
			token.lastDateTimeStr = this.dateToIsoStr(lastDateTime);
			this.authStoredToken = token;
		},
		authLogout: function() {
			this.goptions.currentuser = null;
			this.authStoredToken = null;
		},
		authenticateUser: async function(userNameOrEmail, password) {
			const formData = new FormData();
			formData.append("userNameOrEmail", userNameOrEmail);
			formData.append("password", password);
			try {
				const response = await this.$ky.post(this.apiUrl + "/Login", {
					body: formData,
					cache: "no-cache",
					timeout: this.$gconst.timeouts.medium,
					retry: 1
				});
				let retVal = await response.json();
				return retVal;
			}
			catch(ex) {
				return {success: false, errCode: 401, errDescription: "Error accessing server"};
			}
		},
		checkUserMainInfoUnique: async function(user) {
			return this.$ky(this.apiUrl + "/CheckUserMainInfo", {
				method: "GET",
				searchParams: {
					userName: user.name,
					userEmail: user.email
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		createChildUser: function(userName, userEmail) {
			return this.$ky.get(this.apiUrl + "/CreateChildUser", {
				headers: {
					"Authorization": this.authHeaderVal
				},
				searchParams: {
					userName: userName,
					userEmail: userEmail
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
  			})
			.json();
		},

		// ============== Decode JWT ================
		base64Decode: function(str) {
			return decodeURIComponent(atob(str).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
		},
		  
		// Function to decode a JWT
		decodeJWT: function(token) {
			const parts = token.split('.');
			if (parts.length !== 3) {
				throw new Error('Invalid JWT');
			}

			const payload = parts[1];
			return JSON.parse(this.base64Decode(payload));
		}

	}
}
