import ModalPopupFade from '@/components/ModalPopupFade.vue'

export default function registerGlobalComponents(app) {
	app.component('ModalPopupFade', ModalPopupFade);
}


/*
import Vue from 'vue'
import ModalPopupFade from '@/components/ModalPopupFade.vue'

Vue.component('ModalPopupFade', ModalPopupFade);
*/