<template>
	<div>

		<div class="columns is-mobile">
			<div class="column has-text-weight-bold is-size-4-mobile is-size-3-tablet brand-color">{{ ML.trans('programs') }}</div>
			<div class="column has-text-right">
				<div v-if="hasMinRights(3)" class="button is-primary" @click="editJob(0)" :title="ML.trans('add_program')">
					<img
						class="ls-is-vam"
						alt="Add"
						src="../assets/images/add.svg"
						style="width: 20px"
					/>
					<!-- <span class="spaceleft">{{ ML.trans('add_program') }}</span> -->
				</div>
			</div>
		</div>

		<div class="container block">
			<div class="box">
				<div class="table-container">
					<table class="table is-hoverable container">
						<thead>
							<tr>
								<th v-if="hasMinRights(2)" style="width: 1px;"></th>
								<th>{{ ML.trans('name') }}</th>
								<th>{{ ML.trans('active') }}</th>
								<th>{{ ML.trans('pg_time') }}</th>
								<th>{{ ML.trans('recurrence') }}</th>
								<th>{{ ML.trans('value') }}</th>
								<th>{{ ML.trans('unit') }}</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="job in models.jobs" v-bind:key="job.jobId">
								<td v-if="hasMinRights(2)" style="border-bottom: 0px;">
									<a class="is-unselectable" @click="toggleControlPanelVisibility(job)">[...]</a>
									<transition name="fade">
										<div v-show="job.controlPanelVisible" style="width: 26px;">
											<img src="@/assets/images/edit.svg" class="ls-v-action-icon" alt="Edit" :title="ML.trans('edit')" @click.prevent="editJob(job.jobId)" />
											<img v-if="hasMinRights(3)" src="@/assets/images/clone.svg" class="ls-v-action-icon" alt="Clone" :title="ML.trans('clone')" @click.prevent="cloneJob(job.jobId)" />
										</div>
									</transition>
								</td>
								<td class="ls-is-label">
									{{ job.name }}
								</td>
								<td style="text-align:center;">
									<img :src="getBoolIcon(job.isActive)" style="width: 20px; vertical-align: middle;" />
								</td>
								<td>{{ displayTime(job.dueDateTimeStr) }}</td>
								<td>{{ ML.trans(getMlidFromVal(enumPresenter.recurrenceTypes, job.recurrenceType, "notdef")) }}</td>
								<td>
									<span v-if="[9].includes(job.recurrenceType)">{{ job.recurrenceValue }}</span>
									<WeekDays v-if="[10].includes(job.recurrenceType)" v-model="job.recurrenceValue" :template="ML.trans('weekdays_template')" :firstDay="goptions.currentuser.firstDayOfWeek" :editable="false" :gap="4" />
									<MonthDays v-if="[11].includes(job.recurrenceType)" v-model="job.recurrenceValue" :editable="false" :gap="4" />
								</td>
								<td>{{ job.recurrenceType == 9 ? ML.trans(getMlidFromVal(enumPresenter.recurrenceUnit, job.recurrenceUnit, "notdef")) : "" }}</td>
							</tr>
						</tbody>
					</table>

					<div v-if="pageState == 'loading'" class="has-text-centered">
						{{ ML.trans('loading') }}...
					</div>
					<div v-if="pageState == 'loaded' && models.jobs.length == 0" class="has-text-centered">
						({{ ML.trans('no_program_defined') }})
					</div>

				</div>
			</div>
		</div>


	</div>
</template>

<script>
import { reactive, ref } from "vue";
import { mapState, mapMutations, Store } from 'vuex'
import { datesLib } from "@/components/mixins/datesLib";
import WeekDays from "@/components/controls/WeekDays.vue"
import MonthDays from "@/components/controls/MonthDays.vue"

export default {
	name: 'Programs',
	mixins: [datesLib],
	data: function() {
		return {
			models: {
				jobs: []
			},
			pageState: "loading"
		}
	},
	components: {WeekDays, MonthDays},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	created: function() {
		this.initialize();
	},
	methods: {
		initialize: function() {
			this.pageState = "loading";
			this.getUserJobs()
			.then(responseJobs => {
				responseJobs.forEach(job => {
					job = reactive(job);
					job.controlPanelVisible = ref(false);
				});
				this.models.jobs = responseJobs.sort((job1, job2) => job1.name < job2.name ? -1 : 1);
				console.log("Jobs:", this.models.jobs);
			})
			.finally(() => {
				this.pageState = "loaded";
			});
		},
		cloneJob: function(jobId) {
			// Find the job to clone.
			var foundJob = this.models.jobs.find(job => job.jobId == jobId);
			var newJob = this.deepClone(foundJob);
			// Rename the job and save it.
			newJob.jobId = 0;
			newJob.name = newJob.name + " (clone)";
			newJob.isActive = false;
			this.$ky.post(this.apiUrl + "/SaveJob", {
				json: newJob, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					this.initialize();
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		getBoolIcon: function(value) {
			return require("@/assets/images/" + (value ? "checked.svg" : "unchecked.svg"));
		},
		displayTime: function(dateTimeStr) {
			var timeStr = this.localTimeStrFromUtcDateStr(dateTimeStr, this.goptions.currentuser.utcOffset, this.goptions.currentuser.isDST ? 60 : 0);
			var atoms = timeStr.split(":");
			return atoms[0] + ":" + atoms[1];
		},
		toggleControlPanelVisibility: function(job) {
			job.controlPanelVisible = !job.controlPanelVisible;
		},
		editJob: function(jobId) {
			this.$router.push({ name: "ProgramPageEdit", query: { 'nav': true }, query: {jobId: jobId} });
		},
		executeJob: function(job) {
			alert(this.ML.trans('not_implemented'));
		}
	}
}
</script>

<style>
</style>