<template>
	<div>
		<div class="columns is-mobile is-4 is-variable is-vcentered">
			<div class="column has-text-right ls-is-label">
				{{ ML.trans('parameter') }}
			</div>
			<div class="column has-text-left">
				<div class="select is-primary content">
					<select v-model="weatherParamId" class="is-prime-color">
						<option v-for="param in enumPresenter.weatherParams" v-bind:key="param.value" v-bind:value="param.value">{{ ML.trans(param.ml) }}</option>
					</select>
				</div>
			</div>
		</div>

		<div>
			<div class="columns is-mobile is-vcentered">
				<div class="column has-text-right ls-is-label">
					{{ ML.trans('value_is') }}
				</div>
				<div class="column has-text-left is-narrow">
					<div class="select is-primary content">
						<select v-model="action.operator" class="is-prime-color">
							<option v-for="op in enumPresenter.getOperators" v-bind:key="op.value" v-bind:value="op.value">{{ op.label }}</option>
						</select>
					</div>
				</div>
				<div class="column has-text-left">
					<input type="number" min="0" max="255" class="number is-prime-color is-size-6 ls-is-vam" v-model="action.value">
				</div>
			</div>
		</div>

		<hr>

		<div class="buttons is-grouped is-centered">
			<a class="button is-primary" @click="doSave()">{{ ML.trans('ok') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "ChainLinkWeatherEditor",
	props: {
		seedAction: {	// The default action already generated from outside
			type: Object,
			default: {}
		}
	},
	data: function() {
		return {
			mode: "",	// "N" (new) or "E" (edit) - will be set according to the device value (null / not null)
			action: {},
			weatherParamId: 1,
		};
	},
	created: function() {
		window.addEventListener("keydown", this.onKeydown);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	mounted: function() {
		this.action = this.deepClone(this.seedAction);
		this.mode = (this.action.deviceId == 0 ? "N" : "E");
		this.weatherParamId = this.action.deviceId;
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	watch: {
	},
	methods: {
		onKeydown: function(event) {
			// Manage the Enter key down
			if (event.keyCode == 13) {
				// Trigger the default action
				this.doSave();
			}
		},
		doSave: function() {
			if (this.weatherParamId < 1) {
				alert(this.ML.trans('must_select_param'));
				return;
			}
			this.action.deviceId = this.weatherParamId;
			if (this.mode == "N") {
				this.$emit("askSave", this.action);
			}
			else {
				this.$emit("askSaveEdit", this.action);
			}
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		}
	}
}
</script>

<style>
</style>