<template>
	<span style="user-select: none; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;">
		<span v-if="!editable">
			<span v-for="(dayItem, index) in orderedDaysMap"
				v-text="dayItem.dayName"
				:style="{
					'margin-left': (index != 0 ? gap : 0) + 'px',
					color: dayItem.isSet ? colors.highlight : colors.normal,
					'font-weight': dayItem.isSet ? 'bold' : 'normal'}"
			/>
		</span>
		<span v-if="editable">
			<span v-for="(dayItem, index) in orderedDaysMap"
				v-text="dayItem.dayName"
				style="cursor: pointer;"
				:style="{
					'margin-left': (index != 0 ? gap : 0) + 'px',
					color: dayItem.isSet ? colors.highlight : colors.normal,
					'font-weight': dayItem.isSet ? 'bold' : 'normal'}"
				@click="toggleDay(index)"
			/>
		</span>
	</span>
	
	<!-- <span v-html="weekDaysHtml"></span> -->
</template>

<script>
export default {
	name: "WeekDays",
	props: {
		modelValue: {
			// The value is considered a bitmap, with the lowest bits being the most significant.
			// In order to be used, the value
			// (1) converted to a binary string (0s and 1s), then
			// (2) padded left with character "0" till 31 characters
			// (3) split in to an array of characters, then
			// (4) reverted.
			// The first 7 array elements resulting (starting from index 0) are the checked/unchecked values
			// for the week days.
			// The first bit array element always represents Monday.
			type: Number,
			default: 0			// No day checked by default
		},
		editable: {
			// If false, the control only displays the selected days. If true, the control displays a check box for each day of the week, besides the day initial.
			type: Boolean,
			default: false
		},
		bitmapLength: {
			type: Number,
			default: 31
		},
		template: {
			// The week days initials
			// Enables multi-language.
			// The first character ALWAYS represents Monday.
			type: String,
			default: "MTWTFSS"
		},
		firstDay: {
			// first day rendered
			type: Number,		// 0...6, where 0 is Monday, 1 is Friday, etc.
			default: 0			// Monday
		},
		gap: {
			type: Number,
			default: 4
		}
	},
	emits: ["update:modelValue"],
	data: function() {
		return {
			dayNames: [],
			daysMap: [],
			orderedDaysMap: [],
			colors: {
				highlight: "#ff5555",
				normal: ""
			}
		}
	},
	created: function() {
		this.dayNames = this.template.split("");
		this.daysMap = this.valueToDayMap(this.modelValue);
		this.orderedDaysMap = this.getOrderedDaysMap();
	},
	watch: {
		modelValue: function() {
			this.daysMap = this.valueToDayMap(this.modelValue);
			this.orderedDaysMap = this.getOrderedDaysMap();
		},
		template: function() {
			this.dayNames = this.template.split("");
			this.orderedDaysMap = this.getOrderedDaysMap();
		},
		firstDay: function() {
			this.orderedDaysMap = this.getOrderedDaysMap();
		}
	},
	computed: {
		weekDaysHtml: function() {
			//console.log("this.daysMap", this.daysMap);

			var daysFormatted = Array(7).fill("");
			for (var i=0; i<7; i++) {
				var dayOfWeek = this.dayNames[i];
				var isSet = (this.daysMap[i] === 1);
				daysFormatted[i] = (isSet ? "<span style='color: #ff5555; font-weight: bold;'>" : "") + dayOfWeek + (isSet ? "</span>" : "");
			}
			// Reorder according to the first day of week
			if (this.firstDay > 0) {
				daysFormatted = daysFormatted.slice(this.firstDay).concat(daysFormatted.slice(0, this.firstDay));
			}

			return daysFormatted.join("");
		},
	},
	methods: {
		toggleDay: function(index) {
			this.orderedDaysMap[index].isSet = !this.orderedDaysMap[index].isSet;
			this.doOnChange();
		},
		getOrderedDaysMap: function() {
			var retArr = this.dayNames.map(name => ({ isSet: false, dayName: name }));
			for (var i = 0; i < 7; i++) {
				retArr[i].isSet = (this.daysMap[i] === 1);
			}
			return retArr.slice(this.firstDay).concat(retArr.slice(0, this.firstDay));
		},
		valueToDayMap: function(value) {
			var str = value.toString(2).padStart(this.bitmapLength, "0");
			return [...str].reverse().map(x => parseInt(x));
		},
		dayMapToValue: function(daysMap) {
			var str = [...daysMap].reverse().join("");
			return parseInt(str, 2);
		},
		updateDaysMap: function() {
			// Normalize to days sequence starting with Monday
			var dArr = [...this.orderedDaysMap];
			if (this.firstDay > 0) {
				dArr = this.orderedDaysMap.slice(7 - this.firstDay).concat(this.orderedDaysMap.slice(0, 7 - this.firstDay));
			}
			for (let i=0; i < 7; i++) {
				this.daysMap[i] = (dArr[i].isSet ? 1 : 0);
			}
		},

		doOnChange: function() {
			this.updateDaysMap();
			this.$emit("update:modelValue", this.dayMapToValue(this.daysMap));
		}
	}
}
</script>

<style>
</style>