<template>
	<div>
		<div class="title is-size-6-mobile is-size-3-tablet brand-color">Server</div>

		<div class="container block">
			<div class="box">
				<div class="columns is-centered is-variable">
					<div class="column has-text-centered is-prime-color ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">Job manager</span>
					</div>
				</div>

				<div class="columns is-vcentered mt-4">
					<div class="column has-text-centered">
						<span class="ls-is-vam ls-is-label mr-4">State:</span>
						<span class="ls-is-vam is-primary content">
							<span id="jobManagerState" v-bind:style="{ 'color': enumPresenter.getName(enumPresenter.jobManagerStateColors, jobManagerState) }">{{ enumPresenter.getName(enumPresenter.jobManagerState, jobManagerState) }}</span>
						</span>
					</div>
				</div>

				<div class="columns is-vcentered mt-4">
					<div class="column has-text-centered">
						<span class="ls-is-vam ls-is-label mr-4">Tick period (seconds):</span>
						<span class="ls-is-vam is-primary content">
							<input type="text" size="5" v-model="jobManagerSettings.jobManagerTickPeriod" v-bind:disabled="jobManagerState != 1" />
						</span>
					</div>

					<div class="column has-text-centered">
						<span class="ls-is-vam ls-is-label mr-4">Start on next exact minute:</span>
						<span class="ls-is-vam is-primary content">
							<input type="checkbox" v-model="jobManagerSettings.jobManagerTickOnExactMinute" v-bind:disabled="jobManagerState != 1" />
						</span>
					</div>
				</div>

				<div class="columns">
					<div class="column has-text-centered">
						<a class="button is-primary" v-if="jobManagerState == 1" @click="setJobManagerState(2)">Start</a>
						<a class="button is-primary" v-else @click="setJobManagerState(1)">Stop</a>
					</div>
				</div>
			</div>
		</div>

		<div class="container block">
			<div class="box">
				<div class="columns is-centered is-variable">
					<div class="column has-text-centered is-prime-color ls-happy-title">
						<span class="is-size-5-tablet is-size-6-mobile">Application</span>
					</div>
				</div>

				<div class="columns is-vcentered mt-4">
					<div class="column has-text-centered">
						<span class="ls-is-vam ls-is-label mr-4">Running on:</span>
						<span class="ls-is-vam is-primary content">
							{{ isWindowsPlatform ? "Windows" : "Linux" }}
						</span>
					</div>
				</div>

				<div class="buttons is-centered">
						<a v-if="!isWindowsPlatform" class="button is-primary" @click="stopService()">Stop Linux service</a>
						<a v-if="!isWindowsPlatform" class="button is-primary spaceleft" @click="getProcessorTemperature()">Get temperature</a>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
//import { uiSettings } from "@/components/mixins/globalSettings";
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: "Server",
	mixins: [],
	components: {},
	data: function() {
		return {
			jobManagerSettings: {},
			jobManagerState: 0,
			isWindowsPlatform: false
		};
	},
	created: function() {
		this.initialize();
	},
	methods: {
		initialize: function() {
			this.getJobManagerInfo()
				.then((responseJson) => {
					this.jobManagerSettings = responseJson.settings;
					this.jobManagerState = responseJson.state;
				})
				.catch((err) => {
					alert("Error: " + err.message);
				});
			this.getPlatform();
		},
		getPlatform: function() {
			this.$ky.get(this.apiUrl + "/IsWindowsPlatform", {
				searchParams: {}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				this.isWindowsPlatform = responseJson.isWindowsPlatform;
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		stopService: function() {
			if (!confirm("Are you sure you want to stop the service?\nYou will able to star it only manually.")){
				return;
			}
			this.$ky.get(this.apiUrl + "/StopLinuxService", {
				searchParams: {}})
			.json()
			.then((responseJson) => {
				alert("The service has been stopped.\n\nDon't try any other action until you restart the service!");
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		getProcessorTemperature: function() {
			this.$ky.get(this.apiUrl + "/GetLinuxProcessorTemperature", {
				searchParams: {}})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					alert("Temperature: " + responseJson.value);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		setJobManagerExecutionOptions: function() {
			alert("xxx");
			console.log("Start - setJobManagerExecutionOptions");
			axios({
				method: 'post',
				url: this.apiUrl + "/SetApplicationOptions",
				params: {
					optionsStr: JSON.stringify({
						JobManagerTickPeriod: this.jobManagerInfo.executionPeriod,
						JobManagerTickOnExactMinute: this.jobManagerInfo.jobManagerTickOnExactMinute
					})
				},
				responseType: "json",
				timeout: this.$gconst.timeouts.medium
			})
			.then(response => {
				console.log("End - setJobManagerExecutionOptions");
				if (!response.data.success) {
					alert(response.data.errorText);
				}
			})
			.catch(error => {
				alert("Error: " + error.message);
			});
		},
		setJobManagerState: function(newState) {
			// [...] If starting job manager => Validate the job manager options that will be sent

			// Pack the job manager settings
			var settingsStr = JSON.stringify(this.jobManagerSettings);
			this.$ky.get(this.apiUrl + "/JobManagerSetState", {
				searchParams: {
					newState: newState,
					newJobManagerSettingsStr: settingsStr
				}})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					this.jobManagerState = responseJson.value
				}
				else {
					alert("Error: " + responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});

			return

			if (newState == 2) {
				// Set JobManager to Idle (running) => Updatee first the server options according to the options on the screen.
				// The options will be set futher to the job manager.
				this.setJobManagerExecutionOptions();
			}

			console.log("Start - setJobManagerState");
			// Set the new state.
			axios({
				method: 'post',
				url: this.apiUrl + "/JobManagerSetState",
				params: {
					newState: newState
				},
				responseType: "json",
				timeout: this.$gconst.timeouts.medium
			})
			.then(response => {
				this.jobManagerInfo.state = response.data.currentState;
				console.log("End - setJobManagerState");
				if (response.data.success) {
				}
				else {
					alert(response.data.errorText);
				}
			})
			.catch(error => {
				alert("Error: " + error.message);
			});
		}
	}
};
</script>

<style>

</style>
