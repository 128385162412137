<template>
	<span style="user-select: none; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;">
		<span v-if="!editable" v-text="viewText">
		</span>
		<span v-if="editable">
			<span v-for="(dayItem, index) in daysMap"
				v-text="dayItem.dayName"
				style="cursor: pointer; display: inline-block;"
				:style="{
					'margin-right': (index != daysMap.length - 1 ? gap : 0) + 'px',
					'margin-top': gap + 'px',
					color: dayItem.isSet ? colors.highlight : colors.normal,
					'font-weight': dayItem.isSet ? 'bold' : 'normal'}"
				@click="toggleDay(index)"
			/>
		</span>	
	</span>
</template>

<script>
export default {
	name: "MonthDays",
	// model: {
	// 	// This is a custom model declared for this component. By default (Vue built in), the prop is "value" and the emitted event is "input"
	// 	prop: "bitmapVal",
	// 	event: "change"
	// },
	props: {
		modelValue: {
			// The value is considered a bitmap, with the lowest bits being the most significant.
			// In order to be used, the value
			// (1) converted to a binary string (0s and 1s), then
			// (2) padded left with character "0" till 31 characters
			// (3) split in to an array of characters, then
			// (4) reverted.
			// The first 7 array elements resulting (starting from index 0) are the checked/unchecked values
			// for the week days.
			// The first bit array element always represents Monday.
			type: Number,
			default: 0			// No day checked by default
		},
		editable: {
			// If false, the control only displays the selected days. If true, the control displays a check box for each day of the week, besides the day initial.
			type: Boolean,
			default: false
		},
		bitmapLength: {
			type: Number,
			default: 31
		},
		gap: {
			type: Number,
			default: 4
		}
	},
	emits: ["update:modelValue"],
	data: function() {
		return {
			daysMap: [],
			colors: {
				highlight: "#ff5555",
				normal: ""
			}
		}
	},
	created: function() {
		this.daysMap = this.valueToDayMap(this.modelValue);
	},
	computed: {
		viewText: function() {
			return this.daysMap.filter(day => day.isSet).map(day => day.dayName).join(", ");
		}
	},
	watch: {
		modelValue: function() {
			this.daysMap = this.valueToDayMap(this.modelValue);
		}
	},
	methods: {
		toggleDay: function(index) {
			this.daysMap[index].isSet = !this.daysMap[index].isSet;
			this.doOnChange();
		},
		valueToDayMap: function(value) {
			var str = value.toString(2).padStart(this.bitmapLength, "0");
			return [...str].reverse().map((x, index) => ( { isSet: x == "1", dayName: index + 1 } ));
		},
		dayMapToValue: function(daysMap) {
			//var str = [...daysMap].reverse().join("");
			var str = daysMap.map(x => x.isSet ? "1" : "0").reverse().join("");
			return parseInt(str, 2);
		},

		doOnChange: function() {
			this.$emit("update:modelValue", this.dayMapToValue(this.daysMap));
		}
	}
}
</script>

<style>
</style>