<template>
	<div>
		<div class="title is-size-6-mobile is-size-3-tablet brand-color">Subscriptions on server</div>

		<div class="table-container">
			<table class="table is-hoverable container">
				<thead>
					<tr>
						<th>User id</th>
						<th>OS info</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="subscription, index in subscriptions">
						<td>{{ subscription.userId }}</td>
						<td>{{ subscription.osInfo.platform }}, Browser: {{ subscription.osInfo.browserName }}</td>
						<td>
							<div class="actionImagesContainer">
								<button class="button" @click="sendTestNotification(subscription.id)">Send test notification</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'

export default {
	name: 'PushSubscriptions',
	components: {
	},
	data: function() {
		return {
			subscriptions: []
		}
	},
	computed: {
		...mapState([
			"goptions"
		])
	},
	created: function() {
		this.getServerSubscriptions();
	},
	methods: {
		sendTestNotification(subscriptionId) {
			this.showWait(true);

			this.$ky(this.apiUrl + "/SendTestComplexNotification2", {
				method: "GET",
				searchParams: {
					subscriptionId: subscriptionId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
				})
			.json()
			.then(responseJson => {
				this.subscriptionssuccess = responseJson.success;
				this.showWait(false);
			})
			.catch(error => {
				this.showWait(false);
				alert("Error: " + error.message);
			});
		},
		getServerSubscriptions() {
			//this.showWait(true);

			this.$ky(this.apiUrl + "/GetAllPushSubscriptions", {
				method: "GET",
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
				})
			.json()
			.then(responseJson => {
				//console.log(responseJson);

				// Deserialize the os-related field, returned from server as JSON formatted string.
				for (let i = 0; i < responseJson.subscriptions.length; i++) {
					responseJson.subscriptions[i].osInfo = JSON.parse(responseJson.subscriptions[i].osInfo);
				}
				
				this.subscriptions = responseJson.subscriptions;
				this.showWait(false);
			})
			.catch(error => {
				this.showWait(false);
				alert("Error: " + error.message);
			});
		}
	}
}
</script>

<style>
.tableHead {
	width: 1px;
}
.tableCell {
	white-space: nowrap;
}
</style>