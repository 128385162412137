<template>
	<div>
		<div class="title is-size-6-mobile is-size-3-tablet brand-color">
			Test
		</div>
		<div class="is-size-1">This is a body text.</div>
		<div class="is-size-4 ls-is-comment">
			This is the comment text. (Custom class ls-is-comment applied)
		</div>

		<hr>

		<div>serviceWorkerState</div>
		<div>{{goptions.serviceWorkerState}}</div>
		<div>
			<div v-if="goptions.serviceWorkerState.deferredInstallPrompt != null" class="button is-primary" @click="installPwaApp()">Install application</div>
		</div>


		<hr />

		<a @click="logout()">Set state to logout</a>

		<hr/>

		<div>authStoredToken</div>
		<div>{{authStoredToken}}</div>

		<hr />

		<MonthDays v-model="monthValue" :editable="false" :gap="10" />

		<hr />

		<a @click="TestApiGetUsers()">Call Test API - GetUsers</a>

		<hr />

		<div>
			<span>Week days map: 
				<WeekDays v-model="weekValue" :template="weekTemplate" :firstDay="0" :editable="false" :gap="10" />
			</span>
			<span class="spaceleft ls-feels-link" @click="weekTemplate='LMMJVSD'">LMMJVSD</span>
			<span class="spaceleft ls-feels-link" @click="weekTemplate='MTWTFSS'">MTWTFSS</span>
		</div>
		<div>
			{{this.ML.trans("weekdays_template")}}
		</div>

		<hr />

		<div class="">
			This shows a cell with contents children centered vertically:
		</div>
		<div class="columns mt-1">
			<div class="column has-background-primary-light">
				<img
					class="ls-is-vam"
					alt="Settings icon"
					src="../../assets/images/settings.svg"
					style="width: 30px"
				/>
				<span class="ls-is-vam">s gdgn dkg dkg</span>
				<span class="ls-is-vam" style="font-size: 50px">dgn</span>
			</div>
			<div class="column has-background-danger-light"></div>
			<div class="column has-background-link-light"></div>
			<div class="column has-background-primary-light"></div>
		</div>

		<hr />

		<div class="card" style="display: inline-block;">
			<header class="card-header">
				<p class="card-header-title">Component</p>
				<button class="card-header-icon" aria-label="more options">
					<span class="icon">
						<i class="fas fa-angle-down" aria-hidden="true"></i>
					</span>
				</button>
			</header>
			<div class="card-content">
				<div class="content">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Phasellus nec iaculis mauris.
					<a href="#">@bulmaio</a>. <a href="#">#css</a>
					<a href="#">#responsive</a>
					<br />
					<time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
				</div>
			</div>
			<footer class="card-footer">
				<a href="#" class="card-footer-item">Save</a>
				<a href="#" class="card-footer-item">Edit</a>
				<a href="#" class="card-footer-item">Delete</a>
			</footer>
		</div>

		<div class="card" style="display: inline-block;">
			<header class="card-header">
				<p class="card-header-title">Component</p>
				<button class="card-header-icon" aria-label="more options">
					<span class="icon">
						<i class="fas fa-angle-down" aria-hidden="true"></i>
					</span>
				</button>
			</header>
			<div class="card-content">
				<div class="content">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					Phasellus nec iaculis mauris.
					<a href="#">@bulmaio</a>. <a href="#">#css</a>
					<a href="#">#responsive</a>
					<br />
					<time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
				</div>
			</div>
			<footer class="card-footer">
				<a href="#" class="card-footer-item">Save</a>
				<a href="#" class="card-footer-item">Edit</a>
				<a href="#" class="card-footer-item">Delete</a>
			</footer>
		</div>

		<hr>

					<!-- "--my-prime-color": "rgb(75, 75, 75)",	// "rgb(37, 128, 105)"		rgb(50, 50, 50)
					"--my-contrast-color": "#eeeeee",
					"--my-body-back-color": "#fafafa",
					"--my-body-text-color": "rgb(48, 48, 48)",
					"--my-body-text-color2": "#727272",
					"--my-menu-back-color": "#e7e7e7",
					"--my-a-logo": "hue-rotate(178deg) saturate(150%)",
					"--my-box-back-color": "hsl(0, 0%, 100%)",
					"--my-brand-color": "rgb(50, 170, 140)",	// The "brand" color - like a special turquoise etc.

					"--my-link-color": "#206655",
					"--my-link-hover-color": "#111111",

					"--my-input-color": "#rgb(48, 48, 48)",
					"--my-input-back-color": "#ffffff",

					"--my-wait-popup-back-color": "#ffffff",
					"--my-wait-popup-fore-color": "#111111",

					"--my-navbar-dropdown-back-color": "#ffffff",
					"--my-navbar-dropdown-item-hover-color": "#222222",
					"--my-navbar-divider-background-color": "#cccccc",

					"--my-table-color": "rgb(50, 50, 50)",
					"--my-table-back-color": "rgb(255, 255, 255)",
					"--my-table-row-active-back-color": "rgb(220, 220, 220)" -->

		<div class="columns is-multiline">
			<div class="column is-3" style="background-color: var(--my-prime-color)"><code>--my-prime-color</code></div>
			<div class="column is-3" style="background-color: var(--my-contrast-color)"><code>--my-contrast-color</code></div>
			<div class="column is-3" style="background-color: var(--my-body-back-color)"><code>--my-body-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-body-text-color)"><code>--my-body-text-color</code></div>
			<div class="column is-3" style="background-color: var(--my-body-text-color2)"><code>--my-body-text-color2</code></div>
			<div class="column is-3" style="background-color: var(--my-menu-back-color)"><code>--my-menu-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-menu-back-color)"><code>--my-menu-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-a-logo)"><code>--my-a-logo</code></div>
			<div class="column is-3" style="background-color: var(--my-box-back-color)"><code>--my-box-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-brand-color)"><code>--my-brand-color</code></div>
			<div class="column is-3" style="background-color: var(--my-link-color)"><code>--my-link-color</code></div>
			<div class="column is-3" style="background-color: var(--my-link-hover-color)"><code>--my-link-hover-color</code></div>
			<div class="column is-3" style="background-color: var(--my-input-color)"><code>--my-input-color</code></div>
			<div class="column is-3" style="background-color: var(--my-input-back-color)"><code>--my-input-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-wait-popup-back-color)">--my-wait-popup-back-color<code></code></div>
			<div class="column is-3" style="background-color: var(--my-wait-popup-fore-color)">--my-wait-popup-fore-color<code></code></div>
			<div class="column is-3" style="background-color: var(--my-navbar-dropdown-back-color)"><code>--my-navbar-dropdown-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-navbar-dropdown-item-hover-color)">--my-navbar-dropdown-item-hover-color<code></code></div>
			<div class="column is-3" style="background-color: var(--my-navbar-divider-background-color)">--my-navbar-divider-background-color<code></code></div>
			<div class="column is-3" style="background-color: var(--my-table-color)"><code>--my-table-color</code></div>
			<div class="column is-3" style="background-color: var(--my-table-back-color)"><code>--my-table-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-table-row-active-back-color)"><code>--my-table-row-active-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-error-color)"><code>--my-error-color</code></div>
			<div class="column is-3" style="background-color: var(--my-happy-title-fore-color)"><code>--my-happy-title-fore-color</code></div>
			<div class="column is-3" style="background-color: var(--my-happy-title-back-color)"><code>--my-happy-title-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-happy-title-border-color)"><code>--my-happy-title-border-color</code></div>
			<div class="column is-3" style="background-color: var(--my-devlistitem-back-color)"><code>--my-devlistitem-back-color</code></div>
			<div class="column is-3" style="background-color: var(--my-devlistitem-border-color)"><code>--my-devlistitem-border-color</code></div>
		</div>

		<div>
			<SignalGauge style="height: 20px; background-color: #888888;" />
		</div>

		<div>
			<div><span>Location</span><span><a href="#" class="button spaceleft is-primary" @click="getLocation()">Get</a></span></div>
			<div>{{location}}</div>
		</div>

		<div>
			getConst() = {{getConst()}}
		</div>

		<hr/>

		<div>
			<div class="is-size-3">Get JWT expiration date-time</div>
			<div style="padding-top: 10px">
				<button @click="getJWTExpiration()">Get JWT expiration</button>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import SignalGauge from "@/components/controls/SignalGauge.vue"
import WeekDays from "@/components/controls/WeekDays.vue"
import MonthDays from "@/components/controls/MonthDays.vue"
import { auth } from "@/components/mixins/auth";
import { datesLib } from "@/components/mixins/datesLib";

export default {
	name: "Test",
	mixins: [auth, datesLib],
	components: {SignalGauge, WeekDays, MonthDays},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	data: function() {
		return {
			location: null,
			weekTemplate: "MTWTFSS",
			weekValue: 5,
			monthValue: 10
		}
	},
	watch: {
		weekValue: function() {
			console.log(this.weekValue);
		}
	},
	methods: {
		getConst: function() {
			return this.$gconst.timeouts.medium;
		},
		TestApiGetUsers: function() {
			this.$ky.get("http://localhost:4000/Users/GetAll", {
				searchParams: {},
				headers: {
					"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJuYmYiOjE2NDQ5Mjc0ODIsImV4cCI6MTY0NTUzMjI4MiwiaWF0IjoxNjQ0OTI3NDgyfQ.KZC8fS9bTDVU1Mdyi-j_tIu9gHl6c5IEoA10wLYja-E"
				},
				cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				console.log("responseJson", responseJson);
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		logout: function() {
			this.authLogout();
		},
		onWChange: function(val) {
			console.log(val);
		},
		getLocation: function() {

			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(function (location) {
					//appendLocation(location, 'fetched');
					console.log(location);
				});
				//watchId = navigator.geolocation.watchPosition(appendLocation);
			}
			else {
				alert("Geolocation not supported");
			}

		},
		getJWTExpiration: function() {
			let jwt = this.authJwt;
			//console.log(jwt);
			const decodedToken = this.decodeJWT(jwt);
			alert('JWT token expiration date:\n' + this.UnixUtcToLocalDateString(decodedToken.exp, "YMD"));
		}
	}
}
</script>

<style scoped>
.test-forecolor {
	color: var(--my-prime-color);
}
</style>
