<template>
	<div>

		<div v-if="job.isExecuting && hasMinRights(3)" class="has-text-danger mb-4">
			<span class="has-text-weight-bold">{{ ML.trans('warning') }}: </span><span>{{ ML.trans('program_is_executing') }}.</span>
			<span class="spaceleft">{{ ML.trans('cannot_update_exec_prog') }}.</span>
			<span class="spaceleft">{{ ML.trans('can_stop_prog_in') }} </span>
			<router-link :to="{name: 'Dashboard', query: { 'nav': true }}" class="">{{ ML.trans('dashboard') }}</router-link>
		</div>
		
		<div class="columns">
			<div class="column">
				<div class="container block">
					<div class="box">
						<div class="columns is-centered is-variable">
							<div class="column has-text-centered is-prime-color ls-happy-title">
								<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('general') }}</span>
							</div>
						</div>

						<div class="columns is-vcentered">
							<div class="column has-text-centered-tablet">
								<span class="ls-is-label">{{ ML.trans('program_name') }}: </span>
								<span><input type="text" class="input" id="txtJobName" size="25" autocomplete="off" v-model="job.name" /></span>
							</div>
							<div class="column has-text-centered-tablet">
								<span class="ls-is-label" style="cursor: pointer;" @click="job.isActive = !job.isActive">{{ ML.trans('active') }}: </span>
								<span><input type="checkbox" id="chkIsActive" v-model="job.isActive" /></span>
							</div>
							<div class="column has-text-centered-tablet">
								<div class="columns is-vcentered is-mobile">
									<div class="column ls-is-label has-text-right-tablet ls-is-vam">
										{{ ML.trans('pg_time') }}:
									</div>
									<div class="column has-text-left ls-is-vam is-hidden-mobile">
										<VueCtkDateTimePicker
											v-model="dtpicker.privateTimeStr"
											onlyTime
											:dark="goptions.localSettings.darkMode"
											:label="null" 
											input-size="lg" 
											no-label
											color="var(--my-brand-color)"
											noClearButton
											format="HH:mm:ss"
											formatted="HH:mm"
											@validate="onDtPickerValidateClicked()"
											@is-hidden="onDtPickerHidden()"
											:no-value-to-custom-elem="true"
											>
										</VueCtkDateTimePicker>
									</div>

									<div class="column has-text-left ls-is-vam is-hidden-tablet">
										<VueCtkDateTimePicker
											v-model="dtpicker.lastSetTime"
											onlyTime
											:dark="goptions.localSettings.darkMode"
											:label="null" 
											input-size="lg" 
											inline
											no-label
											color="var(--my-brand-color)"
											format="HH:mm:ss"
											formatted="HH:mm"
											:no-value-to-custom-elem="true"
											>
										</VueCtkDateTimePicker>
									</div>
								</div>
								
							</div>

						</div>
						<div class="columns is-vcentered">
							<div class="column has-text-centered">
								<span class="ls-is-label ls-is-vam">{{ ML.trans('recurrence') }}: </span>
								<span class="select is-primary content ls-is-vam">
									<select class="is-prime-color" id="comboRecurrenceType" v-model="job.recurrenceType">
										<option v-for="recurrType in enumPresenter.recurrenceTypes" v-bind:value="recurrType.value" :key="recurrType.value">
											{{ ML.trans(getMlidFromVal(enumPresenter.recurrenceTypes, recurrType.value, "notdef")) }}
										</option>
									</select>
								</span>
							</div>
							<div v-if="job.recurrenceType == 9" class="column has-text-centered">
								<span class="ls-is-label ls-is-vam">{{ ML.trans('value') }}: </span>
								<span class="ls-is-vam"><input type="number" class="number is-prime-color is-size-6" min="1" max="600" id="txtRecurrenceValue" size="5" autocomplete="off" v-model="job.recurrenceValue" /></span>
							</div>
							<div v-if="job.recurrenceType == 9" class="column has-text-centered">
								<span class="ls-is-label ls-is-vam">{{ ML.trans('unit') }}: </span>
								<span class="select is-primary content ls-is-vam">
									<select class="is-prime-color" v-model="job.recurrenceUnit">
										<option v-for="recurrUnit in enumPresenter.recurrenceUnit" :key="recurrUnit.value" v-bind:value="recurrUnit.value">
											{{ ML.trans(getMlidFromVal(enumPresenter.recurrenceUnit, recurrUnit.value, "notdef")) }}
										</option>
									</select>
								</span>
							</div>
							<div v-if="job.recurrenceType == 10" class="column has-text-centered">
								<WeekDays v-model="jobRecurrenceValue" :template="ML.trans('weekdays_template')" :firstDay="goptions.currentuser.firstDayOfWeek" :editable="true" :gap="10" />
							</div>
							<div v-if="job.recurrenceType == 11" class="column has-text-centered">
								<MonthDays v-model="jobRecurrenceValue" :editable="true" :gap="10" />
							</div>
						</div>

						<div v-if="(!isPrincipal()) && hasMaxRights(2)" class="disableDiv"></div>

					</div>	<!-- End General box -->

					<!-- <div class="buttons is-grouped is-centered is-hidden-mobile">
						<a class="button is-primary" @click="doSave()">{{ ML.trans('ok') }}</a>
						<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
						<a v-if="job.jobId != 0" class="button is-danger" @click="doDelete()">{{ ML.trans('delete') }}</a>
					</div> -->

				</div>

				<div class="container block">
					<div class="box">
						<div class="columns is-centered is-variable">
							<div class="column has-text-centered is-prime-color ls-happy-title">
								<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('execute_if_opt') }}</span>
							</div>
						</div>

						<div class="mb-5 p-2 chain-link-view" :class="{'chain-link-view': !job.ifLink.modeEdit, 'chain-link-edit': job.ifLink.modeEdit}">
							<!-- View IF link -->
							<div v-if="!job.ifLink.modeEdit">
								<div style="text-align: right;">
									<transition name="fade">
										<span v-show="job.ifLink.controlPanelVisible || options.alwaysShowButtonPanels" key="if1">
											<img v-if="hasMinRights(3)" src="@/assets/images/edit.svg" class="ls-h-action-icon" alt="Edit" :title="ML.trans('edit')" @click.prevent="editChainLink(job.ifLink)" />
											<img v-if="hasMinRights(2)" src="@/assets/images/settings.svg" class="ls-h-action-icon" alt="Execute" :title="ML.trans('execute')" @click.prevent="executeChainLink(job.ifLink)" />
											<img v-if="hasMinRights(3)" src="@/assets/images/delete.svg" class="ls-h-action-icon" alt="Delete" :title="ML.trans('delete')" @click.prevent="deleteChainLink(job.ifLink)" />
										</span>
									</transition>
									<span v-if="!options.alwaysShowButtonPanels" class="ls-feels-link spaceleft" style="vertical-align: top;" @click="toggleControlPanelVisibility(job.ifLink)">[...]</span>
								</div>

								<div v-for="action in getChainLinkActions(job.ifLink)" :key="genActionKey(action)">
									{{ actionText(action) }}
								</div>
							</div>


							<!-- Edit IF link -->
							<div v-if="job.ifLink.modeEdit">
								<div style="text-align: right;">
									<transition name="fade">
										<span v-show="job.ifLink.controlPanelVisible || options.alwaysShowButtonPanels" class="ls-is-vam" key="if2">
											<img src="@/assets/images/checked-black.svg" class="ls-h-action-icon" alt="OK" :title="ML.trans('ok')" @click.prevent="editCloseSaveChainLink(job.ifLink)" />
											<img src="@/assets/images/cancel-2.svg" class="ls-h-action-icon" alt="Cancel" :title="ML.trans('cancel')" @click.prevent="editCloseCancelChainLink(job.ifLink)" />
											<img src="@/assets/images/add.svg" class="ls-h-action-icon" alt="Add" :title="ML.trans('add_condition')" @click.prevent="addChainLinkAction(job.ifLink, false)" />
											<img src="@/assets/images/drop.svg" class="ls-h-action-icon" alt="Add weather" :title="ML.trans('add_weather')" @click.prevent="addChainLinkWeatherAction(job.ifLink, false)" />
										</span>
									</transition>
									<span v-if="!options.alwaysShowButtonPanels" class="ls-feels-link ls-is-vam spaceleft" style="vertical-align: top;" @click="toggleControlPanelVisibility(job.ifLink)">[...]</span>
								</div>

								<draggable v-model="chainLinkEdit.actions" tag="transition-group" :component-data="{name:'flip-list'}">
									<template #item="{element, index}" >
										<div class="chain-link-item-edit">
											<span @click="editChainLinkAction(index)" style="cursor: pointer;">{{ actionText(element) }}</span>
											<span :title="ML.trans('delete')" class="ls-feels-link" style="float: right; font-weight: bold;" @click="removeChainLinkAction(element)">X</span>
										</div>
									</template>
								</draggable>

							</div>
						</div>

					</div>
				</div>

			</div>

			<div class="column">
				<div class="container block">
					<div class="box">
						<div class="columns is-centered is-variable">
							<div class="column has-text-centered is-prime-color ls-happy-title">
								<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('schedule') }}</span>
							</div>
						</div>



						<!-- ======= Events ======== -->

						<div class="ls-is-label mb-3">
							<span>{{ ML.trans('events') }}</span>
							<span v-if="hasMinRights(3)" class="ls-feels-link" style="float: right;" @click="addChainLink()">{{ ML.trans('add_event') }}</span>
						</div>
						<div v-for="chainLink in job.chainLinks" :key="chainLink.id" class="mb-5 p-2 chain-link-view" :class="{'chain-link-view': !chainLink.modeEdit, 'chain-link-edit': chainLink.modeEdit}">

							<!-- View link -->
							<div v-if="!chainLink.modeEdit">
								<div style="text-align: right;">
									<transition name="fade">
										<span v-show="chainLink.controlPanelVisible || options.alwaysShowButtonPanels" key="1">
											<img v-if="hasMinRights(3)" src="@/assets/images/edit.svg" class="ls-h-action-icon" alt="Edit" :title="ML.trans('edit')" @click.prevent="editChainLink(chainLink)" />
											<img v-if="hasMinRights(2)" src="@/assets/images/settings.svg" class="ls-h-action-icon" alt="Execute" :title="ML.trans('execute')" @click.prevent="executeChainLink(chainLink)" />
											<img v-if="hasMinRights(3)" src="@/assets/images/delete.svg" class="ls-h-action-icon" alt="Delete" :title="ML.trans('delete')" @click.prevent="deleteChainLink(chainLink)" />
										</span>
									</transition>
									<span v-if="!options.alwaysShowButtonPanels" class="ls-feels-link spaceleft" style="vertical-align: top;" @click="toggleControlPanelVisibility(chainLink)">[...]</span>
								</div>

								<div>
									<span>{{ ML.trans('offset') }}: {{ timeStringFormat(chainLink.startDelayStr, 'HM') }}</span>
									<span class="spaceleft">=> {{ ML.trans('executes_at') }}: {{ getChainLinkExecutionTime(chainLink) }}</span>
								</div>

								<div v-for="action in getChainLinkActions(chainLink)" :key="genActionKey(action)">
									&gt; {{ actionText(action) }}
								</div>
								<div v-if="chainLink.tasksStr == ''" style="color: #ff5555">({{ ML.trans('warning_no_action') }})</div>
							</div>
							
							<!-- Edit link -->
							<div v-if="chainLink.modeEdit">
								<div style="text-align: right;">
									<transition name="fade">
										<span v-show="chainLink.controlPanelVisible || options.alwaysShowButtonPanels" class="ls-is-vam" key="2">
											<img src="@/assets/images/checked-black.svg" class="is-hidden-mobile ls-h-action-icon" alt="OK" :title="ML.trans('ok')" @click.prevent="editCloseSaveChainLink(chainLink)" />
											<img src="@/assets/images/checked-black.svg" class="is-hidden-tablet ls-h-action-icon" alt="OK" :title="ML.trans('ok')" @click.prevent="editCloseSaveChainLink_mobile(chainLink)" />
											<img src="@/assets/images/cancel-2.svg" class="ls-h-action-icon" alt="Cancel" :title="ML.trans('cancel')" @click.prevent="editCloseCancelChainLink(chainLink)" />
											<img src="@/assets/images/add.svg" class="ls-h-action-icon" alt="Add" :title="ML.trans('add_action')" @click.prevent="addChainLinkAction(chainLink, true)" />
										</span>
									</transition>
									<span v-if="!options.alwaysShowButtonPanels" class="ls-feels-link ls-is-vam spaceleft" style="vertical-align: top;" @click="toggleControlPanelVisibility(chainLink)">[...]</span>
								</div>

								<div>
									<div class="spaceright" style="display: inline-block;">{{ ML.trans('offset') }}:</div>
									<div class="is-hidden-mobile" style="display: inline-block;">
										<VueCtkDateTimePicker
											v-model="chainLinkEdit.privateTimeStr"
											onlyTime
											:dark="goptions.localSettings.darkMode"
											:label="null" 
											input-size="lg" 
											no-label
											color="var(--my-brand-color)"
											noClearButton
											format="HH:mm:ss"
											formatted="HH:mm"
											@validate="onLinkDtPickerValidateClicked()"
											@is-hidden="onLinkDtPickerHidden()"
											:no-value-to-custom-elem="true"
											>
										</VueCtkDateTimePicker>
									</div>
									<div class="is-hidden-tablet" style="display: inline-block; width: 150px;">
										<VueCtkDateTimePicker
											v-model="chainLinkEdit.privateTimeStr"
											onlyTime
											:dark="goptions.localSettings.darkMode"
											:label="null" 
											input-size="lg" 
											inline
											no-label
											color="var(--my-brand-color)"
											format="HH:mm:ss"
											formatted="HH:mm"
											@validate="onLinkDtPickerValidateClicked()"
											@is-hidden="onLinkDtPickerHidden()"
											:no-value-to-custom-elem="true"
											:no-header="true"
											:no-label="true"
											>
										</VueCtkDateTimePicker>
									</div>
								</div>


								<!--
									[TBD] Should add a key to draggable (otherwise Vue warning). The old control was using: key="genActionKey(action)"
								-->
								<draggable v-model="chainLinkEdit.actions" tag="transition-group" :component-data="{name:'flip-list'}">
									<template #item="{element, index}" >
										<div class="chain-link-item-edit">
											<span @click="editChainLinkAction(index)" style="cursor: pointer;">{{ actionText(element) }}</span>
											<span :title="ML.trans('delete')" class="ls-feels-link" style="float: right; font-weight: bold;" @click="removeChainLinkAction(element)">X</span>
										</div>
									</template>
								</draggable>

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<ModalPopupFade :visible="linkExec.popupVisible" columns="6" transDuration="0.5s" :popTitle="ML.trans('action_execution')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="closeLinkExecutor()">
			<ActionListExecutor v-if="linkExec.popupVisible" :targetActions="linkExec.targetActions" @askClose="closeLinkExecutor()"></ActionListExecutor>
		</ModalPopupFade>

		<ModalPopupFade :visible="actionAdd.popupVisible" columns="6" transDuration="0.5s" :popTitle="actionAdd.seedAction.device == null ? ML.trans('add_action_tit') : ML.trans('edit_action_tit')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="closeActionAdd()">
			<ChainLinkActionEditor v-if="actionAdd.popupVisible" :controllers="controllers" :devices="devices" :seedAction="actionAdd.seedAction" @askClose="closeActionAdd()" @askSave="saveActionAdd" @askSaveEdit="saveActionEdit"></ChainLinkActionEditor>
		</ModalPopupFade>

		<ModalPopupFade :visible="actionAdd.popupWeatherVisible" columns="6" transDuration="0.5s" :popTitle="actionAdd.seedAction.deviceId == 0 ? ML.trans('add_weather_tit') : ML.trans('edit_weather_tit')" popupLayoutClass="popupEditLayout" :keepLayoutOnMobile="false" @askClose="closeWeatherAdd()">
			<ChainLinkWeatherEditor v-if="actionAdd.popupWeatherVisible" :seedAction="actionAdd.seedAction" @askClose="closeWeatherAdd()" @askSave="saveWeathernAdd" @askSaveEdit="saveWeatherEdit"></ChainLinkWeatherEditor>
		</ModalPopupFade>

		<div class="ls-footer">
			<div class="buttons is-grouped is-centered">
				<a v-if="hasMinRights(3)" class="button is-primary" @click="doSave()">{{ ML.trans('save') }}</a>
				<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
				<a v-if="(job.jobId != 0) && hasMinRights(3)" class="button is-danger" @click="doDelete()">{{ ML.trans('delete') }}</a>
			</div>
		</div>
		<div class="ls-footer-space"></div>

	</div>
</template>

<script>
import { reactive, ref } from 'vue'
import { mapState, mapMutations, Store } from 'vuex'
import { datesLib } from "@/components/mixins/datesLib";
import ActionListExecutor from "@/components/ActionListExecutor.vue"
import ChainLinkActionEditor from "@/components/ChainLinkActionEditor.vue"
import ChainLinkWeatherEditor from "@/components/ChainLinkWeatherEditor.vue"
import WeekDays from "@/components/controls/WeekDays.vue"
import MonthDays from "@/components/controls/MonthDays.vue"
import draggable from 'vuedraggable'

export default {
	name: "ProgramEdit",
	components: { ActionListExecutor, ChainLinkActionEditor, ChainLinkWeatherEditor, WeekDays, MonthDays, draggable },
	mixins: [datesLib],
	props: ["targetJob", "refDevices", "refControllers"],
	data: function() {
		return {
			job: {},
			controllers: [],
			devices: [],
			links: [],
			options: {
				autoSaveEditingLink: true,			// If true => With a link in edit mode, if the user starts editing another link => the currently edited link is saved.
				deleteNewLinkOnEditCancel: true,	// If True => cancels the link addition, i.e. if we are editing a new link (just added), and if we close the link editor without explicit save => the newly added link is removed. If False => when exiting the edit mode, the new link is saved in its current state.
				alwaysShowButtonPanels: true		// If false, the [...] button is used to switch between panel open / closed states.
			},
			dtpicker: {
				label: null,
				privateTimeStr: "",		// bound to the time control
				lastSetTime: ""			// used to reset the modified value in the time control to the last set value
			},
			linkExec: {
				popupVisible: false,
				targetActions: []
			},
			chainLinkEdit: {
				link: {},
				actions: [],
				lastSetTime: "",
				privateTimeStr: "",
				linkBackup: {},
				isNewItem: false,
				editActionIndex: 0
			},
			actionAdd: {
				popupVisible: false,
				popupWeatherVisible: false,
				seedAction: {},
			}
		};
	},
	created: function() {
		this.controllers = this.deepClone(this.refControllers);
		this.devices = this.deepClone(this.refDevices);
		this.job = this.deepClone(this.targetJob);

		this.job.chainLinks.forEach(link => {
			link = reactive(link);
			link.controlPanelVisible = ref(false);
			link.modeEdit = ref(false);
		});

		// Initialize time data
		this.dtpicker.privateTimeStr = this.dtpicker.lastSetTime = this.localTimeStrFromUtcDateStr(this.job.dueDateTimeStr, this.goptions.currentuser.utcOffset, this.goptions.currentuser.isDST ? 60 : 0);

		// Add the [id] field to the chain links array elements, initialized with unique values.
		this.addUniqueIds(this.job.chainLinks, "id");
		// Sort links by delay.
		this.sortLinks();

		// Add extra properties to the "If" chain link
		this.job.ifLink = reactive(this.job.ifLink);
		this.job.ifLink.controlPanelVisible = ref(false);
		this.job.ifLink.modeEdit = ref(false);
		this.showWait(false);
	},
	unmounted: function() {
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		jobRecurrenceValue: {
			get: function() {
				return this.job.recurrenceValue;
			},
			set: function(newValue) {
				this.job.recurrenceValue = newValue;
			}
		}
	},
	watch: {
	},
	methods: {
		getChainLinkExecutionTime: function(chainLink) {
			var refDate = "2000-01-01";		// Arbitrary date
			var dateTimeRefStr = refDate + " " + this.dtpicker.lastSetTime;
			var execDateTime = this.dateAddTimeSpanStr(dateTimeRefStr, chainLink.startDelayStr);
			var execDateStr = this.dateToIsoStr(execDateTime, "YMD", "D");
			return this.timeStringFormat((execDateStr > refDate ? "(next day) " : "") + this.dateToIsoStr(execDateTime, null, "T"), "HM");
		},
		closeActionAdd: function() {
			this.actionAdd.popupVisible = false;
		},
		saveActionAdd: function(action) {
			this.chainLinkEdit.actions.push(action);
			this.actionAdd.popupVisible = false;
		},
		saveActionEdit: function(action) {
			this.chainLinkEdit.actions[this.chainLinkEdit.editActionIndex] = action;
			this.actionAdd.popupVisible = false;
		},
		addChainLinkAction: function(chainLink, isSetAction) {
			if (isSetAction) {
				this.actionAdd.seedAction = {domain: 0, device: null, operator: "=", value: 0};
			}
			else {
				this.actionAdd.seedAction = {domain: 0, device: null, operator: "==", value: 0};
			}
			this.actionAdd.popupVisible = true;
		},
		// ==============================================================
		addChainLinkWeatherAction: function(chainLink) {
			this.actionAdd.seedAction = {domain: 1, deviceId: 0, operator: "==", value: 0};
			this.actionAdd.popupWeatherVisible = true;
		},
		closeWeatherAdd: function() {
			this.actionAdd.popupWeatherVisible = false;
		},
		saveWeathernAdd: function(action) {
			this.chainLinkEdit.actions.push(action);
			this.actionAdd.popupWeatherVisible = false;
		},
		saveWeatherEdit: function(action) {
			this.chainLinkEdit.actions[this.chainLinkEdit.editActionIndex] = action;
			this.actionAdd.popupWeatherVisible = false;
		},
		// ==============================================================
		editChainLinkAction: function(actionIndex) {
			console.log("Edit action index: " + actionIndex);
			var action = this.chainLinkEdit.actions[actionIndex];
			this.actionAdd.seedAction = action;
			this.chainLinkEdit.editActionIndex = actionIndex;

			if (action.domain == 0) {
				this.actionAdd.popupVisible = true;
			}
			else if (action.domain == 1) {
				this.actionAdd.popupWeatherVisible = true;
			}
		},
		initChainLinkEdit: function(chainLink) {
			// Make a backup of the initial link.
			this.chainLinkEdit.linkBackup = this.deepClone(chainLink);
			// Initialize the chain link edit info.
			this.chainLinkEdit.link = chainLink;
			this.chainLinkEdit.actions = this.getChainLinkActions(chainLink);
			this.chainLinkEdit.privateTimeStr = this.chainLinkEdit.lastSetTime = chainLink.startDelayStr;
		},
		closeLinkExecutor: function() {
			this.linkExec.popupVisible = false;
		},
		editCloseSaveChainLink: function(chainLink) {
			this.setChainLinkActions(chainLink, this.chainLinkEdit.actions);
			chainLink.startDelayStr = this.chainLinkEdit.lastSetTime;
			this.sortLinks();	// Sort links in case the link's delay has been changed
			//chainLink.controlPanelVisible = false;
			chainLink.modeEdit = false;
		},
		editCloseSaveChainLink_mobile: function(chainLink) {
			this.setChainLinkActions(chainLink, this.chainLinkEdit.actions);
			chainLink.startDelayStr = this.chainLinkEdit.privateTimeStr;
			this.sortLinks();	// Sort links in case the link's delay has been changed
			//chainLink.controlPanelVisible = false;
			chainLink.modeEdit = false;
		},
		editCloseCancelChainLink: function(chainLink) {
			if (this.chainLinkEdit.isNewItem && this.options.deleteNewLinkOnEditCancel) {
				// If editing a newly added item => remove the item
				this.deleteChainLink(chainLink);
				return;
			}
			chainLink.tasksStr = this.chainLinkEdit.linkBackup.tasksStr;
			chainLink.startDelayStr = this.chainLinkEdit.linkBackup.startDelayStr;
			//chainLink.controlPanelVisible = false;
			chainLink.modeEdit = false;
		},
		editChainLink: function(chainLink, isNew) {
			isNew = (isNew == null ? false : isNew);	// isNew default value: false
			this.chainLinkEdit.isNewItem = isNew;

			// First close any open chain link editor.
			// If so stated, first save any link in edit mode.
			var editingLink = this.job.chainLinks.find(l => l.modeEdit);
			if (editingLink == null) {
				// Check also the IF link
				if (this.job.ifLink.modeEdit) {
					editingLink = this.job.ifLink;
				}
			}
			if (editingLink != null) {
				if (this.options.autoSaveEditingLink) {
					this.editCloseSaveChainLink(editingLink);
				}
				else {
					this.editCloseCancelChainLink(editingLink);
				}
			}

			this.initChainLinkEdit(chainLink);
			// Enter edit mode
			chainLink.modeEdit = true;
		},
		addChainLink: function() {
			var newChainLink = reactive({startDelayStr:"00:00:00", tasksStr:"", name:""});
			newChainLink.controlPanelVisible = ref(true);
			newChainLink.modeEdit = ref(false);
			var indexes = this.job.chainLinks.map(link => link.id);
			// Set the new chain job index (max of all indexes + 1).
			var maxIndex = 0;
			indexes.forEach(index => {
				maxIndex = Math.max(maxIndex, index);
			});
			newChainLink.id = ref(maxIndex + 1);
			this.job.chainLinks.splice(0, 0, newChainLink);
			
			// Enter edit mode for the new chain link.
			this.editChainLink(newChainLink, true);
		},
		deleteChainLink: function(chainLink) {
			var index = this.job.chainLinks.indexOf(chainLink);
			if (index !== -1) {
				this.job.chainLinks.splice(index, 1);
			}
		},
		executeChainLink: function(chainLink) {
			this.linkExec.targetActions = this.getChainLinkActions(chainLink);
			this.linkExec.popupVisible = true;
		},
		toggleControlPanelVisibility: function(chainLink) {
			chainLink.controlPanelVisible = !chainLink.controlPanelVisible;
		},

		// ==============================================
		getChainLinkActions: function(chainLink) {
			var retArr = [];
			if (chainLink.tasksStr.length == 0) {
				return retArr;
			}
			var arrTargets = chainLink.tasksStr.split(",");
			arrTargets.forEach(target => {
				retArr.push(this.actionDeserialize(target, this.devices));
			});
			return retArr;
		},
		setChainLinkActions: function(chainLink, actions) {
			chainLink.tasksStr = actions.map(action => this.actionSerialize(action)).join(",");
		},
		removeChainLinkAction: function(action) {
			var index = this.chainLinkEdit.actions.indexOf(action);
			if (index !== -1) {
				this.chainLinkEdit.actions.splice(index, 1);
			}
		},
		// ==============================================

		onLinkDtPickerValidateClicked: function() {
			this.chainLinkEdit.lastSetTime = this.chainLinkEdit.privateTimeStr;
		},
		onLinkDtPickerHidden: function() {
			// Purpose: If the dt picker was closed, but NOT by clicking the validate button, its value is reset to the last set time. So that the time is updated only when the validate button is clicked.
			this.chainLinkEdit.privateTimeStr = this.chainLinkEdit.lastSetTime;
		},
		onDtPickerHidden: function() {
			// Purpose: If the dt picker was closed, but NOT by clicking the validate button, its value is reset to the last set time. So that the time is updated only when the validate button is clicked.
			this.dtpicker.privateTimeStr = this.dtpicker.lastSetTime;
		},
		onDtPickerValidateClicked: function() {
			this.dtpicker.lastSetTime = this.dtpicker.privateTimeStr;
		},
		sortLinks: function() {
			/* Sorts the links by delay. */
			this.job.chainLinks.sort((a, b) => a.startDelayStr < b.startDelayStr ? -1 : 1);
		},
		packArrays: function() {
			this.job.ifLinkStr = JSON.stringify(this.job.ifLink);
			this.job.chainLinksStr = JSON.stringify(this.job.chainLinks);
		},
		isRecurrenceValueValid: function(value) {
			var significantBits = 0;
			var str = value.toString(2).padStart(31, "0");
			var arr = [...str].reverse().map(x => parseInt(x)) ;
			switch(this.job.recurrenceType) {
				case 10:
					significantBits = 7;
					break;
				case 11:
					significantBits = 31;
					break;
				default:
					return value > 0;
			}
			var sum = 0;
			for (let i = 0; i < significantBits; i++) {
				sum += arr[i];
			}
			return sum > 0;
		},
		genActionKey: function(action) {
			// Generates a unique key (hopefully) for an action, by concateenating: action's domain [+] action deviceId OR actions's device's dbId (in case of actions targeting real devices) [+] action value
			// Issue: this isn't unique if the same action device and the same action value is used twice in the chain links
			// NOTE: The action key needs to be unique in ALL rendered chain links. A duplicate key, even in two different chain links, triggers the Vue warning "not distinct key".
			return action.domain + "_" + (action.domain == 0 ? action.device.dbId : action.deviceId) + action.value;
		},
		doSave: async function() {
			var isExecuting = await this.isJobExecuting(this.job.jobId);
			if (isExecuting) {
				alert(this.ML.trans('cannot_update_exec_prog'));			// Validate: is the job currently executing?
				return;
			}

			if (this.job.name.trim() == "") {								// Validate: Name
				alert(this.ML.trans('prog_name_must_not_empty'));
				return;
			}

			if (!this.isRecurrenceValueValid(this.job.recurrenceValue)) {	// Validate: Recurrence value
				switch(this.job.recurrenceType) {
					case 10:	// WeekDays
					case 11:	// MonthDays
						alert(this.ML.trans('no_day_checked'));
						return;
					case 9:		// Custom recurrence
						alert(this.ML.trans('rec_val_must_more_than_zero'));
						return;
				}
			}

			// If any currently chain link is in edit mode => save the current values in the editor
			if (this.job.ifLink.modeEdit) {
				this.setChainLinkActions(this.job.ifLink, this.chainLinkEdit.actions);
			}
			else {
				var editingLink = this.job.chainLinks.find(chainLink => chainLink.modeEdit);
				if (editingLink != null) {
					editingLink.startDelayStr = this.chainLinkEdit.privateTimeStr;
					this.setChainLinkActions(editingLink, this.chainLinkEdit.actions);
				}
			}

			this.packArrays();

			// Update the job due date-time as UTC date-time and considering the last selected time.
			this.job.dueDateTimeStr = this.utcDateTimeStrWithSetTimeStr(this.dtpicker.lastSetTime, this.goptions.currentuser.utcOffset, this.goptions.currentuser.isDST ? 60 : 0);

			this.$emit("askSave", this.job);
		},
		doCancel: function()   {
			this.$emit("askClose", {});
		},
		doDelete: async function() {
			var isExecuting = await this.isJobExecuting(this.job.jobId);
			if (isExecuting) {
				alert(this.ML.trans('cannot_delete_exec_prog'));			// Validare: job-ul este in executie
				return;
			}
			if (confirm(this.ML.trans('sure_del_prog') + " \"" + this.job.name + "?")) {
				this.$emit("askDelete", this.job);
			}
		}
	}
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
</style>