export const errorCodes = {
	data() {
		return {
			errorCodes: [
				{ code: 0, text: "Success", mlId: "" },
				{ code: 1, text: "No execution result", mlId: "" },
				{ code: 2, text: "Commend executor generic error", mlId: "" },
				{ code: 3, text: "Manadatory parameter missing", mlId: "" },
				{ code: 4, text: "Incorrect parameter value", mlId: "" },
				{ code: 5, text: "Invalid command", mlId: "" },
				{ code: 6, text: "The referred device is not currently mounted.", mlId: "" },
				{ code: 7, text: "Error upon command execution", mlId: "" },
				{ code: 8, text: "Unknown command", mlId: "" },
				{ code: 9, text: "No device specified", mlId: "" },
				{ code: 10, text: "A device with the same id is already mounted.", mlId: "" },
				{ code: 11, text: "Unknown device type", mlId: "" },
				{ code: 12, text: "Mounting parameters not valid", mlId: "" },
				{ code: 12, text: "Error while setting the device parameters", mlId: "" },
				{ code: 14, text: "Unknown command", mlId: "" },
				{ code: 15, text: "The command is inappropriate for this device.", mlId: "" }
			]
		}
	},
	methods: {
		getErrText: function(code) {
			var errEntry = this.errorCodes.find(err => err.code == code);
			return (errEntry == undefined ? "No description for the code " + code : errEntry.text);
		},
		getErrMlId: function(code) {
			var errEntry = this.errorCodes.find(err => err.code == code);
			return (errEntry == undefined ? "" + code : errEntry.mlId);
		}
	}
}
