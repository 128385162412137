<template>
	<div ref="editable" contenteditable inputmode="decimal" @blur="onBlur" @input="onInput"></div>
</template>

<script>
export default {
	name: "EditableDiv",
	props: {
		modelValue: {
			// type: String,
			default: ""
		},
		isNumber: {
			type: Boolean,
			default: false
		}
	},
	emits: ["update:modelValue"],
	data: function() {
		return {
			lastValid: ""
		};
	},
	watch: {
		"modelValue": function() {
			this.$refs.editable.innerText = this.modelValue;
		}
	},
	mounted() {
		this.$refs.editable.innerText = this.modelValue;
		this.lastValid = this.modelValue;
	},
	methods: {
		onBlur: function(e) {
			//console.log("blur triggered");
			var inputText = e.target.innerText;
			this.$emit('update:modelValue', this.isNumber ? (isNaN(inputText) ? 0 : Number(inputText)) : inputText);
		},
		onInput: function(e) {
			//console.log("input triggered", e);
			if (!this.isNumber) {
				return;		// Nothing to validate
			}
			// Check if it's a valid number.
			if (["-", "+"].includes(this.$refs.editable.innerText)) {
				this.lastValid = this.$refs.editable.innerText;
			}
			else if (isNaN(this.$refs.editable.innerText)) {
				this.$refs.editable.innerText = this.lastValid;
			}
			else if (this.$refs.editable.innerText.trim() != this.$refs.editable.innerText) {
				this.$refs.editable.innerText = this.lastValid;
			}
			else {
				this.lastValid = this.$refs.editable.innerText;
			}
		}
	}
};
</script>
