<template>
	<div>
		<div class="columns is-mobile is-vcentered">
			<div class="column has-text-weight-bold is-size-4-mobile is-size-3-tablet brand-color">
				<span>{{ ML.trans('dashboard') }}</span>
				<span class="is-size-6 ls-disable-text-select ls-is-vam" v-if="timer.enabled" v-html="timerProgressHtml()" style="cursor: pointer; float: right;" :title="'Auto-refresh ' + (timer.instance != null ? 'On' : 'Off')" @click="toggleAutoRefresh()"></span>
				<span v-if="!timer.refreshSuccessful" class="is-size-6 ls-disable-text-select ls-is-vam" style="color: var(--my-error-color); float: right;">X</span>
			</div>
			<div class="column has-text-right">
				<div class="button is-primary" @click="refresh()" :title="ML.trans('refresh')">
					<img
						class="ls-is-vam"
						alt="Refresh"
						src="../assets/images/refresh.svg"
						style="width: 20px"
					/>
					<!-- <span class="spaceleft">{{ ML.trans('refresh') }}</span> -->
				</div>
			</div>
		</div>
		
		<div class="columns">
			<div class="column">
				<!-- Jobs -->
				<div class="container block">
					<div class="box">
						<div class="columns is-centered is-variable">
							<div class="column has-text-centered ls-happy-title">
								<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('controllers') }}</span>
							</div>
						</div>


						<div class="columns is-multiline is-variable">
							<div class="column has-text-centered m-3 chain-link-view" v-for="controller in controllers">
								<div class="columns">
									<div class="column ls-is-label ls-no-wrap">
										{{ controller.name }}
									</div>
								</div>

								<div class="columns is-mobile">
									<!-- <div class="column has-text-right-tablet has-text-centered-mobile ls-no-wrap">
										<span class="ls-is-label">{{ ML.trans('active') }}: </span>
										<div class="ls-circle ls-is-vam" :style="{'border-color': controller.isActive ? '#00aa00' : '#ff0000' }"></div>
									</div> -->
									<div class="column has-text-right ls-no-wrap">
										<span class="ls-is-vam ls-is-label">{{ ML.trans('online') }}: </span>
										<!-- <span class="ls-is-vam">???</span> -->
										<div class="ls-circle ls-is-vam" :style="{'border-color': getControllerStateColor(controller)}"></div>
									</div>
									<div class="column has-text-left ls-no-wrap">
										<span class="ls-is-vam ls-is-label">WiFi: </span>
										<!-- <span class="ls-is-vam">???</span> -->
										<SignalGauge :dataAvailable="controller.info != null" :strength="controller.info != null ? controller.info.signalStrength : 0" class="ls-is-vam" style="height: 15px; width: 30px;" />
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			</div>



			<div class="column">
				<!-- Jobs -->
				<div class="container block">
					<div class="box">
						<div class="columns is-centered is-variable">
							<div class="column has-text-centered ls-happy-title">
								<span class="is-size-5-tablet is-size-6-mobile">{{ ML.trans('scheduled_programs') }}</span>
							</div>
						</div>

						<div class="columns is-centered" v-for="(job, index) in jobManagerJobs" :key="job.jobId" :class="{'mt-5': index > 0, 'mt-3': index == 0}">
							<!-- <hr v-if="index > 0"> -->

							<!-- Job -->
							<div class="column has-text-centered">
								<!-- Job contents -->
								<div class="columns is-mobile has-text-weight-bold has-text-primary is-vcentered">
									<div class="column has-text-right">
										<!-- Job date -->
										{{ [2,3].includes(jobManagerState) ? reformatIsoDateString(job.displayDueDateTimeStr, goptions.localSettings.dateFormat, "-", false) : "n/a" }}
									</div>
									<div class="column has-text-left is-size-5-tablet is-size-6-mobile">
										<!-- Job name -->
										{{ job.name }}
									</div>
								</div>

								<div class="columns">
									<div class="column">
										<!-- Done links -->
										<div class="columns is-mobile" v-for="link in job.doneLinks">
											<div class="column has-text-right">
												<img src="@/assets/images/checked.svg" style="width: 20px; vertical-align: middle;" alt="Done" title="Done" />
												{{ timeStringFormat(dateToIsoStr(dateAddTimeSpanStr(job.displayDueDateTimeStr, link.startDelayStr), "DMY", "T", "-"), "HM")}}
												<span v-if="isChainLinkNextDay(link, job)"><br>(Next day)</span>
											</div>
											<div class="column has-text-left">
												<!-- Link actions -->
												<div v-for="(actionLabel, index2) in link.actionLabels">
													<!-- <br v-if="index > 0"> -->
													{{ actionLabel }}
													<br v-if="index2 > 0">
												</div>
											</div>
										</div>

										<!-- Executing links -->
										<div class="columns is-mobile" v-for="link in job.executingLinks">
											<div class="column has-text-right">
												<img src="@/assets/images/wait-hourglass-yellow.svg" v-if="job.doneLinks.length > 0" style="width: 20px; vertical-align: middle;" alt="Done" title="Done" />
												{{ timeStringFormat(dateToIsoStr(dateAddTimeSpanStr(job.displayDueDateTimeStr, link.startDelayStr), "DMY", "T", "-"), "HM")}}
												<span v-if="isChainLinkNextDay(link, job)"><br>(Next day)</span>
											</div>
											<div class="column has-text-left">
												<!-- Link actions -->
												<div v-for="(actionLabel, index2) in link.actionLabels">
													<!-- <br v-if="index > 0"> -->
													{{ actionLabel }}
													<br v-if="index2 > 0">
												</div>
												<div v-if="link.actionLabels.length == 0" style="color: #ff5555">
													({{ ML.trans('warning_no_action') }})
												</div>
											</div>
										</div>
										
									</div>
								</div>

								<!-- Cancel job button -->
								<div v-if="jobIsExecuting(job) && hasMinRights(2)" class="button is-danger is-small" @click="cancelJobExecution(job)">{{ ML.trans('cancel_execution') }}</div>

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex';
import { datesLib } from "@/components/mixins/datesLib";
import SignalGauge from "@/components/controls/SignalGauge.vue"

export default {
	name: 'Dashboard',
	mixins: [datesLib],
	components: {SignalGauge},
	data: function() {
		return {
			jobManagerJobs: [],
			jobManagerSettings: {},
			jobManagerState: 0,
			controllers: [],
			devices: [],
			timer: {
				enabled: false,
				instance: null,
				intervalTicks: 10,
				tick: 0,
				dots: 10,
				refreshSuccessful: true
			}
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		timerTicks: {
			set: async function(value) {
				this.timer.tick = value;
				if (this.timer.tick > this.timer.intervalTicks) {
					this.timer.tick = 0;
					var values = await this.initialize("timer");
					this.preProcess(values);
				}
			},
			get: function() {
				return this.timer.tick;
			}
		}
	},
	watch: {
	},
	created: async function() {
		var initValues = await this.initialize();
		this.preProcess(initValues);
		if (this.timer.enabled) {
			this.timerStart();
		}
	},
	beforeUnmount: function() {
		if (this.timer.instance != null) {
			this.timerStop();
		}
	},
	methods: {
		initialize: async function(mode) {
			var retObj = {jobs: [], info: {}};
			var values = null;
			try {
				//values = await this.executeAll();
				values = await Promise.all([
					this.getJobManagerJobs(),
					this.getJobManagerInfo(),
					this.getUserControllers(),
					this.getUserDevices()
				]);
				this.timer.refreshSuccessful = true;
			}
			catch(err) {
				if (mode == "timer") {
					this.timer.refreshSuccessful = false;
				}
				else {
					console.error("Failded to call entitied functions:\n" + err.message);
				}
				return retObj;
			}
			retObj.jobs = values[0];
			retObj.info = values[1];
			retObj.controllers = values[2];
			retObj.devices = values[3];

			return retObj;
		},
		preProcess: function(initValues) {
			this.jobManagerSettings = initValues.info.settings;
			this.jobManagerState = initValues.info.state;
			this.controllers = initValues.controllers.value.sort((a, b) =>  a.name < b.name ? -1 : 1 );;
			this.devices = initValues.devices;

			// Sort jobs by due date
			this.jobManagerJobs = initValues.jobs.sort((a, b) =>  a.dueDateTimeStr < b.dueDateTimeStr ? -1 : 1 );
			// Add job display date time mamber
			this.jobManagerJobs.forEach(job => {
				job.displayDueDateTimeStr = this.localDateTimeStrFromUtcDateStr(job.dueDateTimeStr, this.goptions.currentuser.utcOffset, this.goptions.currentuser.isDST ? 60 : 0);
			});
			// Sort executing links by time and populate actions
			this.jobManagerJobs.forEach(job => {
				job.executingLinks.sort((a, b) => a.startDelayStr < b.startDelayStr ? -1 : 1);
				// Populate chain links with actions
				job.executingLinks.forEach(chainLink => {
					this.populateChainLinkActionLabels(job, chainLink);
				});
			});
			// Sort done links by time and populate actions
			this.jobManagerJobs.forEach(job => {
				job.doneLinks.sort((a, b) => a.startDelayStr < b.startDelayStr ? -1 : 1);
				// Populate chain links with actions
				job.doneLinks.forEach(chainLink => {
					this.populateChainLinkActionLabels(job, chainLink);
				});
			});
			console.log("this.jobManagerJobs", this.jobManagerJobs);
			//console.log("this.controllers", this.controllers);
			this.controllers.forEach(controller => {
				this.updateControllerState(controller);
			});
		},
		refresh: async function() {
			var initValues = await this.initialize();
			this.preProcess(initValues);
		},
		isChainLinkNextDay: function(chainLink, parentJob) {
			var refDate = this.reformatIsoDateString(parentJob.displayDueDateTimeStr, "YMD", "-", false);
			var execDateTime = this.dateAddTimeSpanStr(parentJob.displayDueDateTimeStr, chainLink.startDelayStr);
			var execDateStr = this.dateToIsoStr(execDateTime, "YMD", "D", "-");
			return (execDateStr > refDate);
		},
		populateChainLinkActionLabels: function(job, chainLink) {
			chainLink["actionLabels"] = [];
			if (chainLink.tasksStr.length == 0) {
				return;
			}
			var actionsStrArr = chainLink.tasksStr.split(",");
			actionsStrArr.forEach(actionStr => {
				var at = this.actionText(this.actionDeserialize(actionStr, this.devices));
				chainLink["actionLabels"].push(at);
			});
		},
		timerProgressHtml: function() {
			if (!this.timer.enabled) {
				return ".".repeat(this.timer.dots);
			}
			var done = this.timer.tick / this.timer.intervalTicks * this.timer.dots;
			var remain = this.timer.dots - done;
			var retHtml = "";
			retHtml += "<span style='color: var(--my-brand-color);'>" + "*".repeat(done) + "</span>";
			retHtml += "<span style='color: var(--my-prime-color);'>" + "*".repeat(remain) + "</span>";
			return retHtml;
		},
		timerStart: function() {
			if (this.timer.instance != null) {
				return;
			}
			var that = this;
			this.timer.instance = setInterval(function () {
				that.timerTicks++;
			}, 1000);
		},
		timerStop: function() {
			if (this.timer.instance == null) {
				return;
			}
			clearInterval(this.timer.instance);
			this.timer.instance = null;
		},
		toggleAutoRefresh: function() {
			if (this.timer.instance != null) {
				this.timerStop();
				this.timerTicks = 0;
			}
			else {
				this.timerStart();
			}
		},
		jobIsExecuting: function(job) {
			return (this.dateToIsoStr(this.getUTCNowDateTime(), "YMD") > job.dueDateTimeStr);
		},
		cancelJobExecution: function(job) {
			this.showWait(true);
			this.$ky.get(this.apiUrl + "/CancelExecutingJob", {
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
					jobId: job.jobId,
				}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				this.refresh();
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
			.finally(() => {
				this.showWait(false);
			});
		}
	}
}
</script>

<style>
</style>