<template>
	<div>
		<nav
			class="navbar is-fixed-top is-transparent"
			role="navigation"
			aria-label="main navigation"
		>
			<div class="navbar-brand">
				<router-link :to="logoLinkTo" class="navbar-item" @click.native="closeMobileMenu()">
					<img src="./assets/images/a-logo.png" class="a-logo-img"/>
					<span class="title is-3 brand-color">ptis<span v-if="goptions.isUserAdmin"> (admin)</span></span>
				</router-link>
				<div class="navbar-item" v-if="goptions.serviceWorkerState.deferredInstallPrompt != null">
					<span class="in-app-installation" @click="installApp()">Install</span>
				</div>

				<a v-if="goptions.currentuser != null"
					role="button"
					class="navbar-burger"
					aria-label="menu"
					aria-expanded="false"
					data-target="navMenu"
					@click.prevent="switchMobileMenuVisibility()"
					v-bind:class="{'is-active': mobileMenuVisible}"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>

			</div>

			<div id="navMenu" class="navbar-menu" v-bind:class="{'is-active': mobileMenuVisible}">
				<div class="navbar-start" v-if="(authAuthenticated()) && !goptions.isUserAdmin">

					<router-link :to="{name: 'Dashboard', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('dashboard') }}</router-link>
					<router-link :to="{name: 'Controllers', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('controllers') }}</router-link>
					<router-link :to="{name: 'Programs', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('programs') }}</router-link>
					<span style="width: 40px;"></span>
					<!-- <router-link :to="{name: 'Map', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('map') }}</router-link> -->
					<router-link v-if="this.hasWeather" :to="{name: 'Weather', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('weather') }}</router-link>
					<router-link :to="{name: 'Log', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('log') }}</router-link>
					<router-link :to="{name: 'Settings', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">{{ ML.trans('settings') }}</router-link>

				</div>

				<div class="navbar-start" v-if="this.goptions.isUserAdmin">
					<router-link :to="{name: 'Users', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Users</router-link>
					<router-link :to="{name: 'CommandConsole', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Command console</router-link>
					<router-link :to="{name: 'FactorySetup', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Factory setup</router-link>
					<router-link :to="{name: 'Server', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Server</router-link>
					<router-link :to="{name: 'PushSubscriptions', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Push</router-link>
				</div>

				<div class="navbar-end">
					<div class="navbar-item" v-if="(authAuthenticated()) && !goptions.isUserAdmin">
						<a class="navbar-item" @click.prevent="logout()">{{ ML.trans('sign_out') }}</a>
						<!-- <div class="buttons">
							<a class="button is-primary">
								<strong>Sign up</strong>
							</a>
							<a class="button is-light">Log in</a>
						</div> -->
					</div>
					<div class="navbar-item" v-if="goptions.isUserAdmin">
						<router-link :to="{name: 'Test', query: { 'nav': true }}" class="navbar-item" @click.native="closeMobileMenu()">Test</router-link>
					</div>

					<div class="navbar-item">
						<img v-if="themeIsDark" class="ls-menu-icon" @click="toggleTheme(false)" src="./assets/images/sun.svg" />
						<img v-if="!themeIsDark" class="ls-menu-icon" @click="toggleTheme(true)" src="./assets/images/moon.svg" />
					</div>

					<div v-if="isCurrentUserSysAdmin()" class="navbar-item has-dropdown is-hoverable">
						<a class="navbar-link">Switch</a>
						<div class="navbar-dropdown is-boxed">
							<a class="navbar-item" @click.prevent="dev_impersonate('admin')">Admin</a>
							<a class="navbar-item" @click.prevent="dev_impersonate('user')">User ({{ currentUserName }})</a>
							<hr class="navbar-divider" />
							<a class="navbar-item" @click.prevent="toggleTheme(false)">Light</a>
							<a class="navbar-item" @click.prevent="toggleTheme(true)">Dark</a>
						</div>
					</div>

					<!-- <div class="navbar-item has-dropdown is-hoverable">
						<a class="navbar-link">Dev</a>
						<div class="navbar-dropdown is-boxed">
							<a class="navbar-item" @click.prevent="dev_test()">Test method</a>
						</div>
					</div> -->

				</div>


			</div>
		</nav>

		<!-- <section class="hero is-link is-fullheight-with-navbar">
			<div class="hero-body">
				<router-view />
			</div>
		</section> -->

		<div class="mainArea has-text-left">
			<router-view />
		</div>


		<!-- "Wait" popup -->
		<!-- NOTE: For such popup, it's important that the isInteractive be set to false. -->
		<ModalPopupFade :isInteractive="false" columns="3" transDuration="0s" popTitle="" popupLayoutClass="popupWaitLayout" backcolorOverlay="#00000000" :visible="goptions.waitPopupVisible" :keepLayoutOnMobile="true">
			<div style="text-align: center; font-weight: bold;">{{ ML.trans('wait') }}...</div>
			<progress class="progress is-small is-primary mt-4" max="100"></progress>
		</ModalPopupFade>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { themes } from "@/components/mixins/themes";

export default {
	name: "app",
	mixins: [themes],
	data: function() {
		return {
			mobileMenuVisible: false
		}
	},
	created: async function() {
		await this.initialNavigation();

		// [PWA] Service worker registration
		// For development purposes, consider localhost
		if ('serviceWorker' in navigator) {

				window.addEventListener('load', () => {
					var serviceWorkerFile = (process.env.NODE_ENV != 'development' ? process.env.BASE_URL + "sw.js" : "service-worker-events.js");
					console.log("%c[SW] serviceWorkerFile: " + serviceWorkerFile, "color: magenta");

					navigator.serviceWorker.addEventListener('controllerchange', () => {
						console.log("%c[SW] Hmmm, we are operating under a new service worker", "color: magenta");
						that.goptions.serviceWorkerState.newSwAvailable = true;
					});

					var that = this;
					navigator.serviceWorker.ready.then(registration => {
						registration.update().then(() => {
							console.log("%c[SW] Service worker is active", "color: magenta");
						});
					});

					navigator.serviceWorker.register(serviceWorkerFile)
						.then(registration => {
							console.log('%c[SW] Service Worker is registered', "color: magenta", registration);
						})
						.catch(err => {
							console.error('[SW] Registration failed:', err);
						});

				});

				// Store the installation prompt for later use - in order to install the PWA as Desktop app from within the application itself.
				// NOTE: in case the application is already installed, the event beforeinstallprompt isn't triggered.
				// Once the event argument is stored (in this case in VueX), it can be used to prompt user for installation with e.prompt() .
				var that = this;
				window.addEventListener('beforeinstallprompt', (e) => {
					// Prevent Chrome 67 and earlier from automatically showing the prompt
					e.preventDefault();
					// Stash the event so it can be triggered later.
					that.goptions.serviceWorkerState.deferredInstallPrompt = e;
					console.log("%c[SW] Stashed the deferred prompt", "color: magenta");
				});
				
		}
		else {
			console.log("Service worker support not available");
		}


		// Add event listener window active
		window.addEventListener("focus", this.onWindowActive);

		// Add event listener for the transition from "uninstalled" PWA to "installed" application.
		// When the application is installed, this will hide the "install application" button from the UI.
		// This serves in case the user installs the application from the browser specific control (i.e.
		// small icon in the navnar, in Chrome, for example).
		// If "in app" installation is used (by clicking the "install" button in the UI), another code is
		// used - see installApp() method). that hides explicitely the button.
		var that = this;
		window
			.matchMedia('(display-mode: standalone)')
			.addEventListener('change', ({ matches }) => {
				if (matches) {
					that.goptions.serviceWorkerState.deferredInstallPrompt = null
				}
			});

		// Add navigation guards
		this.$router.beforeEach((to, from, next) => {
			// [PWA]
			// If no connection => Display specific "no connection" page.
			// NOTE: The "no connection" page needs to be pre-cached, and specific code needs to be
			// added in the service worker in oeder to serve from cache if the default fetch fails.
			if (!navigator.onLine) {
				window.location = "./noConnection.html";
				return;
			}

			if (to.query.nav == null) {
				if (this.mobileMenuVisible) {
					this.mobileMenuVisible = false;
					next(false);
					return;
				}
			}

			if (to.matched.some(record => record.meta.isAdminMenu)) {
				// Admin menu items have no navigation limits (always able to navigate to).
				next();
			}
			else {
				if (!this.authAuthenticated()) {
					if (to.name == "Auth") {
						next();
					}
					else if (to.name == "UserRegistration") {
						next();
					}
					else {
						next({ name: "Auth" });
					}
				}
				else {
					next();
				}
			}
		})

		// Setup
		// =====
		this.setThemeValues(this.goptions.localSettings.darkMode ? "dark" : "light");
		this.ML.translationOptions.defaultLanguage = this.goptions.localSettings.language;

	},
	destroyed: function() {
		window.removeEventListener("focus", this.onWindowActive);
	},
	onWindowActive: function() {
		// Redirect to login page if not logged in
		// if (!this.goptions.validLogin) {
		// 	if (this.$router.currentRoute.name != "login") {
		// 		this.$router.push({ name: "login" });
		// 	}
		// }

		console.log("Back in business");

		// Play sound
		const sound = new Audio(require("@/assets/sounds/PositiveDame.mp3"));
		console.log("Play sound...");
		sound.play();
	},
	mounted: function() {
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		logoLinkTo: function() {	// Where to navigate when clicking on the logo
			return (this.authAuthenticated() ? "/home" : "/auth");
		},
		currentUserName: function() {
			return this.goptions.currentuser != null ? this.goptions.currentuser.userName : 'none';
		}
	},
	watch: {
	},
	methods: {
		initialNavigation: async function() {
			if (this.goptions.currentuser == null) {
				if (this.$router.currentRoute.value.name != "Auth") {
					this.$router.push({ name: "Auth", query: { 'nav': true } });
				}
			}
			else {
				if (!this.goptions.localSettings.keepMeSigned) {
					if (this.$router.currentRoute.value.path == "/") {
						this.$router.push({ name: "Auth", query: { 'nav': true } });
					}
				}
			}
		},
		installApp: async function() {
			//alert("[TBD] Install");
			if (this.goptions.serviceWorkerState.deferredInstallPrompt !== null) {
				this.goptions.serviceWorkerState.deferredInstallPrompt.prompt();
				const { outcome } = await this.goptions.serviceWorkerState.deferredInstallPrompt.userChoice;
				if (outcome === 'accepted') {
					this.goptions.serviceWorkerState.deferredInstallPrompt = null;
				}
			}
		},
		dev_impersonate: function(who) {
			switch(who) {
				case "admin":
					this.goptions.impersLastRouteUser = this.$router.currentRoute.value.name;	// Save the route to come back at the same page when switching to user
					this.goptions.isUserAdmin = true;
					let newRoute = (this.goptions.impersLastRouteAdmin == "" ? "Users" : this.goptions.impersLastRouteAdmin);
					if (newRoute != this.$router.currentRoute.value.name) {
						this.closeMobileMenu();
						this.$router.push({ name: newRoute, query: { 'nav': true } });
					}
					break;
				case "user":
					this.goptions.impersLastRouteAdmin = this.$router.currentRoute.value.name;	// Save the route to come back at the same page when switching to user
					this.goptions.isUserAdmin = false;
					if (this.goptions.impersLastRouteUser != this.$router.currentRoute.value.name) {
						this.closeMobileMenu();
						this.$router.push({ name: this.goptions.impersLastRouteUser, query: { 'nav': true } }).catch(() => {});
					}
					break;
			}
		},
		dev_test: function(show) {
			alert("Nothing here...");
		},
		logout: function() {
			this.closeMobileMenu();
			this.$router.push({ name: "Auth", query: { 'nav': true } });
		},
		switchMobileMenuVisibility: function() {
			if (this.goptions.bulmaMobileMenu) {
				this.mobileMenuVisible = !this.mobileMenuVisible;
			}
			else {
				if (this.$router.currentRoute.value.name != "Home") {
					this.$router.push({ name: "Home", query: { 'nav': true } });
				}
			}
			return;
		},
		openMobileMenu: function() {
			this.mobileMenuVisible = true;
		},
		closeMobileMenu: function() {
			this.mobileMenuVisible = false;
		},
		logoSvg: function() {
			return require("./assets/images/A_inkscape.svg");
		},
		toggleTheme: function(setDark) {
			this.themeIsDark = setDark;
			this.saveLocalSettings();
			this.closeMobileMenu();
		}
	}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  //padding: 30px;

  a {
    font-weight: normal;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
