export const themes = {
	data() {
		return {
			themeValues: {
				light: {
					"--my-prime-color": "rgb(75, 75, 75)",	// "rgb(37, 128, 105)"		rgb(50, 50, 50)
					"--my-contrast-color": "#eeeeee",
					"--my-body-back-color": "#fafafa",
					"--my-body-text-color": "rgb(48, 48, 48)",
					"--my-body-text-color2": "#727272",
					"--my-body-text-color3": "#909090",
					"--my-menu-back-color": "#e7e7e7",
					"--my-a-logo": "hue-rotate(178deg) saturate(150%)",
					"--my-box-back-color": "hsl(0, 0%, 100%)",
					"--my-brand-color": "rgb(50, 170, 140)",	// The "brand" color - like a special turquoise etc.

					"--my-link-color": "#206655",
					"--my-link-hover-color": "#111111",

					"--my-input-color": "#rgb(48, 48, 48)",
					"--my-input-back-color": "#ffffff",

					"--my-wait-popup-back-color": "#ffffff",
					"--my-wait-popup-fore-color": "#111111",

					"--my-navbar-dropdown-back-color": "#ffffff",
					"--my-navbar-dropdown-item-hover-color": "#222222",
					"--my-navbar-divider-background-color": "#cccccc",

					"--my-table-color": "rgb(50, 50, 50)",
					"--my-table-back-color": "rgb(255, 255, 255)",
					"--my-table-row-active-back-color": "rgb(220, 220, 220)",

					"--my-happy-title-fore-color": "rgb(50, 50, 50)",	// Section title
					"--my-happy-title-back-color": "rgb(150, 255, 220)",
					"--my-happy-title-border-color": "rgb(220, 0, 0)",

					"--my-devlistitem-back-color": "#fafafa",
					"--my-devlistitem-border-color": "#00aa99",

					"--my-error-color": "#ff5555"
				},
				dark: {
					"--my-prime-color": "rgb(220, 220, 220)",	// "rgb(50, 170, 140)"
					"--my-contrast-color": "#333333",
					"--my-body-back-color": "#222222",
					"--my-body-text-color": "rgb(220, 220, 220)",
					"--my-body-text-color2": "rgb(100,100,100)",
					"--my-body-text-color3": "rgb(150,150,150)",
					"--my-menu-back-color": "#333333",
					"--my-a-logo": "hue-rotate(178deg) saturate(400%)",
					"--my-box-back-color": "hsl(0, 0%, 25%)",
					"--my-brand-color": "rgb(50, 170, 140)",	// The "brand" color - like a special turquoise etc.

					"--my-link-color": "#00cccc",
					"--my-link-hover-color": "#00eeee",

					"--my-input-color": "#eeeeee",
					"--my-input-back-color": "#666666",

					"--my-wait-popup-back-color": "#444444ff",
					"--my-wait-popup-fore-color": "#aaaaaa",

					"--my-navbar-dropdown-back-color": "#333333",
					"--my-navbar-dropdown-item-hover-color": "#33eeee",
					"--my-navbar-divider-background-color": "#666666",

					"--my-table-color": "rgb(210, 210, 210)",
					"--my-table-back-color": "rgb(80, 80, 80)",
					"--my-table-row-active-back-color": "rgb(65, 65, 65)",

					"--my-happy-title-fore-color": "rgb(225, 255, 255)",	// Section title
					"--my-happy-title-back-color": "rgb(30, 100, 90)",
					"--my-happy-title-border": "rgb(220, 0, 0)",

					"--my-devlistitem-back-color": "#555555",
					"--my-devlistitem-border-color": "#00aa99",

					"--my-error-color": "#ff5555"
				}
			}
		}
	},
	computed: {
		themeIsDark: {
			get: function() {
				return this.goptions.localSettings.darkMode;
			},
			set: function(newValue) {
				this.goptions.localSettings.darkMode = newValue;
				if (this.goptions.localSettings.darkMode) {
					this.setThemeValues("dark");
				}
				else {
					this.setThemeValues("light");
				}
			}
		}
	},
	methods: {
		getThemeAttrValue: function(themeName, attrName) {
			return this.themeValues[themeName][attrName];
		},
		setThemeValues: function(themeName) {
			Object.keys(this.themeValues[themeName]).forEach(key => {
				document.documentElement.style.setProperty(key, this.themeValues[themeName][key]);
			});
		},
		switchTheme: function() {
			this.goptions.localSettings.darkMode = !this.goptions.localSettings.darkMode;
		}
	}
}