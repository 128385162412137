<template>
	<div>
		<!--
			type == 1 => Rectangular switch
			type == 2 => Rounded switch
		-->

		<label class="switch">
			<input type="checkbox" v-model="isChecked" :disabled="!enabled" @change="doOnChange">
			<span class="slider" v-bind:class="{round: type == 2}" :style="{'cursor': enabled ? 'pointer' : 'not-allowed'}"></span>
		</label>
	</div>
</template>

<script>
	export default {
		name: "FunToggleSwitch",
		//props: ["type", "state", "on_state_val", "off_state_val", "refObj"],
		props: {
			modelValue: {
				type: Number
			},
			type: {
				type: Number,
				default: 1
			},
			on_state_val: {
				type: Number
			},
			off_state_val: {
				type: Number
			},
			refObj: {
				type: Object
			},
			enabled: {
				type: Boolean,
				default: true
			}
		},
		emits: ["update:modelValue", "hasChenged"],
		data() {
			return {
				isChecked: false
			}
		},
		created: function() {
			this.isChecked = (this.modelValue == this.on_state_val);
		},
		watch: {
			modelValue: function() {
				this.isChecked = (this.modelValue == this.on_state_val);
			}
		},
		methods: {
			doOnChange: function(ev) {
				this.$emit("update:modelValue", this.isChecked ? this.on_state_val : this.off_state_val);
				this.$emit("hasChenged", this.refObj);
			}
		}
	}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #eb9898;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 17px;
	width: 17px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #4caf50;
}

input:focus + .slider {
	box-shadow: 0 0 1px #4caf50;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>