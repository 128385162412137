<template>
	<!-- Inspiration: https://blog.logrocket.com/using-svg-and-vue-js-a-complete-guide/ -->
	
	<svg width="102.61mm" height="52.49mm" version="1.1" viewBox="0 0 102.61 52.49" xmlns="http://www.w3.org/2000/svg">
		<title v-text="'  ' + strength + '  '"></title>
		<g transform="translate(-40.009 -69.911)" fill-rule="evenodd" stroke="#000" stroke-width=".94488">
			<path transform="scale(.26458)" d="m223.02 425.69-71.592 36.457h71.592z" :fill="getColor(1)"/>
			<path transform="scale(.26458)" d="m284.45 394.4-43.021 21.908v45.83h43.021z" :fill="getColor(2)"/>
			<path transform="scale(.26458)" d="m350.16 360.94-47.305 24.088v77.111h47.305z" :fill="getColor(3)"/>
			<path transform="scale(.26458)" d="m411.59 329.66-43.02 21.906v110.57h43.02z" :fill="getColor(4)"/>
			<path transform="scale(.26458)" d="m473.02 298.38-43.02 21.906v141.86h43.02z" :fill="getColor(5)"/>
			<path transform="scale(.26458)" d="m538.57 265-47.145 24.008v173.13h47.145z" :fill="getColor(6)"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: "SignalGauge",
	props: {
		dataAvailable: {
			type: Boolean,
			default: false
		},
		strength: {
			type: Number,
			default: 50
		}
	},
	data: function() {
		return {
			lines: 3
		}
	},
	created: function() {
		this.calculateLines();
	},
	watch: {
		strength: function() {
			this.calculateLines();
		}
	},
	methods: {
		calculateLines: function() {
			this.lines = Math.round(1.0 * 6 * this.strength / 100);
		},
		getColor: function(barIndex) {
			if (this.dataAvailable) {
				return (barIndex <= this.lines ? "#0a0" : "#f00");
			}
			else {
				return "#888";
			}
		}
	}
}
</script>

<style>
</style>