<template>
	<div>
		<div class="title is-size-4-mobile is-size-3-tablet brand-color">{{ job.jobId != 0 ? ML.trans('edit_program') : ML.trans('new_program') }}</div>

		<ProgramEdit v-if="programEditorVisible" :targetJob="job" :refControllers="controllers" :refDevices="devices" @askClose="editJobCancel()" @askSave="editJobSave" @askDelete="editJobDelete"></ProgramEdit>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import ProgramEdit from "@/components/ProgramEdit.vue"

export default {
	name: 'ProgramPageEdit',
	components: {ProgramEdit},
	mixins: [],
	data: function() {
		return {
			job: {},
			controllers: [],
			devices: [],
			programEditorVisible: false
		}
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	watch: {
	},
	created: function() {
		this.showWait(true);

		var editJobId = this.$route.query.jobId;
		this.$ky.get(this.apiUrl + "/GetJob", {
			searchParams: {jobId: editJobId}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
		.json()
		.then((responseJson) => {
			this.job = responseJson;
			if (editJobId == 0) {
				this.job.userId = this.goptions.currentuser.userId;
			}

			this.isJobExecuting(editJobId)
			.then((isExecuting) => {
				this.job.isExecuting = isExecuting;

				this.getUserDevices()
				.then(responsedevices => {
					this.devices = responsedevices;
					console.log("Devices:", this.devices);

					this.getUserControllers()
					.then(responseControllers => {
						this.controllers = responseControllers.value;
						this.programEditorVisible = true;
					})
				});
			});
		})
		.catch((err) => {
			alert("Error: " + err.message);
		});


	},
	methods: {
		editJobCancel: function() {
			this.$router.push({ name: "Programs", query: { 'nav': true } });
		},
		editJobSave: function(job) {
			// Save the job
			this.$ky.post(this.apiUrl + "/SaveJob", {
				json: job, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					// Go back to the jobs page
					this.$router.push({ name: "Programs", query: { 'nav': true } });
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		},
		editJobDelete: function(job) {
			this.$ky.get(this.apiUrl + "/DeleteJob", {
				searchParams: {jobId: job.jobId}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
			.then((responseJson) => {
				if (responseJson.success) {
					// Go back to the jobs page
					this.$router.push({ name: "Programs", query: { 'nav': true } });
				}
				else {
					alert(this.ML.trans('exec_error') + ": " + responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			})
		}
	}
}
</script>

<style>
</style>