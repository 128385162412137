const MultiLanguage = {
	state: {
		translations: {
			translationOptions: {
				defaultLanguage: "ro",
				defaultText: "???"
			},
			items: {
				notdef: {ro: "! nedefinit !", en: "! not defined !", fr: "! pas défini !"},
				language: {ro: "Limba", en: "Language", fr: "Langue"},
				sections: {ro: "Secțiuni", en: "Sections", fr: "Sections"},
				dashboard: {ro: "Tablou de bord", en: "Dashboard", fr: "Tableau de bord"},
				controller: {ro: "Controler", en: "Controller", fr: "Contrôleur"},
				controllers: {ro: "Controlere", en: "Controllers", fr: "Contrôleurs"},
				actions: {ro: "Acțiuni", en: "Actions", fr: "Actions"},
				action_name: {ro: "Nume acțiune", en: "Action name", fr: "Nom action"},
				action_type: {ro: "Tip acțiune", en: "Action type", fr: "Type action"},
				add_action: {ro: "Adaugă acțiune", en: "Add action", fr: "Ajouter action"},
				add_action_tit: {ro: "Adăugare acțiune", en: "Add action", fr: "Ajout action"},
				edit_action_tit: {ro: "Editare acțiune", en: "Edit action", fr: "Edition action"},
				add_actions: {ro: "Adaugă acțiuni", en: "Add actions", fr: "Ajouter actions"},
				edit_action_tit: {ro: "Editare acțiune", en: "Edit action", fr: "Edition action"},
				add_condition: {ro: "Adaugă condiție", en: "Add condition", fr: "Ajouter condition"},
				add_weather: {ro: "Adaugă condiție meteo", en: "Add weather condition", fr: "Ajouter météo condition"},
				add_weather_tit: {ro: "Adăugare condiție meteo", en: "Add weather condition", fr: "Ajout météo condition"},
				edit_weather_tit: {ro: "Editare condiție meteo", en: "Edit weather condition", fr: "Edition météo condition"},
				programs: {ro: "Programe", en: "Programs", fr: "Programmes"},
				add_program: {ro: "Adaugă program", en: "Add program", fr: "Ajouter programme"},
				log: {ro: "Jurnal", en: "Log", fr: "Journal"},
				settings: {ro: "Setări", en: "Settings", fr: "Personnalisation"},
				map: {ro: "Hartă", en: "Map", fr: "Carte"},
				sign_out: {ro: "Deconectare", en: "Sign out", fr: "Se déconnecter"},
				action_execution: {ro: "Execuție actiuni", en: "Action execution", fr: "Execution actions"},
				wait: {ro: "Asteapta", en: "Wait", fr: "Attendre"},

				ok: {ro: "OK", en: "OK", fr: "OK"},
				cancel: {ro: "Renunță", en: "Cancel", fr: "Annuler"},
				delete: {ro: "Șterge", en: "Delete", fr: "Supprimer"},
				re_execute: {ro: "Re-execută", en: "Re-execute", fr: "Re-executer"},
				close: {ro: "Închide", en: "Close", fr: "Fermer"},
				finish: {ro: "Finalizează", en: "Finish", fr: "Finaliser"},
				add: {ro: "Adaugă", en: "Add", fr: "Ajouter"},

				warning: {ro: "Avertisment", en: "Warning", fr: "Avertissement"},
				program_is_executing: {ro: "Acest program este in curs de executie", en: "This program is currently executing", fr: "Ce programme est en cours d'exécution"},
				cannot_update_exec_prog: {ro: "Programul nu poate fi modificat cât timp este in execuție", en: "Cannot update the program while in execution", fr: "Le programme ne peut pas être modifié pendant l'execution"},
				cannot_delete_exec_prog: {ro: "Programul nu poate fi sters cât timp este in execuție", en: "Cannot delete the program while in execution", fr: "Le programme ne peut pas être supprimé pendant l'execution"},
				can_stop_prog_in: {ro: "Puteți opri programul în", en: "You can stop the program in", fr: "Vous povez arreter le programme sur"},
				general: {ro: "General", en: "General", fr: "Général"},
				program_name: {ro: "Nume program", en: "Program name", fr: "Nom programme"},
				schedule: {ro: "Orar", en: "Schedule", fr: "Horaire"},
				execute_if_opt: {ro: "Execută dacă (opțional)", en: "Execute if (optional)", fr: "Execute si (optionnel)"},
				events: {ro: "Evenimente", en: "Events", fr: "Événements"},
				add_event: {ro: "Adaugă eveniment", en: "Add event", fr: "Ajouter événement"},
				offset: {ro: "Decalaj", en: "Offset", fr: "Décalage"},
				executes_at: {ro: "Se execută la", en: "Executes at", fr: "S'exécute à"},
				edit_program: {ro: "Editare program", en: "Edit program", fr: "Edition programme"},
				new_program: {ro: "Program nou", en: "New program", fr: "Nouveau programme"},
				loading: {ro: "Se încarcă", en: "Loading", fr: "Chargement en cours"},
				program: {ro: "Program", en: "Program", fr: "Programme"},

				actions_execution: {ro: "Execuție acțiuni", en: "Actions execution", fr: "Execution actions"},
				result: {ro: "Rezultat", en: "Result", fr: "Résultat"},
				defined_actions: {ro: "Acțiuni definite", en: "Defined actions", fr: "Actions définies"},
				available_pl: {ro: "Disponibile", en: "Available", fr: "Disponibles"},
				referred_pl: {ro: "Referite", en: "Referred", fr: "Référencés"},
				new_action: {ro: "Acțiune nouă", en: "New action", fr: "Nouvelle action"},

				refresh: {ro: "Reîncarcă", en: "Refresh", fr: "Rafraîchir"},
				add_controller: {ro: "Adaugă controler", en: "Add controller", fr: "Ajouter contrôleur"},
				edit_controller: {ro: "Editează controler", en: "Edit controller", fr: "Edition contrôleur"},
				model: {ro: "Model", en: "Model", fr: "Modèle"},
				version: {ro: "Versiune", en: "Version", fr: "Version"},
				firmware_version: {ro: "Versiune firmware", en: "Firmware version", fr: "Version firmware"},
				active: {ro: "Activ", en: "Active", fr: "Actif"},
				online: {ro: "Conectat", en: "Online", fr: "Connecté"},
				device: {ro: "Dispozitiv", en: "Device", fr: "Dispositif"},
				devices: {ro: "Dispozitive", en: "Devices", fr: "Dispositifs"},
				device_name: {ro: "Nume dispozitiv", en: "Device name", fr: "Nom dispositif"},
				edit: {ro: "Editează", en: "Edit", fr: "Éditer"},
				clone: {ro: "Clonează", en: "Clone", fr: "Cloner"},
				execute: {ro: "Execută", en: "Execute", fr: "Executer"},
				add_device: {ro: "Adaugă dispozitiv", en: "Add device", fr: "Ajouter dispositif"},
				edit_device: {ro: "Editează dispozitiv", en: "Edit device", fr: "Edition dispositif"},
				set_to: {ro: "Setează pe valoarea", en: "Set to", fr: "Mettre sur"},
				value_is: {ro: "Valoarea este", en: "Value is", fr: "La valeur est"},
				parameter: {ro: "Parametru", en: "Parameter", fr: "Paramètre"},
				name: {ro: "Nume", en: "Name", fr: "Nom"},
				target: {ro: "Destinație", en: "Target", fr: "Destination"},
				details: {ro: "Detalii", en: "Details", fr: "Détailles"},
				type: {ro: "Tip", en: "Type", fr: "Type"},
				all: {ro: "Tot", en: "All", fr: "Tous"},
				utc_offset: {ro: "Diferență UTC", en: "UTC offset", fr: "Différence UTC"},
				no_program_defined: {ro: "Niciun program definit", en: "No program defined", fr: "Aucun programme défini"},
				pg_time: {ro: "Ora", en: "Time", fr: "Heure"},
				recurrence: {ro: "Periodicitate", en: "Recurrence", fr: "Récurrence"},
				value: {ro: "Valoare", en: "Value", fr: "Valeur"},
				unit: {ro: "Unitate", en: "Unit", fr: "Unité"},
				not_implemented: {ro: "Neimplementat", en: "Not implemented", fr: "Pas implementé"},
				
				minutes: {ro: "Minute", en: "Minutes", fr: "Minutes"},
				hours: {ro: "Ore", en: "Hours", fr: "Heures"},
				days: {ro: "Zile", en: "Days", fr: "Jours"},
				preferences: {ro: "Preferințe", en: "Preferences", fr: "Préférences"},
				account: {ro: "Cont", en: "Account", fr: "Compte"},
				time_zone: {ro: "Fus orar", en: "Time zone", fr: "Zone horaire"},
				save: {ro: "Salvează", en: "Save", fr: "Sauvegarder"},
				delete_account: {ro: "Șterge cont", en: "Delete account", fr: "Suppression compte"},

				custom: {ro: "Parametrizat", en: "Custom", fr: "Parametrisé"},
				week_days: {ro: "Zile săptămână", en: "Week days", fr: "Jours semaine"},
				month_days: {ro: "Zile lună", en: "Month days", fr: "Jours mois"},

				saved: {ro: "Salvat", en: "Saved", fr: "Sauvegardé"},
				sure_del_account: {
					ro: "Confirmati ca doriti stergerea contului, a tuturor asocierilor cu controllere si a programelor?",
					en: "Are you sure you wish to delete your account\nand all the device pairings and programs?",
					fr: "Confirmez-vous la suppression du compte et de tous les associations avec contrôleurs, aussi que les programmes?"},
				
				weekdays_template: {ro: "LMMJVSD", en: "MTWTFSS", fr: "LMMJVSD"},
				first_day_of_week: {ro: "Prima zi a săptămânii", en: "First day of week", fr: "Premèr jour de la semaine"},

				monday: {ro: "Luni", en: "Monday", fr: "Lundi"},
				tuesday: {ro: "Marți", en: "tuesday", fr: "Mardi"},
				wednesday: {ro: "Miercuri", en: "Wednesday", fr: "Mercredi"},
				thursday: {ro: "Joi", en: "Thursday", fr: "Jeudi"},
				friday: {ro: "Vineri", en: "Friday", fr: "Vendredi"},
				sathurday: {ro: "Sâmbătă", en: "Sathurday", fr: "Samedi"},
				sunday: {ro: "Duminică", en: "Sunday", fr: "Dimanche"},

				scheduled_programs: {ro: "Programe planificate", en: "Scheduled programs", fr: "Programmes planifiés"},
				session_timeout: {ro: "Timp expirare sesiune", en: "Session timeout", fr: "Expiration session"},
				never: {ro: "Niciodată", en: "Never", fr: "Jamais"},
				warning_no_action: {ro: "Atenție: nicio acțiune specificata", en: "Warning: no task specified", fr: "Attention: aucune action spécifié"},

				date: {ro: "Data", en: "Date", fr: "Date"},
				date_format: {ro: "Formatul data", en: "Date format", fr: "Format de la date"},
				day: {ro: "Zi", en: "Day", fr: "Jour"},
				month: {ro: "Luna", en: "Month", fr: "Mois"},
				year: {ro: "An", en: "Year", fr: "Année"},

				cancel_execution: {ro: "Abandon executie", en: "Cancel execution", fr: "Annuler l'execution"},
				on_prog_cancel: {ro: "La abandon program", en: "On program cancel", fr: "Lors l'abandon d'un' programme"},
				prog_cancel_reset_devs: {ro: "Reseteazâ dispozitivele", en: "Reset the devices", fr: "Mettre à zéro"},
				prog_cancel_leave_devs: {ro: "Pâstreazâ starea", en: "Keep current state", fr: "Garder l'état"},

				change_password: {ro: "Schimbare parolă", en: "Change password", fr: "Modifier le mot de passe"},
				current_pass: {ro: "Actuală", en: "Current", fr: "Actuel"},
				new_pass: {ro: "Nouă", en: "New", fr: "Nouveau"},
				change: {ro: "Schimbă", en: "Change", fr: "Modifier"},
				pass_cannot_empty: {ro: "Noua parola nu poate fi goala", en: "The new password cannot be empty", fr: "Le nouveau mot de passe ne peut pas etre vide"},
				user_not_found: {ro: "Utilizator negasit", en: "User not found", fr: "Utilisateur pas trouvé"},
				current_pass_incorrect: {ro: "Parola actuală este incorectă", en: "Current password is incorrect", fr: "Mot de passe actuel incorrecte"},
				unknown_error: {ro: "Eroare necunoscuta", en: "Unknown error", fr: "Erreur pas connue"},
				pass_changed: {ro: "Parola a fost modificata", en: "Password changed", fr: "Mot de passe modifié"},

				command: {ro: "Comandă", en: "Command", fr: "Commande"},
				query: {ro: "Interogare", en: "Query", fr: "Requête"},

				page: {ro: "Pagina", en: "Page", fr: "Page"},
				first_page: {ro: "Prima pagină", en: "First page", fr: "PagPremière page"},
				prev_page: {ro: "Pagina anterioară", en: "Previous page", fr: "Page antérieure"},
				next_page: {ro: "Pagina următoare", en: "Next page", fr: "Page suivante"},
				last_page: {ro: "Ultima pagină", en: "Last page", fr: "Dernière page"},
				page_L: {ro: "pagină", en: "page", fr: "page"},
				rows_L: {ro: "rânduri", en: "rows", fr: "lignes"},
				description: {ro: "Descriere", en: "Description", fr: "Description"},
				notes: {ro: "Note", en: "Notes", fr: "Notes"},

				geo_location: {ro: "Localizare geograficâ", en: "Geo location", fr: "Géo location"},
				set_crt_location: {ro: "Seteaza pozitia curenta", en: "Set current location", fr: "Localisation courante"},
				
				weather: {ro: "Vremea", en: "Weather", fr: "Météo"},
				weather_source: {ro: "Sursa vreme", en: "Weather source", fr: "Source météo"},
				past_days: {ro: "Zile precedente", en: "Past days", fr: "Jours précédents"},
				next_days: {ro: "Zile următoare", en: "Next days", fr: "Jours suivants"},
				tot_precip_amount: {ro: "Precipitatii cumulata", en: "Cumulated precipitation amount", fr: "Précipitations cumullés"},
				mean_rel_humidity: {ro: "Umiditatea medie relativă", en: "Mean relative humidity", fr: "Humidité moyenne rélative"},
				mean_air_temp: {ro: "Temperatura medie a aerului", en: "Mean air temperature", fr: "Temperature air moyenne"},
				air_temp: {ro: "Temperatura aerului", en: "Air temperature", fr: "Température de l'air"},
				temperature: {ro: "Temperatura", en: "Temperature", fr: "Temperature"},
				precipitations: {ro: "Precipitații", en: "Precipitations", fr: "Précipitations"},
				humidity: {ro: "Umiditate", en: "Humidity", fr: "Humidité"},
				dates: {ro: "Date", en: "Dates", fr: "Dates"},
				today: {ro: "Astăzi", en: "Today", fr: "Aujourd'hui"},

				sure_purge_log: {ro: "Sigur doriti stergerea jurnalului?", en: "Are you sure you want to purge the log?", fr: "Confirmez-vous la suppression du journal?"},

				// Controller add wizard
				enter_controller_id: {ro: "Introduceți id controller (8 caractere) - -v. etichetă pe controler", en: "Enter controller id (8 characters) - see label on the controller", fr: "Saisir l'identifiant controleur (8 caractères) - v. libellé controleur"},
				set_pair_mode: {ro: "Puneți controller-ul în mod împerechere (v. manual utilizare pentru detalii)", en: "Set the controller in pairing mode. (See user manual for details.)", fr: "Mettez le contrôleur en mode jumelage (voir manuel utilisateur pour détailles.)"},
				pair_blink_blue: {ro: "In mod imperechere, LED-ul controlerului va pulsa albastru", en: "When in pairing mode, the controller light will blink blue.", fr: "En mode jumelage, le LED du contrôleur va s'allumer en bleu intermitent."},
				in_pair_click: {ro: "In mod imperechere, click pe butonul următor", en: "When in pairing mode, click the following button", fr: "En mode jumelage, cliquer le bouton suivant"},
				pair_controller: {ro: "Imperechere controller", en: "Pair controller", fr: "Jumelage contrôleur"},
				pairstate_none: {ro: "Neîmperecheat", en: "No pairing attempt yet", fr: "Pas jumelé"},
				pairstate_working: {ro: "Se încearcă împerecherea", en: "Pairing", fr: "Jumelage en cours"},
				pairstate_ok: {ro: "Imperechere reușită", en: "Pairing successful", fr: "Jumelage réussi"},
				pairstate_notfound: {ro: "Niciun controler disponibil, verificați dacă controlerul este în mod împerechere si încercați din nou.", en: "No controller found, ensure the controller is in pairing mode and try again.", fr: "Jumelage échoué, assurez-vous que le contrôleur est en mode jumelage et re-essayez."},
				controller_name: {ro: "Nume controler", en: "Controller name", fr: "Nom contrôleur"},
				enter_ctrl_name: {ro: "Introduceți nume controler", en: "Enter the controller name", fr: "Saisir nom contrôleur"},
				enter_dev_name: {ro: "Introduceți nume dispozitiv", en: "Enter the device name", fr: "Saisir nom dispositif"},

				// User registration
				user_registration: {ro: "Inregistrare utilizator", en: "User registration", fr: "Enregistrament utilisateur"},
				user_name: {ro: "Nume utilizator", en: "User name", fr: "Nom utilisateur"},
				password: {ro: "Parola", en: "Password", fr: "Mot de passe"},
				pair_to_finish: {ro: "Pentru finalizarea inregistrarii, trebuie sa imperecheati controlerul dvs.", en: "In order to finish the registration, you need to pair with your controller.", fr: "Pour finaliser l'enregistrement, vous devez jumeler votre contrôleur."},
				back: {ro: "Inapoi", en: "Back", fr: "Arrière"},
				continue: {ro: "Continuă", en: "Next", fr: "Continuer"},

				sign_in: {ro: "Autentificare", en: "Sign in", fr: "Authentification"},
				uname_or_email: {ro: "Nume utilizator sau email", en: "User name or email", fr: "Nom utilisateur ou email"},
				show: {ro: "Arată", en: "Show", fr: "En clair"},
				hide: {ro: "Ascunde", en: "Hide", fr: "Cacher"},
				keep_me_signed: {ro: "Păstreăza-mă autentificat", en: "Keep me signed", fr: "Garde-moi authentifié"},
				if_no_account: {ro: "Dacă nu aveți cont", en: "If you don't have an account yet", fr: "Si vous n'avez pas de compte"},
				click_here: {ro: "click aici", en: "click here", fr: "cliquer ici"},
				last_value: {ro: "Ultima valoare", en: "Last value", fr: "Dernière valeur"},
				dst: {ro: "Ora de vară", en: "Daylight saving time", fr: "Heure d'été"},

				notifications: {ro: "Notificări", en: "Notifications", fr: "Notifications"},
				subscription: {ro: "Abonament", en: "Subscription", fr: "Abonnement"},
				subscribed: {ro: "Abonat", en: "Subscribed", fr: "Abonné"},
				not_subscribed: {ro: "Neabonat", en: "Not subscribed", fr: "Pas abonné"},
				subscribe: {ro: "Abonare", en: "Subscribe", fr: "S'abonner"},
				unsubscribe: {ro: "Dezabonare", en: "Unsubscribe", fr: "Se désabonner"},
				subscribe_denied: {ro: "Abonare nepermisă", en: "Cannot subscribe: permission denied", fr: "Impossible de s'abonner : autorisation refusée"},
				notified_events: {ro: "Evenimente notificate", en: "Notified events", fr: "Événements notifiés"},
				incidents: {ro: "Incidente", en: "Incidents", fr: "Incidents"},
				updates: {ro: "Actualizări", en: "Updates", fr: "Mises à jour"},
				connection: {ro: "Conexiune", en: "Connection", fr: "Connexion"},
				on_this_device: {ro: "Pe acest dispozitiv", en: "On this device", fr: "Sur cet appareil"},

				secondary: {ro: "Secundar", en: "Secondary", fr: "Secondaire"},
				secondary_users: {ro: "Utilizatori secundari", en: "Secondary users", fr: "Utilisateurs secondaires"},
				defined_users: {ro: "Utilizatori definiți", en: "Defined users", fr: "Utilisateurs définis"},
				spec_user_name: {ro: "Specificați nume utilizator", en: "Specify a user name", fr: "Spécifiez nom utilisateur"},
				email_address: {ro: "Specificaați adresa email", en: "Specify the email address", fr: "Spécifiez adress email"},
				can_view: {ro: "Poate vizualiza", en: "Can view", fr: "Peut visualiser"},
				can_execute: {ro: "Poate executa", en: "Can execute", fr: "Peut exécuter"},
				can_edit: {ro: "Poate edita", en: "Can edit", fr: "Peut éditer"},


				// Errors
				must_select_device: {ro: "Trebuie să selectați un dispozitiv", en: "You must select a device", fr: "Vous devez choisir un dispositif"},
				must_select_param: {ro: "Trebuie să selectați un parametru", en: "You must select a parameter", fr: "Vous devez choisir un paramètre"},
				give_name_to_action: {ro: "Dați un nume acestei acțiuni", en: "Please give a name to this action", fr: "Merci de saisir un nom pour cette action"},
				geolocation_not_supported: {ro: "Geolocatia nu este suportata", en: "Geolocation not supported", fr: "Géolocation pas supportée"},
				dev_name_forbidden_char: {ro: "Numele dispozitivului nu poate contine caracterul", en: "The device name cannot contain the character", fr: "Le nom du dispositif ne peur pas inclure le caractère"},
				no_day_checked: {ro: "Trebuie sa selectati cel putin o zi", en: "You must check at least one day", fr: "Vous devez cocher au moins un jour"},
				rec_val_must_more_than_zero: {ro: "Valoarea recurenței trebuie să fie mai mare decât 0", en: "Recurrence value must be greater than 0", fr: "La valeur de la récurrence doit être au moins 1"},
				prog_name_must_not_empty: {ro: "Numele programului nu poate fi gol", en: "The program name cannot be empty", fr: "Le nom dy programme ne peut pas être vide"},
				sure_del_prog: {ro: "Sigur doriți să ștergeți programul", en: "Are you sure you want to remove the program", fr: "Vous ëtes sur de supprimer le programme"},
				dev_not_responding: {ro: "Dispozitivul nu raspunde", en: "The device is not responding", fr: "Le dispositif ne répond pas"},
				cannot_del_perm_dev_in_basic_mode: {ro: "Nu poate fi sters dispozitivul in mod basic", en: "Cannot delete a permanent device in basic mode", fr: "Le dispositif ne peut pas être supprimé en mode basic"},
				exec_error: {ro: "Eroare execuție", en: "Execution error", fr: "Erreur execution"},
				invalid_credentials: {ro: "Parametri identificare incorecți", en: "Invalid credentials", fr: "Paramètres d;identification incorrectes"},
				job_exec_no_timezone: {ro: "Programe in curs de executie. Zona orara nu poate fi schimbata.", en: "Programs eexcuting. Cannot change the time zone now.", fr: "Programmes en cours d'execution. La zone horaire ne peut pas etre modifiée."},
				cannot_be_empty: {ro: "Nu poate fi gol", en: "Cannot be empty", fr: "Ne peut pas être vide"},
				invalid_email: {ro: "Email incorect", en: "Invalid email", fr: "Email incorrecte"},
				min_char_count: {ro: "Numar minim caractere", en: "Minimum characters number", fr: "Nombre minimum de caractères"},
				name_already_used: {ro: "Nume deja utilizat. Alegeti va rugam alt nume.", en: "This name is already used. Please choose another one.", fr: "Ce nom est déjà utilisé. Merci de saisir un autre."},
				email_already_used: {ro: "Adresa email deja utilizata. Alegeti va rugam o alta adresa.", en: "This email is already used. Please choose another one.", fr: "Cette adresse email est déjà utilisée. Merci de saisir une autre."},
				no_querry_possible_offline: {ro: "Interogare nepermisa (controler inactiv)", en: "No query possible (controller offline)", fr: "Interrogation pas possible (contrôleur hors-ligne)"},
				jobs_referring_controller: {ro: "Următoarele programe referă dispozitive de pe acest controler. Ștergerea nu se poate efectua atât timp cât controlerul este referit.", en: "The following programs are referring devices on this controller. The controller cannot be deleted while referred.", fr: "Les programmes suivants réferrent des dispositifs de ce contrôleur. Le contrôleur ne peut pas être supprimé sil est référé."},
				confirm_user_delete: {ro: "Confirmati stergerea utilizatorului", en: "Do you confirm the deletion of user", fr: "Confirmez-vous la suppression de l'utilisateur"},
				controller_id_too_short: {ro: "id controler prea scurt", en: "Controller id too short", fr: "Identifiant controleur trop court"},
				geo_not_set: {ro: "Localizare geografică nespecificată", en: "Geo location not set", fr: "Géo location pas spécifiée"},
				server_inaccessible: {ro: "Serverul nu poate fi accesat.", en: "The server cannot be accessed.", fr: "Le serveur est inaccessible."},
				check_internet_connection: {ro: "Serverul nu poate fi accesat.", en: "Check your internet connection.", fr: "Vérifiez votre connexion internet."},

				// Ș ă ț â î ș
			},
			trans: function(textId, format, languageId, defaultText) {
				languageId = (languageId == null ? this.translationOptions.defaultLanguage : languageId);
				defaultText = (defaultText == null ? this.translationOptions.defaultText : defaultText);

				var translation = null;
				try {
					var translation = this.items[textId][languageId];
				}
				catch(ex) {
					console.error("======== err: " + ex);
				}
				if (format != null && translation.length > 0) {
					if (format == "l") {		// lower
						translation = translation.toLowerCase();
					}
					else if (format == "c") {	// capitalize
						translation = translation[0].toUpperCase() + translation.slice(1);
					}
					else if (format == "u") {	// upper
						translation = translation.toUpperCase();
					}
				}
				return (translation != null ? translation : defaultText);
			},
			getAllTranslationIds: function() {
				return Object.keys(this.items);
			}
		}
	}
}

export default MultiLanguage
