<template>
<div>
	<ControllerPairing @pairingAttempted="pairingAttemptDone" />

	<hr>

	<div v-if="validControllerPairing != null">
		<div class="columns is-centered is-5 is-mobile">
			<div class="column is-half has-text-right">
				{{ ML.trans('controller_name') }}
			</div>
			<div class="column is-half has-text-left">
				<input type="text" size=30 v-model="controllerName" :placeholder="ML.trans('enter_ctrl_name')">
			</div>
		</div>
		<hr>
	</div>

	<div class="columns is-centered is-8 is-mobile">
		<div class="column is-half has-text-centered">
			<a v-if="validControllerPairing != null" class="button is-primary spaceright" @click="doSave()">{{ ML.trans('finish') }}</a>
			<a class="button is-primary" @click="doCancel()">{{ ML.trans('cancel') }}</a>
		</div>
	</div>

</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import ControllerPairing from "@/components/ControllerPairing.vue"

export default {
	name: "ControllerAdd",
	components: {ControllerPairing},
	data: function() {
		return {
			validControllerPairing: null,
			controllerId: 0,
			controllerName: ""
		};
	},
	created: function() {
		this.validControllerPairing = null;
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	methods: {
		pairingAttemptDone: function(pairing) {
			this.validControllerPairing = pairing;
			if (this.validControllerPairing != null) {
				this.getPairingControllerInfo();
			}
		},
		doSave: function() {
			this.$emit("askSave", {pairing: this.validControllerPairing, controllerName: this.controllerName });
		},
		doCancel: function() {
			this.$emit("askClose", {pairing: this.validControllerPairing, controllerId: this.controllerId});
		},
		clearPairing: function() {
			// Set the controller's pairing mode to off (if pairing is in course). This will remove also the pairing info from the controller.
			if (this.validControllerPairing != null) {
				this.serialCommandExecute(this.controllerId, "pairoff", 3000, false)
				.then((responseJson) => {
				})
				.catch((err) => {
					alert("Error: " + err.message);
				});
			}
		},
		getPairingControllerInfo: function() {
			this.getPairingControllerByStampId(this.validControllerPairing.controllerStampId)
			.then((responseJson) => {
				if (responseJson.success) {
					this.controllerId = responseJson.value.controllerId;
					this.controllerName = responseJson.value.name;
				}
				else {
					alert(responseJson.errorDescription);
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		}
	}
}
</script>

<style>
</style>